import { createGlobalStyle } from 'styled-components';
import Mock from '../mock'

export const CookiesPolicyStyle: any = createGlobalStyle`  
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  box-sizing: border-box;
  .inner {
    margin: -130px auto 0;
    padding: 32px;
    box-sizing: border-box;
    max-width: 1108px;
    width: 80%;
    border-radius: 12px;
    background: white;
  }
  h3 {
    margin: 0 0 34px;
  }
}
@media screen and (min-width: ${({ theme }) => Mock.theme.mobile}) {
  .container {
    .inner {
      padding: 64px;
    }

  }
}

    .wysiwyg {
      color: ${({ theme }) => Mock.theme.alt1};
      font-size: 17px;
      line-height: 23px;
      font-family: '${({ theme }) => Mock.theme.font3}';
      h2 {
        font-size: 36px;
        line-height: 46px;
      }
      a {
        color: ${({ theme }) => Mock.theme.primary};
      }
      table,
      tr,
      th,
      td {
        border: 1px solid ${Mock.theme.primary};
      }
      .ctf-thetable {
        overflow: auto;
        width: 100%;
      }
      
      table{
          border-collapse: collapse;
      }
      th {
          text-align: center;
          background-color: ${Mock.theme.primary};
          color: #fff;
      }
      button {
        display: block;
        width: 250px;
        height: 50px;
        margin-top: 20px;
        background: ${Mock.theme.primary};
        border: 0 none;
        cursor: pointer;
        border-radius: 10px;
        color: white;
        text-align: center;
        -webkit-transition-duration: 0.3s;
        -o-transition-duration: 0.3s;
        -moz-transition-duration: 0.3s;
        transition-duration: 0.3s;
        font-size: 14px;
        font-weight: 600;
        margin: 0 auto;
      }
    }
    ${Mock.font.map((font) => `
    @font-face {
      font-family: "${font.name}";
      src:url("${font.url}")format('woff');
    }
    `)}
`

//max-width: ${({ theme }) => theme.maxWidthCenter};