import styled from 'styled-components';

export const CookiesStyle = styled.section`
box-shadow: 0px 0px 30px #00001152;
padding: 32px;
position: fixed;
transform: translate(-50%, -50%);
top: 50%;
left: 50%;
border: 1px solid ${({ theme }) => theme.borderColor};
background: ${({ theme }) => theme.white};
width: calc(100% - 64px);
box-sizing: border-box;
margin: O auto;
border-radius: ${({ theme }) => theme.radiusLarge};
z-index: 2;
text-align: center;
display: flex;
justify-content: center;
.inner {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: ${({ theme }) => theme.maxWidthCenter};
}

.close {
  position: absolute;
  right: 20px;
  top: 12px;
  font-size: 28px;
  line-height: 1;
  color: #5B6E83;
  cursor: pointer;
}


  .button-wrap {
    display: flex;
    flex-direction: column;
    button {
      margin: 10px 44px;
    }
  }

h3 {
  margin: 0 0 16px;
}

  @media screen and (min-width: ${({ theme }) => theme.mobile}) {
  }
  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    .button-wrap {

      flex-direction: row;
      button {
        margin: 44px 10px;
      }
    }
  }
`