import React from 'react';
import { AboutStyle } from './aboutStyle';
import { Picture } from '../../elements/picture/picture';
import { Button } from '../../elements/button/button';
import { Typography } from '../../elements/typography/typography';

interface IAboutProperties {
    title?: string;
    data: string[];
}

export default class About extends React.Component<IAboutProperties, IAboutProperties> {
    constructor(props: IAboutProperties){
        super(props);
        this.state = {
            title: this.props.title,
            data: this.props.data
        }
    }

  render() {
    return(
        <React.Fragment>
            <AboutStyle className="about" >
                <div className="inner">
                    <div className="article-list">
                        { this.state.data.map(function(data: any, i: number) {
                            return (
                                <div className="article" key={i}>
                                    <Picture url={data.img} cover></Picture>
                                    <div className="info">
                                        <Typography variant="h3" text={data.title} white={ i%2 ===0 ? false: true }></Typography>
                                        <div className="description">
                                            <Typography variant="span" text={data.info} white={ i%2 ===0 ? false: true }></Typography>
                                        </div>
                                        { data.buttonLabel ?
                                            <Button outlined="true" as="a"  target="_blank" href={data.href}  label={data.buttonLabel}></Button>
                                            : null
                                        }  
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
            </AboutStyle>
        </React.Fragment>
    )
  }
}