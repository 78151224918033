import styled from 'styled-components';

export const CardListStyle = styled.section` 
    padding: 32px 20px;
    .card-list-wrap {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: auto;
      grid-gap: 1rem;
      width: ${({ theme }) => theme.maxWidthCenter};
      margin: 0 auto;
      .picture {
        img {
          object-fit: cover;
        }
      }
    }
    .inner {
      border-radius:  ${({ theme }) => theme.radiusLarge};
      display: flex;
      flex-direction: column;
      width: 100%;
      > h3 {
        margin: 0 0 32px;
      }
    }

    .overflow {
      overflow-x: auto;
      width: 100%; 
    }
    @media screen and (min-width: ${({ theme }) => theme.mobile}) {
      padding: 32px;
      picture {
        height: 220px;
      }
      .inner {
        max-width: ${({ theme }) => theme.maxWidth};
        margin: 0 auto;
        > h3 {
          text-align: center;
          margin: 0 0 32px;
        }
      }
      .card-list-wrap {
        grid-template-columns: repeat(2, 1fr);
        max-width: ${({ theme }) => theme.maxWidthCenter};
        width: 100%;
        margin: 0 auto;
      }
    }
`