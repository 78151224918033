// global.js
// Source: https://github.com/maximakymenko/react-day-night-toggle-app/blob/master/src/global.js#L23-L41

import { createGlobalStyle } from 'styled-components';
import Mock from '../mock'

export const MainStyle = createGlobalStyle`  
    .container {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      box-sizing: border-box;
    }
    ${Mock.font.map((font) => `
    @font-face {
      font-family: "${font.name}";
      src:url("${font.url}")format('woff');
    }
    `)}
`