// global.js
// Source: https://github.com/maximakymenko/react-day-night-toggle-app/blob/master/src/global.js#L23-L41

import styled from 'styled-components';

export const MainSectionStyle = styled.section`
      padding: 32px 0;
      .inner {
        background: ${({ theme }) => theme.background1};
        border-radius: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      .section-wrap {
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;
      }
      .img-wrap {
        display: flex;
        align-items: center;
        padding: 0 20px 32px;
        justify-content: center;
      }
      .title-wrap {
        padding: 20px;
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }
      .title {
        font-weight: normal;
        text-transform: uppercase;
      }
      .info {
        font-size: 10px;
        margin:20px 0 0 0;
      }
      Button {
        margin:20px 0 0 0;
      }
      @media screen and (min-width: ${({ theme }) => theme.tablet}) {
        .img-wrap {
          width: auto;
        }
      }
    @media screen and (min-width: ${({ theme }) => theme.mobile}) {
        padding: 32px;
        .inner {
          max-width: ${({ theme }) => theme.maxWidth};
          margin: 0 auto;
          flex-direction: row;
          justify-content: space-between;
          min-height: 640px;
          border-radius:  ${({ theme }) => theme.radiusMedium};
          .title-wrap {
            flex: 1;
            max-width: 445px; 
          }
          .section-wrap {
            max-width: ${({ theme }) => theme.maxWidthCenter};
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
          .img-wrap {
            width: 40%;
          }
        }
    }
`