import styled from 'styled-components';

export const TypographyStyle = styled.p` 
  margin: 0;
  color: ${({ theme }) => theme.textColor};
  font-family: '${({ theme }) => theme.font3}';
  font-size: 17px;
  line-height: 23px;
  margin: 0 0 16px;
  &.white {
    color: ${({ theme }) => theme.white};
  }
`
export const H2Style = styled.h2` 
  margin: 0;
  color: ${({ theme }) => theme.textColor};
  font-family: '${({ theme }) => theme.font1}';
  font-size: clamp( 32px, 4vw , 58px );
  line-height: clamp( 39px, 4vw, 69px );
  &.white {
    color: ${({ theme }) => theme.white};
  }
`
export const H2LIGHTStyle = styled.h2` 
  margin: 0;
  color: ${({ theme }) => theme.textColor};
  font-family: ${({ theme }) => theme.font4};
  font-size: clamp( 32px, 4vw , 58px );
  line-height: clamp( 39px, 4vw, 69px );
  &.white {
    color: ${({ theme }) => theme.white};
  }
`

export const ALTStyle = styled.span` 
  font-family: ${({ theme }) => theme.font2};
`

export const LightStyle = styled.span` 
  font-family: ${({ theme }) => theme.font4};
`

export const BStyle = styled.b` 
  font-weight: bold;
`

export const H3Style = styled.h3` 
  margin: 0;
  color: ${({ theme }) => theme.textColor};
  font-family: ${({ theme }) => theme.font3};
  font-weight: bold;
  font-size: clamp( 28px, 5vw, 42px );
  line-height: clamp( 38px, 5vw, 52px );
  &.white {
    color: ${({ theme }) => theme.white};
  }
`
export const H4Style = styled.h4`
  margin: 0;
  color: ${({ theme }) => theme.alt2};
  font-family: '${({ theme }) => theme.font3}';
  font-weight: bold;
  font-size: clamp( 20px, 5vw, 20px );
  line-height: clamp( 27px, 5vw, 27px );
  &.white {
    color: '${({ theme }) => theme.white}';
  }
`

