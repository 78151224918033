import styled from 'styled-components';

export const CardStyle = styled.div`
  position: relative;
  text-align: left;
  border-radius:  ${({ theme }) => theme.radiusMedium};
  overflow: hidden; 
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.borderColor};
  &:hover {
    cursor: pointer;
    box-shadow: 0px 10px 20px #00001129;
    .picture {
      background-color: #333;
      picture {
        opacity: 0.7;
      }
    }
  }
  .picture {
    opacity: 1;
    transition: opacity 1s ease-in-out;
    position: relative;
    > span {
      z-index: 1;
      position: absolute;
      top: 6px;
      left: 6px;
    }
  }
  .card-body {
    padding: 32px 16px;
    .logo {
      margin: 0 0 16px;
    }
    .icon {
      height: 75px;
    }
    &.card-body-white {
      display:flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
  h4 {
    margin: 0 0 16px; 
  }
  a {
    margin: 16px 0 0 0;
    display: flex;
  }
  .overlay {
    position: absolute;
    background: transparent;
    z-index: 1;
    height: 100%;
    width: 100%;
  }
`
export const CardWhite = styled(CardStyle)`
  background: ${({ theme }) => theme.white};
  box-shadow: 0px 3px 6px #00001114;
  border: none;
  pointer-events: none;
  .card-body {
    padding: 32px;
  }
`;