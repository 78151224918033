import React from 'react';
import { BadgeStyle, BadgeSecondaryStyle } from './badgeStyle';

interface IBadgePropertiesProperties {
    label?: string;
    secondary?: string;
}

interface IBadgePropertiesState {
    label?: string;
    secondary?: string;
}

export class Badge extends React.Component<IBadgePropertiesProperties, IBadgePropertiesState> {
    constructor(props: IBadgePropertiesProperties) {
        super(props);
        this.state = {
            label: this.props.label,
            secondary: this.props.secondary,
        }
    }

  render() {
 
    return(
        <React.Fragment>
            { this.state.secondary ? 
                <BadgeSecondaryStyle>
                     {this.state.label}
                </BadgeSecondaryStyle>
            :
                <BadgeStyle>
                    {this.state.label}
                </BadgeStyle>
            }

        </React.Fragment>
    )
  }
}