import React from 'react';
import { TypographyStyle, H2Style, H3Style, H4Style, ALTStyle, BStyle, H2LIGHTStyle, LightStyle } from './typographyStyle';
import {Link} from '../link/link'

interface ITextContentProperties {
  data: dataObject;
}

interface dataObject {
  texts: string[];
}

export class TextContent extends React.Component<ITextContentProperties, ITextContentProperties> {
  constructor(props: ITextContentProperties){
    super(props);
    this.state = {
        data: this.props.data,
    }
}

  render() {
    return (
      this.props.data.texts?.map(function(value: any, i:number) {
        switch (value.variant) {
          case 'spanAlt':
            return (
            <ALTStyle key={i} >{value.text}</ALTStyle>
          )
          case 'spanLight':
            return (
            <LightStyle key={i} >{value.text}</LightStyle>
          )
            case 'bold':
              return (
              <BStyle key={i}>{value.text}</BStyle>
            )
            case 'a':
              return (
              <Link key={i} label={value.text} target="_blank" href={value.linkUrl} arrow="false"></Link>
            )
          default:
          return (
            <span key={i}>{value.text}</span>
          )
        }
          }
      )
    )

  }
}

interface ITypographyProperties {
  variant?: string;
  white?: boolean;
  text?: string;
  data?: string[];
  textBold?: string;
}

export class Typography extends React.Component<ITypographyProperties, ITypographyProperties> {
  constructor(props: ITypographyProperties) {  
      super(props);
      this.state = {
          variant: this.props.variant,
          white: this.props.white,
          text: this.props.text,
          data: this.props.data,
          textBold: this.props.textBold,
      }
  }

  render() {
    return(
      <React.Fragment>
          { this.props.variant === 'h2' ? 
              <H2Style className={`title ${this.props.white ? "white" : ""}`}>
                  {this.props.text}
                  { this.props.textBold ? 
                  <b>{this.props.textBold}</b>
                  : null 
                  } 
              </H2Style>
            : null
          }
          { this.props.variant === 'h3' ? 
              <H3Style className={`title ${this.props.white ? "white" : ""}`}>{this.props.text}{this.props.textBold}</H3Style>
            : null
          }
          { this.props.variant === 'h4' ? 
              <H4Style className={`title ${this.props.white ? "white" : ""}`}>{this.props.text}{this.props.textBold}</H4Style>
            : null
          }
          { this.props.variant === 'span' ? 
              <TypographyStyle className={`text ${this.props.white ? "white" : ""}`}>{this.props.text}{this.props.textBold}</TypographyStyle>
            : null
          }
        {
          this.props.data?.map(function(data: any, i:number) {
            switch (data.variant) {
              case 'h2':
                return (
                  <H2Style className={`title ${data.white ? "white" : ""}`} key={i}>
                    {data.text}
                    <TextContent data={data}></TextContent>
                  </H2Style>
                )
              case 'h2Light':
                return  (
                  <H2LIGHTStyle className={`title ${data.white ? "white" : ""}`} key={i}>
                    {data.text}
                    { /*TODO FIND A WAY TTO FIX IT*/ }
                    {/* <TextContent data={data}></TextContent> */}
                  </H2LIGHTStyle>
                )
              case 'h3':
                return  (
                  <H3Style className={`title ${data.white ? "white" : ""}`} key={i}>
                    {data.text}
                    <TextContent data={data}></TextContent>
                  </H3Style>
                ) 
              case 'p':
                return  (
                  <TypographyStyle className={`title ${data.white ? "white" : ""}`} key={i}>
                    {data.text}
                    <TextContent data={data}></TextContent>
                  </TypographyStyle>
                )             
              default:
                return (
                  <div>You are a User.</div>
                )
            }
          })
        }
      </React.Fragment>
    )
  }
}