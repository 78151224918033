import React from 'react';
import { ThemeProvider } from 'styled-components';
import Mock from '../mock'
import { TermsOfServiceStyle } from './termsOfServiceStyle';
// import {Typography} from '../../elements/typography/typography';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import ContentfulService from '../../services/ContentfulService';

export default class TermsOfService extends React.Component<{}, any> {
    constructor(props: any){
        super(props);
        // this.state = Mock;
        this.state = {
            mock: Mock,
            cleanHtml: ''
        }
    }

    private async getCGUFromContentful() {
        return await ContentfulService.getInstance().getContentEntry('cgu', 'fr');
      }

    async componentDidMount() {
        const cleanHtml = await this.getCGUFromContentful();
        this.setState({
            // mock: Mock,
            cleanHtml: cleanHtml
        });
    }

    render() {
        const cleanedHtml = this.state.cleanHtml;
        return(
            <React.Fragment>
                <ThemeProvider theme={this.state.mock.theme}>
                    <TermsOfServiceStyle />
                    <div className="container">
                        { this.state.mock.list.map(  function(mock: any, i: number) {
                            return(
                                <React.Fragment>
                                    { mock.componentType === 'header' ?          
                                        <Header  
                                            logo={mock.logo}
                                            data={mock.data}
                                        ></Header>
                                    : null
                                    }
                                    {
                                        mock.componentType === 'MentionLegale' ? 
                                        <div className="inner wysiwyg">
                                            <div dangerouslySetInnerHTML={{__html: cleanedHtml}}></div>
                                        </div>
                                        :
                                        null
                                    }
                                
                                <div key={i} >
                                {/* {mock.componentType === 'LegalNotice' ? 
                                    <div className="inner">
                                        <Typography key={i} data={mock.data}></Typography>
                                    </div>
                                : null
                                } */}
                                { mock.componentType === 'footer' ?          
                                <Footer  
                                    copyright={mock.copyright} 
                                    links={mock.links}
                                    logo={mock.logo}
                                    key={i}
                                ></Footer>
                            : null
                                }
                            </div>
                            </React.Fragment>
                            )
                        })}
                    </div>
                </ThemeProvider>
            </React.Fragment>
        )
  }
}