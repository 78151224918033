import React from 'react';
import { CookiesStyle } from './cookiesStyle';
import { Button } from '../../elements/button/button';
import { Typography } from '../../elements/typography/typography';

interface ICookiesProperties {
    data: string[];
    title: string;
    btn1: string;
    btn2: string;
    btn3: string;
    open?: boolean;
}

export default class Cookie extends React.Component<ICookiesProperties, ICookiesProperties> {
    constructor(props: ICookiesProperties){
        super(props);
        this.state = {
            data: this.props.data,
            title: this.props.title,
            btn1: this.props.btn1,
            btn2: this.props.btn2,
            btn3: this.props.btn3,
            // open: true
        }
    }
    
    closeModal() {
          this.setState({open: false})
    }

  render() {
    return(
        <React.Fragment>
            { this.state.open === true ? 
                    <CookiesStyle className="cookies" >
                        <div className="inner">
                                <div className="close" onClick={() => this.closeModal()}>×</div>
                                <Typography variant="h3" text={this.props.title}></Typography>
                                <Typography  data={this.props.data}></Typography>
                                <div className="button-wrap">
                                    <Button outlined="true" label={this.props.btn1} arrow="false"></Button>
                                    <Button outlined="true" label={this.props.btn2} arrow="false"></Button>
                                    <Button label={this.props.btn3} arrow="false"></Button>
                                </div>
                        </div>
                    </CookiesStyle>
                : null
            }

        </React.Fragment>
    )
  }
}