// global.js
// Source: https://github.com/maximakymenko/react-day-night-toggle-app/blob/master/src/global.js#L23-L41

import styled from 'styled-components';

export const ApreciateStyle = styled.section` 
  padding: 32px 0;

  .inner {
    position: relative;
    background: ${({ theme }) => theme.background1};

    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 75px 32px ;
    box-sizing: border-box;
    > h3 {
      text-align: center;
      margin: 0 0 32px;
    }

  
  }
  .card-list-wrap {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: auto;
    grid-gap: 1rem;
    width: 100%;
    margin: 0 auto;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 120px;
      width: 66px;
      height: 54px;
      background: #9fbdb9;
      transform: skew(-30deg,0deg);

      box-shadow: 59px -33px 0 ${({ theme }) => theme.white};
    }
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50px;
      width: 59px;
      height: 66px;
      background:  ${({ theme }) => theme.white};
      transform: skew(-20deg,0deg);
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.mobile}) {
    padding: 32px;
    .inner {
      border-radius:  ${({ theme }) => theme.radiusLarge};
    }
    .card-list-wrap {
      grid-template-columns: repeat(2, 1fr);
    }
  }

@media screen and (min-width: ${({ theme }) => theme.tablet}) {
    .inner {
      max-width: ${({ theme }) => theme.maxWidth};
      margin: 0 auto;
    }
    .card-list-wrap {
      grid-template-columns: repeat(4, 1fr);
      max-width: ${({ theme }) => theme.maxWidthCenter};
      width: 100%;
    }

}

`