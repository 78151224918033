import styled from 'styled-components';

export const BadgeStyle = styled.span` 
  text-decoration: none;
  background: ${({ theme }) => theme.primary};
  border: 1px solid ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.white};
  font-family: '${({ theme }) => theme.font3}';
  font-size: 14px;
  padding: 6px 16px;
  border-radius:  ${({ theme }) => theme.radiusSmall};
  cursor: pointer;
`
export const BadgeSecondaryStyle = styled(BadgeStyle)`
  background: ${({ theme }) => theme.secondary};
  border: 1px solid ${({ theme }) => theme.secondary};
`;