// global.js
import styled from 'styled-components';

export const PictureStyle: any = styled.picture` 
  display: flex;
  img { 
    width: 100%;
    &.cover {
      object-fit: cover;
    }
  }
`