import { ContentfulClientApi, createClient, Entry } from "contentful";
import { Constants, transcoJsonHtml } from "../utils/Constants";
import { ContentfulCard } from "../models/ContentfulCard";
import { ContentfulCardList } from "../models/ContentfulCardList";
import { ContentfulCGV } from "../models/ContentfulCGUModel";
// import sanitizeHtml from 'sanitize-html';

export default class ContentfulService {
    private static instance: ContentfulService;
    private client: ContentfulClientApi;

    private constructor() {
        this.client = createClient({
            space: Constants.CONTENTFUL_SPACEID,
            environment: Constants.CONTENTFUL_ENVIRONMENT,
            accessToken: Constants.CONTENTFUL_ACCESS_APIKEY
        });
    }

    static getInstance() {
        if (!ContentfulService.instance) {
            ContentfulService.instance = new ContentfulService();
        }
        return ContentfulService.instance;
    }

    public async getCardItemContent(cardName: string): Promise<Entry<ContentfulCard>> {
        try {
            if (!cardName) { 
                const cardId = cardName;
                let items = (await this.client.getEntries<ContentfulCard>({
                    content_type: 'cardValues',
                    'fields.cardName': cardId,
                })).items;
                if (items && items.length > 0) return items[0];
            }
            const cardCommonId = cardName;
            return (await this.client.getEntries<ContentfulCard>({
                content_type: 'cardValues',
                'fields.cardName': cardCommonId,
            })).items[0];
        } catch (e) {
            console.error('[ContentfulService]::[getCardItemContent]::Error:: ' + JSON.stringify(e));
            throw e;
        }
    }

    public async getCardItemList(organisation: string, templateName: string) {
        try {
            if (!organisation && !templateName) { 
                const cardListId = `${organisation}_${templateName}`;
                let items = (await this.client.getEntries<ContentfulCardList>({
                    content_type: 'cardLandingPage',
                    'fields.entityName': cardListId,
                })).items;
                if (items && items.length > 0) return items[0];
            }
            const cardListCommonId = `${organisation}_${templateName}`;
            return (await this.client.getEntries<ContentfulCardList>({
                content_type: 'cardLandingPage',
                'fields.entityName': cardListCommonId,
            })).items[0];
        } catch (e) {
            console.error('[ContentfulService]::[getCardItemList]::Error:: ' + JSON.stringify(e));
            throw e;
        }
    }

    public async getContentEntry(contentType: string, lang: string) {
        try {
            const entry: any = (await this.client.getEntries<Entry<ContentfulCGV>>({
                content_type: contentType,
                'fields.id': Constants.CODETENANT + '_' + lang
            }))?.items[0];
            const html = await this.parseContent(entry.fields.content.content);
            /* const cleanHtml = sanitizeHtml(html, {
                allowedTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'p', 'ul', 'li', 'a', 'hr', 'blockquote', 'ol', 'span'],
                allowedAttributes: {
                    span: ["style"]
                },
                allowedStyles: {
                  'span': {
                    'font-weight': [/^bold$/],
                    'font-style': [/^italic$/],
                    'text-decoration': [/^underline$/],
                    'text-align': [/^left$/, /^right$/, /^center$/]
                  }
                }
            }); 
            return cleanHtml; */
            return html;
        } catch(e) {
            console.error('[ContentfulService]::[getContentEntry]::Error:: ' + JSON.stringify(e));
            throw e;
        }
    }

    async parseContent(contents: any[]) {
        try {
          // console.log(JSON.stringify(contents));
          let html = '';
          for (const content of contents) {
            const node = await this.getNodeTypeAndStyle(content.nodeType);
            if (node) {
              html += await this.createHtml(node, content);
            }
          }
          return html;
        } catch (e) {
            console.error('[ContentFulService]::[parseContent]::Error:: ' + e);
          throw e;
        }
    }
    
      async getNodeTypeAndStyle(nodeType: string) {
        try {
          const obj = {
            type: transcoJsonHtml[nodeType],
            class: transcoJsonHtml[nodeType] === 'img' ? 'imgResponsive' : undefined,
          };
          return obj;
        } catch (e) {
            console.error('[ContentFulService]::[getNodeTypeAndStyle]::Error:: ' + e);
          throw e;
        }
      }
    
      async getValue(formatValue: string) {
        try {
          if (formatValue.includes('\n')) {
            let val = '';
            const split = formatValue.split('\n');
            for (const str of split) {
              if (str !== '') { val += str + '<br />'; }
              else { val += '<br />'; }
            }
            return val;
          }
          else {
            return formatValue;
          }
        } catch (e) {
            console.error('[ContentFulService]::[getValue]::Error:: ' + e);
          throw e;
        }
      }
    
      async createHtml(initialNode: any, content: any, parentNode?: any) {
        try {
          let html = '';
          if (initialNode.type) {
            if (initialNode.type !== 'blockquote') { html += '<' + initialNode.type + ''; }
    
            // Paragraph
            if (initialNode.type === 'p' || initialNode.type === 'ul' || initialNode.type === 'li' ||
              initialNode.type === 'ol') //|| initialNode.type === 'blockquote')
            { html += '>'; }
    
            // Hyperlink
            if (initialNode.type === 'a') { html += ' href="' + content.data.uri + '" >'; }
    
            // Img
            if (initialNode.type === 'img') { html += ' class="' + initialNode.class + '" '; }
    
            // Heading
            if (initialNode.type.includes('h')) { html += '>'; }
    
            if (initialNode.type !== 'hr') {
              if (content?.content?.length > 0) {
                for (const item of content.content) {
                  const node = await this.getNodeTypeAndStyle(item.nodeType);
                  const val = await this.createHtml(node, item, initialNode);
                  html += val;
                }
              } else {
                html += 'src="' + content.data.target.fields.file.url + '" />';
              }
            }
            if (initialNode.type !== 'img' && initialNode.type !== 'hr') { html += '</' + initialNode.type + '>'; }
          } else {
            if (content.marks && content.marks.length > 0) {
              html += await this.addStyle(content.marks);
              const val = await this.getValue(content.value);
              html += val + '</span>';
            } else {
              const val = await this.getValue(content.value);
              html += val;
            }
          }
          return html;
        } catch (e) {
            console.error('[ContentFulService]::[createHtml]::Error:: ' + e);
          throw e;
        }
      }
    
      async addStyle(marks: any[]) {
        let html = '';
        html += '<span style="';
        for (const style of marks) {
          if (style.type === 'bold') { html += 'font-weight: bold;'; }
          if (style.type === 'italic') { html += 'font-style: italic;'; }
          if (style.type === 'underline') { html += 'text-decoration: underline;'; }
        }
        html += '">';
        return html;
      }
}