import React from 'react';
import { ApreciateStyle } from './apreciateStyle';
import { Card } from '../../elements/card/card';
import { Typography } from '../../elements/typography/typography';

interface IApreciateProperties {
    title: string;
    data: string[];
}

interface IApreciateStateProperties {
    title: string;
    data: string[];
}

export default class Apreciate extends React.Component<IApreciateProperties, IApreciateStateProperties> {
    constructor(props: IApreciateProperties){
        super(props);
        this.state = {
            title: this.props.title,
            data: this.props.data
        }
    }

  render() {
    return(
        <React.Fragment>
            <ApreciateStyle className="apreciate" >
                <div className="inner">
                    <Typography variant="h3" text={this.props.title}></Typography>
                        <div className="card-list-wrap">
                            { this.state.data.map(function(data: any, i: number) {
                                return (
                                    <Card  
                                        key={i}
                                        title={data.title} 
                                        description={data.description}
                                        icon={data.icon}
                                        white="true"
                                    ></Card>
                                )
                            })}
                        </div>
                </div>
            </ApreciateStyle>
        </React.Fragment>
    )
  }
}