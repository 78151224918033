import styled from 'styled-components';

export const MainSectionStyle = styled.section` 
      padding: 0 0 32px;
      box-sizing: border-box;
      width: 100%;
  
      .inner { 
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-size: cover;

        display: flex;
        flex-direction: column;
        width: 100%;
      }
      .main-wrap {
        padding: 64px 10px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        min-height: 750px;
        box-sizing: border-box;
      }
      .main-wrap-container {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .title {
        font-weight: normal;
        text-transform: uppercase;
      }
      .info {
        margin: 20px 0 0 0;
        max-width: 600px;
      }
      a {
        margin: 32px 0 0;
        width: calc( 100% - 40px);
      }
    @media screen and (min-width: ${({ theme }) => theme.mobile}) {
        padding: 32px;
        .inner {
          background-attachment: fixed;
          max-width: ${({ theme }) => theme.maxWidth};
          margin: 0 auto;
          border-radius:  ${({ theme }) => theme.radiusLarge};
        }
        .main-wrap {
          padding: 64px;
        }
        .logo-top {
          align-self: flex-start;
        }
        .logo-bottom {
          align-self: flex-end;
          margin: 0 -32px -32px 0;
        }
        a {
          width: auto;
        }

    }
`