import styled from 'styled-components';

export const AboutStyle = styled.section`
  padding: 32px 20px; 
  .inner {
    display: flex;
    width: 100%;
  }
  .article-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    img {
      object-fit: cover;
    }
    .article {
      margin: 0 0 30px;
      display: flex;
      flex-direction: column;
      Picture {
        width: 100%;
        border-radius:  ${({ theme }) => theme.radiusMedium};
        overflow: hidden;
        margin: 0 0 20px;
      }
      .info {
        background: ${({ theme }) => theme.background3};
        flex: 1;
        text-align: left;
        padding: 32px;
        border-radius: ${({ theme }) => theme.radiusMedium};
      }
      h3 {
        margin: 0 0 32px;
      }
      a {
        margin: 32px 0 0;
        width: 100%;
        display: inline-block;
        box-sizing: border-box;
      }
      &:nth-child(even) {
        .info { 
          background:  ${({ theme }) => theme.primary};
        }
      }
    }
  }
  @media screen and (min-width: ${({ theme }) => theme.mobile}) {


    padding: 32px;
    .inner {
      max-width: ${({ theme }) => theme.maxWidth};
      margin: 0 auto;
    }
    .article-list {
      max-width: ${({ theme }) => theme.maxWidthCenter};
      .article {
        flex-direction: row;
        &:nth-child(even) {
          Picture {
            order: 2;
            margin: 0 0 0 20px;
          }
          .info { 
            margin: 0;
          }
        }
        .info {
         padding: 64px;
        }
        a {
         margin: 32px 0 0;
         width: auto;
       }
        Picture {
          margin: 0 20px 0 0;
          width: 273px;
          min-height: 478px;
        }
      }
    }
  }
@media screen and (min-width: ${({ theme }) => theme.tablet}) {
  .article-list .article Picture {
    width: 445px;
  }
}
`