import React from 'react';
import { ThemeProvider } from 'styled-components';
import Mock from '../mock'
import { MainStyle } from './mainStyle';
import MainSection from '../../components/mainSection/mainSection'
import CardList from '../../components/cardList/cardList';
import Apreciate from '../../components/apreciate/apreciate';
import About from '../../components/about/about';
import Section from '../../components/section/section';
import Footer from '../../components/footer/footer';
import Cookie from '../../components/cookies/cookies';
import ContentfulService from '../../services/ContentfulService';
import { Entry } from 'contentful';
import { ContentfulCard } from '../../models/ContentfulCard';

interface ICardListProperties {
    url?: string;
    img: string;
    logoName: string;
    description: string;
    linkUrl?: string;
    linkLabel: string;
    badgeLabel: string;
    secondary?: boolean;
}

export default class Main extends React.Component<{}, any> {

  constructor(props: any) {
      super(props);
      this.state = {
          theme: {},
          font: {},
          list: []
        };
        // this.state = Mock;
    }

  private async fillMockCardListFromContentful() {
    return await ContentfulService.getInstance().getCardItemList("IMA", "LandingPage");
  }

  async componentDidMount() {
      const contentfulValues = await this.fillMockCardListFromContentful();
        if (!contentfulValues) {
            console.error('[MainComponent]::[componentDidMount]::Error:: Call Contentful failed !');
        }
        for(const mockObj of Mock.list) {
        if(mockObj.componentType === 'CardList') {
            mockObj.title = contentfulValues.fields.title;
            let cardList: any[] = [];
            contentfulValues.fields.cardItems.forEach(function(cardValues: Entry<ContentfulCard>) {
                const cardItem: ICardListProperties = {
                    url: cardValues.fields.linkUrl,
                    img: cardValues.fields.cardPicture.fields.file.url,
                    logoName: cardValues.fields.cardName,
                    description: cardValues.fields.description,
                    linkUrl: cardValues.fields.linkUrl,
                    linkLabel: cardValues.fields.linkLabel,
                    badgeLabel: cardValues.fields.badgeLabel,
                }
                cardList.push(cardItem);
            });
            mockObj.list = cardList;
            break;
        }
        }
        this.setState(Mock);
    }

  render() {
    return(
      <React.Fragment>
        <ThemeProvider theme={this.state.theme}>
        <MainStyle />
                <div className="container">
                    { this.state.list.map(function(mock: any, i: number) {
                        switch (mock.componentType) {
                            case 'Main':
                                return  (
                                    <MainSection 
                                        title={mock.title} 
                                        titleBold={mock.titleBold}
                                        info={mock.info}
                                        labelButton={mock.labelButton}
                                        href={mock.href}
                                        img={mock.img}
                                        imgMobile={mock.imgMobile}
                                        key={i}
                                        data={mock.data}
                                        logoNameTop={mock.logoTop}
                                        logoNameBottom={mock.logoBottom}
                                    ></MainSection>
                                )
                            case 'CardList':
                                return  (
                                    <CardList  
                                        key={i}
                                        title={mock.title}
                                        data={mock.list}
                                    ></CardList>
                                )
                            case 'apreciate':
                                return  (
                                    <Apreciate  
                                            key={i}
                                            title={mock.title}
                                            data={mock.list}
                                    ></Apreciate>
                                )
                            case 'about':
                                return  (
                                    <About  
                                        key={i}
                                        data={mock.article}
                                    ></About>
                                )
                            case 'section':
                                return  (
                                     <Section  
                                        title={mock.title} 
                                        titleBold={mock.titleBold}
                                        info={mock.info}
                                        labelButton={mock.labelButton}
                                        img={mock.img}
                                        imgMobile={mock.imgMobile}
                                        key={i}
                                    ></Section>
                                ) 
                            case 'footer':
                                return  (
                                     <Footer  
                                        copyright={mock.copyright} 
                                        links={mock.links}
                                        logo={mock.logo}
                                        key={i}
                                    ></Footer>
                                )
                                case 'cookies':
                                    return  (
                                        <Cookie
                                        title={mock.title}
                                        btn1={mock.btn1}
                                        btn2={mock.btn2}
                                        btn3={mock.btn3}
                                        data={mock.data}
                                        key={i}
                                        ></Cookie>
                                    )           
                            default:
                                return (
                                    <div key={i}></div>
                                )
                        }
                    })}
                </div>
            </ThemeProvider>
      </React.Fragment>
    );
  }
}