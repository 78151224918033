import { createGlobalStyle } from 'styled-components';
import Mock from '../mock'

export const TermsOfServiceStyle = createGlobalStyle`  
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  box-sizing: border-box;
  .inner {
    margin: -130px auto 0;
    padding: 32px;
    box-sizing: border-box;
    max-width: 1108px;
    width: 80%;
    border-radius: 12px;
    background: white;
  }
  h3 {
    margin: 0 0 34px;
  }
}
.wysiwyg {
  color: ${({ theme }) => Mock.theme.alt1};
  font-size: 17px;
  line-height: 23px;
  font-family: '${({ theme }) => Mock.theme.font3}';
  h2 {
    font-size: 36px;
    line-height: 46px;
  }
  a {
    color: ${({ theme }) => Mock.theme.primary};
  }
}
@media screen and (min-width: ${({ theme }) => Mock.theme.mobile}) {
  .container {
    .inner {
      padding: 64px;
    }

  }
}
${Mock.font.map((font) => `
@font-face {
  font-family: "${font.name}";
  src:url("${font.url}")format('woff');
}
`)}
`