import React from 'react';
import { PictureStyle } from './pictureStyle';
import { mobile } from '../../theme'

interface IPictureProperties {
    url?: string;
    urlMobile?: string;
    alt?: string;
    cover?: boolean;
}

interface IPictureStateProperties {
    url?: string;
    urlMobile?: string;
    alt?: string;
    cover?: boolean;
}

export class Picture extends React.Component<IPictureProperties, IPictureStateProperties> {
    constructor(props: IPictureProperties){
        super(props);
        this.state = {
            url: this.props.url,
            urlMobile: this.props.urlMobile,
            alt: this.props.alt,
            cover: this.props.cover,
        }
    }

    render() {
        return(
            <React.Fragment>
                <PictureStyle>
                    { this.props.urlMobile ? 
                        <React.Fragment>
                            <source media= {`(min-width: ${mobile.media})`} srcSet={this.props.url} />
                            <img src={this.props.urlMobile} alt={this.props.alt} className={`${this.props.cover ? "cover" : ""}`} />
                        </React.Fragment>
                        : 
                        <img src={this.props.url} alt={this.props.alt} className={`${this.props.cover ? "cover" : ""}`} />
                    }
                </PictureStyle>  
            </React.Fragment>
        )
    }
}
