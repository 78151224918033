import React from 'react';
import { CardStyle, CardWhite } from './cardStyle';
import { Picture } from '../picture/picture';
import { Icon } from '../icon/icon';
import { Typography } from '../typography/typography';
import { Link } from '../link/link';
import { Badge } from '../badge/badge';
import { Logo } from '../logo/logo';
// import TagManager from 'react-gtm-module';

interface ICardProperties {
    title?: string;
    description?: string;
    linkUrl?: string;
    linkLabel?: string;
    img?: string;
    icon?: string;
    white?: string;
    badgeLabel?: string;
    logo?: string;
    secondary?: string;
    border?: string;
}

export class Card extends React.Component<ICardProperties, ICardProperties> {
    constructor(props: ICardProperties){
        super(props);
        this.state = {
            title: this.props.title,
            description: this.props.description,
            linkUrl: this.props.linkUrl,
            linkLabel: this.props.linkLabel,
            img: this.props.img,
            icon: this.props.icon,
            white: this.props.white,
            badgeLabel: this.props.badgeLabel,
            logo: this.props.logo,
            secondary: this.props.secondary,
            border: this.props.border,
        }
        this.renderCard = this.renderCard.bind(this);
    }

    cardClick(url: any) {
        window.open( url, '_blank');
    }

    renderCard() {
        return(
           <React.Fragment>
               {!this.props.white ? <div className={`${this.props.logo ? "overlay ga-" + this.props.logo : "overlay"}`}></div> : null}
                    { this.props.img ?
                        <div className="picture">
                        <Badge 
                            label={this.props.badgeLabel}
                            secondary={this.props.secondary}
                        ></Badge>
                        <Picture url={this.props.img}></Picture>
                        </div> 
                        : null
                    }
                    <div className={`${this.props.img ? "card-body" : "card-body card-body-white"}`}>
                        { this.props.icon ?
                            <div className="logo">
                        <Icon icon={this.props.icon}></Icon> 
                        </div>
                        : null
                        }
                        
                        { this.props.logo ?
                            <div className="logo">
                                <Logo name={this.props.logo}></Logo> 
                            </div>
                            : null
                        }
                        <Typography variant="h4" text={this.props.title}></Typography>
                        <div className="description">
                            <Typography variant="span" text={this.props.description}></Typography>
                        </div>
                            {this.props.linkLabel ?
                                <Link label={this.props.linkLabel} secondary={this.props.secondary} target="_blank"></Link>
                                : null 
                            }
                    </div>
           </React.Fragment> 
        )
    }
    render() {
        return(
            <React.Fragment>
                {this.props.white ?
                    <CardWhite >
                        {this.renderCard()}  
                    </CardWhite>
                : 
                    
                    <CardStyle onClick={() => this.cardClick(this.props.linkUrl)} >
                        {this.renderCard()}  
                    </CardStyle>
                }
            </React.Fragment>
        )
    }
}