import styled from 'styled-components';

export const LinkStyle: any = styled.a` 
  color: ${({ theme }) => theme.primary};
  font-family: '${({ theme }) => theme.font3}';
  font-size: 17px;
  line-height: 19px;
`
export const LinkSecondaryStyle = styled(LinkStyle)`
  color: ${({ theme }) => theme.secondary};
`;