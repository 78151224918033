import React from "react";
import { HeaderStyle } from './headerStyle';
import { Logo } from '../../elements/logo/logo';
import { Typography } from '../../elements/typography/typography';
import { Link } from "react-router-dom";

interface IHeaderProperties {
    logo: string;
    data: string[];
}

export default class Header extends React.Component<IHeaderProperties, IHeaderProperties> {
    constructor(props: any){
        super(props);
        this.state = {
            logo: this.props.logo,
            data: this.props.data
        }
    }

    render() {
        return(
            <React.Fragment>
                <HeaderStyle className="header">
                    <div className="header-inner">
                        <div className="main-wrap">
                            <div className="logo-top">
                            <Link to="/">
                                <Logo name={this.props.logo}></Logo> 
                            </Link>
                            </div>
                            <div className="main-wrap-container">
                                <Typography  data={this.props.data}></Typography>
                            </div>
                        </div>
                    </div>
                </HeaderStyle>
            </React.Fragment>
        );
    }
}