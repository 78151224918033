import React from 'react';
import { MainSectionStyle } from './sectionStyle';
import { Typography } from '../../elements/typography/typography';
import { Picture } from '../../elements/picture/picture';

interface ISectionProperties {
    title: string;
    titleBold?: string;
    labelButton?: string;
    info: string;
    img: string;
    imgMobile: string;
}

export default class Section extends React.Component<ISectionProperties, ISectionProperties> {
    constructor(props: ISectionProperties){
        super(props);
        this.state = {
            title: this.props.title,
            titleBold: this.props.titleBold,
            labelButton: this.props.labelButton,
            info: this.props.info,
            img: this.props.img,
            imgMobile: this.props.imgMobile
        }
    }

  render() {
    return(
        <React.Fragment>
            <MainSectionStyle className="section">
                <div className="inner">
                    <div className="section-wrap">
                        <div className="title-wrap">
                            <Typography variant="h3" text={this.props.title}></Typography>
                            <div className="info">
                                <Typography variant="span" text={this.props.info}></Typography>
                            </div>
                        </div>
                        <div className="img-wrap">
                            <Picture 
                                url={this.props.img}
                                urlMobile={this.props.imgMobile}
                                alt="Habitat Solutions"
                            ></Picture>
                        </div>
                    </div>
                </div>
            </MainSectionStyle>
        </React.Fragment>
    )
  }
}