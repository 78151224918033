import React from 'react';
import { ButtonOutlined, ButtonPrimary } from './buttonStyle';

interface IButtonProperties {
    label?: string;
    outlined?: string;
    as?: string;
    href?: string;
    target?: string;
    click?: any;
    arrow?: string;
}

export class Button extends React.Component<IButtonProperties, IButtonProperties> {
    constructor(props: IButtonProperties){
        super(props);
        this.state = {
            label: this.props.label,
            outlined: this.props.outlined,
            as: this.props.as,
            href: this.props.href,
            target: this.props.target,
            click: this.props.click,
            arrow: this.props.arrow,
        }
    }

  render() {
    const {label} = this.state;
    return(
        <React.Fragment>
            {  this.props.outlined  ? 
                <ButtonOutlined 
                    as={this.props.as} 
                    href={this.props.href} 
                    onClick={this.props.click}
                    target={this.props.target}
                >
                  {label} { !this.props.arrow ? '›' : null }
                </ButtonOutlined>
                : 
                <ButtonPrimary 
                    as={this.props.as} 
                    href={this.props.href}  
                    onClick={this.props.click}
                    target={this.props.target}
                >
                    {label}  { !this.props.arrow ? '›' : null }
                </ButtonPrimary>
            }
        </React.Fragment>
    )
  }
}