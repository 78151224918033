import { imaTheme } from '../theme';
import IMABlack from "../assets/fonts/IMA/woff/IMA-Black.woff";
import IMA from '../assets/fonts/IMA/woff/IMA-Alt.woff';
import MANROPE from '../assets/fonts/Manrope/otf/Manrope3Regular400.otf';
import AZO from '../assets/fonts/AZO/woff/AzoSans-Regular.woff2';

// MAIN
import Main from '../assets/imgs/main-photo.png';

// CARDLIST

// ABOUT
import About1 from '../assets/imgs/conseiller-groupe-ima.jpg';
import About2 from '../assets/imgs/alexa.png';

// SECTTION
import imgX4 from '../assets/imgs/imgX4.png';

export const  Mock = {
    theme: imaTheme,
    font: [
        {
            url: IMABlack,
            name: 'IMABlack',
        },
        {
            url: IMA,
            name: 'IMA',
        },
        {
            url: MANROPE,
            name: 'MANROPE',
        },
        {
            url: AZO,
            name: 'AZO',
        }
    ],
    list: [
        {
            componentType: 'Main',
            title: "Un éventail de services pour tous les ",
            titleBold: "Besoins de votre domicile.",
            info: "Découvrez l’ensemble des services habitat du Groupe IMA pour vous accompagner au quotidien et dans tous les projets pour votre foyer.",
            img: Main,
            labelButton: "Choisir un service",
            href: "#cardList",
            logoTop: "IMA-MAIN-TOP",
            logoBottom: "IMA-MAIN-BOTTOM",
            data: [
                {
                    variant: "h2Light",
                    white: true,
                    text: "UN ÉVENTAIL DE SERVICES POUR LES",
                },
                {
                    variant: "h2",
                    white: true,
                    texts: [
                        {
                            text : "BESOINS DE VOTRE D"
                        },
                        {
                            variant: "spanAlt",
                            text : "O"
                        },
                        {
                            text : "MICILE."
                        },
                      
                    ]
                },
      
            ]
        },
        {
            componentType: 'CardList',
            title: "",
            list: [
               /* {
                    url:"http://www.google.com",
                    img: Card1,
                    logoName: "IMA-DEPANNAGE",
                    description: "Trouvez rapidement et facilement le professionnel qu’il vous faut pour une intervention en urgence !",
                    linkUrl: "http://www.google.com",
                    linkLabel: "Trouver un professionnel",
                    badgeLabel: "Urgence",
                },
                {
                    url:"http://www.google.com",
                    img: Card2,
                    logoName: "IMA-TRAVAUX",
                    description: "Estimez rapidement vos projets de rénovation ou d’embellissement grâce à travaux.help !",
                    linkUrl: "http://www.google.com",
                    linkLabel: "Estimer mon projet",
                    badgeLabel: "Rénovation et travaux",
                },
                {
                    url:"http://www.google.com",
                    img: Card3,
                    logoName: "IMA-PROTECT",
                    description: "Protégez votre habitation et vos proches grâce à nos solutions d’alarme et de télésurveillance !",
                    linkUrl: "http://www.google.com",
                    linkLabel: "Protéger mon logement",
                    badgeLabel: "Télésurveillance",
                },
                {
                    url:"http://www.google.com",
                    img: Card4,
                    logoName: "IMA-SERELIA",
                    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, do eiusmod ut labore et dolore magna aliqua.",
                    linkUrl: "http://www.google.com",
                    linkLabel: "Découvrir la téléassistance",
                    badgeLabel: "Téléassistance",
                    secondary: true,
                }*/
            ]
        },
        {
            componentType: 'apreciate',
            title: "Nos clients apprécient",
            list: [
                {
                    icon: "national",
                    title: "Un réseau national",
                    description: "Nous mettons à votre disposition un réseau de professionnels déployé sur tout le territoire.",
                },
                {
                    icon: "human",
                    title: "De l'humain",
                    description: "Vous êtes accompagnés par des équipes qui veillent à la bonne réalisation de vos projets.",
                },
                {
                    icon: "home",
                    title: "Un point d’entrée unique",
                    description: "Ici vous avez accès à tous les services pour gérer votre foyer au quotidien.",
                },
                {
                    icon: "digital",
                    title: "100 % Digital",
                    description: "Estimation gratuite, devis, souscription… Accédez à l’ensemble de nos services en ligne.",
                },
   
            ]
        },
        {
            componentType: 'about',
            article: [
                {
                    img: About1,
                    title: "La force du Groupe IMA",
                    info : "Créé en 1981, à l’initiative de 3 grandes mutuelles d’assurance, le Groupe IMA est aujourd’hui une figure majeure de l’économie sociale. Nous avons pour ambition d’offrir un service certifié de très grande qualité à nos assurés. De l’assistance automobile au dépannage d’urgence habitation, en passant par l’assistance à domicile ou médicale, le Groupe IMA propose une offre complète.",
                    buttonLabel: "Découvrir le Groupe IMA",
                    href: "https://www.ima.eu/fr/index.php",
                },
                {
                    img: About2,
                    title: "L’innovation au cœur de votre habitation",
                    info : "Nous apportons aux bénéficiaires de nos clients des solutions basées sur la complémentarité entre le digital et la force de notre réseau de plus de 3000 prestataires répartis dans toute la France. Tous nos services sont développés afin de répondre à une plus grande connectivité des habitations. Grâce à nos expertises, nous orchestrons tous les dispositifs de confort au domicile et de sécurité. ",
                },
            ]
        },
        {
            componentType: 'section',
            title: "Plus de 3000 professionnels de l’habitat à votre service!",
            info: "Le Groupe IMA c’est un réseau de plus de 3000 professionnels de l’habitat agréés. Des professionnels réactifs et à votre écoute pour tous vos projets liés à votre habitat. Un savoir-faire garanti grâce à la maîtrise de la qualité de réalisation via un contrôle permanent de la qualité et du taux de réclamation.",
            img: imgX4,
        },
        {
            componentType: "cookies",
            title: "Vos paramètres de cookies",
            btn1: "Gestion des cookies",
            btn2: "Interdire tous les cookies",
            btn3: "Autoriser tous les cookies",
            data: [
                {
                    variant: "p",
                    texts: [
                        {
                            text : "Le site Travaux.help utilise des cookies techniques et des cookies de mesures d’audience. Vous disposez du contrôle sur ce que vous souhaitez activer via les boutons ci-dessous et à tout moment en consultant notre "
                        },
                        {
                            variant: "a",
                            text : "politique de cookies",
                            linkUrl: "http//www.google.fr",
                        },
                        {
                            text : ". En fermant ce bandeau, vous interdisez tous les cookies."
                        },
                    ]
                },
            ]
        },
        /* {
            componentType: "LegalNotice",
            data: [
    
                {
                    variant: "h3",
                    texts: [
                        {
                            text : "Mentions legales "
                        },
 
                    ]
                },
                {
                    variant: "p",
                    texts: [
                        {
                            text : "Le site Travaux.help utilise des cookies techniques et des cookies de mesures d’audience. Vous disposez du contrôle sur ce que vous souhaitez activer via les boutons ci-dessous et à tout moment en consultant notre "
                        },
                        {
                            variant: "a",
                            text : "politique de cookies",
                            linkUrl: "http//www.google.fr",
                        },
                        {
                            text : ". En fermant ce bandeau, vous interdisez tous les cookies."
                        },
                    ]
                },
                {
                    variant: "p",
                    texts: [
                        {
                            text : "Le site Travaux.help utilise des cookies techniques et des cookies de mesures d’audience. Vous disposez du contrôle sur ce que vous souhaitez activer via les boutons ci-dessous et à tout moment en consultant notre "
                        },
                        {
                            variant: "a",
                            text : "politique de cookies",
                            linkUrl: "http//www.google.fr",
                        },
                        {
                            text : ". En fermant ce bandeau, vous interdisez tous les cookies."
                        },
                    ]
                },
                {
                    variant: "p",
                    texts: [
                        {
                            text : "Le site Travaux.help utilise des cookies techniques et des cookies de mesures d’audience. Vous disposez du contrôle sur ce que vous souhaitez activer via les boutons ci-dessous et à tout moment en consultant notre "
                        },
                        {
                            variant: "a",
                            text : "politique de cookies",
                            linkUrl: "http//www.google.fr",
                        },
                        {
                            text : ". En fermant ce bandeau, vous interdisez tous les cookies."
                        },
                    ]
                },
                {
                    variant: "p",
                    texts: [
                        {
                            text : "Le site Travaux.help utilise des cookies techniques et des cookies de mesures d’audience. Vous disposez du contrôle sur ce que vous souhaitez activer via les boutons ci-dessous et à tout moment en consultant notre "
                        },
                        {
                            variant: "a",
                            text : "politique de cookies",
                            linkUrl: "http//www.google.fr",
                        },
                        {
                            text : ". En fermant ce bandeau, vous interdisez tous les cookies."
                        },
                    ]
                },
                {
                    variant: "p",
                    texts: [
                        {
                            text : "Le site Travaux.help utilise des cookies techniques et des cookies de mesures d’audience. Vous disposez du contrôle sur ce que vous souhaitez activer via les boutons ci-dessous et à tout moment en consultant notre "
                        },
                        {
                            variant: "a",
                            text : "politique de cookies",
                            linkUrl: "http//www.google.fr",
                        },
                        {
                            text : ". En fermant ce bandeau, vous interdisez tous les cookies."
                        },
                    ]
                },
            ]
        }, */
        {
            componentType: "CookiesPolicy",
            data: [
    
                {
                    variant: "h3",
                    texts: [
                        {
                            text : "Politiques des coockies"
                        },
 
                    ]
                },
                {
                    variant: "p",
                    texts: [
                        {
                            text : "Le site Travaux.help utilise des cookies techniques et des cookies de mesures d’audience. Vous disposez du contrôle sur ce que vous souhaitez activer via les boutons ci-dessous et à tout moment en consultant notre "
                        },
                        {
                            variant: "a",
                            text : "politique de cookies",
                            linkUrl: "http//www.google.fr",
                        },
                        {
                            text : ". En fermant ce bandeau, vous interdisez tous les cookies."
                        },
                    ]
                },
                {
                    variant: "p",
                    texts: [
                        {
                            text : "Le site Travaux.help utilise des cookies techniques et des cookies de mesures d’audience. Vous disposez du contrôle sur ce que vous souhaitez activer via les boutons ci-dessous et à tout moment en consultant notre "
                        },
                        {
                            variant: "a",
                            text : "politique de cookies",
                            linkUrl: "http//www.google.fr",
                        },
                        {
                            text : ". En fermant ce bandeau, vous interdisez tous les cookies."
                        },
                    ]
                },
                {
                    variant: "p",
                    texts: [
                        {
                            text : "Le site Travaux.help utilise des cookies techniques et des cookies de mesures d’audience. Vous disposez du contrôle sur ce que vous souhaitez activer via les boutons ci-dessous et à tout moment en consultant notre "
                        },
                        {
                            variant: "a",
                            text : "politique de cookies",
                            linkUrl: "http//www.google.fr",
                        },
                        {
                            text : ". En fermant ce bandeau, vous interdisez tous les cookies."
                        },
                    ]
                },
                {
                    variant: "p",
                    texts: [
                        {
                            text : "Le site Travaux.help utilise des cookies techniques et des cookies de mesures d’audience. Vous disposez du contrôle sur ce que vous souhaitez activer via les boutons ci-dessous et à tout moment en consultant notre "
                        },
                        {
                            variant: "a",
                            text : "politique de cookies",
                            linkUrl: "http//www.google.fr",
                        },
                        {
                            text : ". En fermant ce bandeau, vous interdisez tous les cookies."
                        },
                    ]
                },
                {
                    variant: "p",
                    texts: [
                        {
                            text : "Le site Travaux.help utilise des cookies techniques et des cookies de mesures d’audience. Vous disposez du contrôle sur ce que vous souhaitez activer via les boutons ci-dessous et à tout moment en consultant notre "
                        },
                        {
                            variant: "a",
                            text : "politique de cookies",
                            linkUrl: "http//www.google.fr",
                        },
                        {
                            text : ". En fermant ce bandeau, vous interdisez tous les cookies."
                        },
                    ]
                },
            ]
        },
        /* {
            componentType: "TermsOfService",
            data: [
                {
                    variant: "h3",
                    texts: [
                        {
                            text : "Conditions générales d'utilisations",
                        },
                    ]
                },
                {
                    variant: "p",
                    texts: [
                        {
                            text : "Le site Travaux.help utilise des cookies techniques et des cookies de mesures d’audience. Vous disposez du contrôle sur ce que vous souhaitez activer via les boutons ci-dessous et à tout moment en consultant notre "
                        },
                        {
                            variant: "a",
                            text : "politique de cookies",
                            linkUrl: "http//www.google.fr",
                        },
                        {
                            text : ". En fermant ce bandeau, vous interdisez tous les cookies."
                        },
                    ]
                },
                {
                    variant: "p",
                    texts: [
                        {
                            text : "Le site Travaux.help utilise des cookies techniques et des cookies de mesures d’audience. Vous disposez du contrôle sur ce que vous souhaitez activer via les boutons ci-dessous et à tout moment en consultant notre "
                        },
                        {
                            variant: "a",
                            text : "politique de cookies",
                            linkUrl: "http//www.google.fr",
                        },
                        {
                            text : ". En fermant ce bandeau, vous interdisez tous les cookies."
                        },
                    ]
                },
                {
                    variant: "p",
                    texts: [
                        {
                            text : "Le site Travaux.help utilise des cookies techniques et des cookies de mesures d’audience. Vous disposez du contrôle sur ce que vous souhaitez activer via les boutons ci-dessous et à tout moment en consultant notre "
                        },
                        {
                            variant: "a",
                            text : "politique de cookies",
                            linkUrl: "http//www.google.fr",
                        },
                        {
                            text : ". En fermant ce bandeau, vous interdisez tous les cookies."
                        },
                    ]
                },
                {
                    variant: "p",
                    texts: [
                        {
                            text : "Le site Travaux.help utilise des cookies techniques et des cookies de mesures d’audience. Vous disposez du contrôle sur ce que vous souhaitez activer via les boutons ci-dessous et à tout moment en consultant notre "
                        },
                        {
                            variant: "a",
                            text : "politique de cookies",
                            linkUrl: "http//www.google.fr",
                        },
                        {
                            text : ". En fermant ce bandeau, vous interdisez tous les cookies."
                        },
                    ]
                },
                {
                    variant: "p",
                    texts: [
                        {
                            text : "Le site Travaux.help utilise des cookies techniques et des cookies de mesures d’audience. Vous disposez du contrôle sur ce que vous souhaitez activer via les boutons ci-dessous et à tout moment en consultant notre "
                        },
                        {
                            variant: "a",
                            text : "politique de cookies",
                            linkUrl: "http//www.google.fr",
                        },
                        {
                            text : ". En fermant ce bandeau, vous interdisez tous les cookies."
                        },
                    ]
                },
            ]
        }, */
        {
            componentType: 'header',
            logo: "IMA-MAIN-COLOR",
            data: [
                {
                    variant: "h2",
                    white: false,
                    texts: [
                        {
                            variant: "spanLight",
                            white: false,
                            text: "CGU ET MENTION ",
                        },
                        {
                            text : "LÉ"
                        },
                        {
                            variant: "spanAlt",
                            text : "G"
                        },
                        {
                            text : "ALES."
                        },
                      
                    ]
                },
      
            ]
        },
        {
            componentType: 'header-cookie',
            logo: "IMA-MAIN-COLOR",
            data: [
                {
                    variant: "h2",
                    white: false,
                    texts: [
                        {
                            variant: "spanLight",
                            text : "POLITIQUES DE "
                        },
                        {
                            text : "CO"  
                        },
                        {
                            variant: "spanAlt",
                            text : "O"
                        },
                        {
                            text : "KIES."
                        },
                      
                    ]
                },
      
            ]
        },
        {
            componentType: 'MentionLegale',

        },
        {
            componentType: 'footer',
            copyright: "©2021 Groupe IMA",
            logo: "IMA-FOOTER",
            links: [
                {
                    label: "Conditions générales d’utilisation",
                    href: "/TermsOfService",
                },
                {
                    label: "Politique des cookies",
                    href: "/CookiesPolicy",
                },
            ]
        }
    ]
}

export default Mock
