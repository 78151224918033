import styled from 'styled-components';

export const HeaderStyle = styled.header`
        padding: 16px;
        box-sizing: border-box;
        width: 100%;
    .header-inner {
        display: flex;
        flex-direction: column;
        width: 100%;
        background: ${({ theme }) => theme.background1};
        border-radius: 24px;
    }
    .main-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 414px;
    }
    .main-wrap-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .logo-top {
        position: absolute;
        top: 64px;
    }
    @media screen and (min-width: ${({ theme }) => theme.mobile}) {
        padding: 32px;
        .logo-top {
            position: absolute;
            left: 64px;
        }
    }
`