import React from 'react';

import { CardListStyle } from './cardListStyle';
import { Card } from '../../elements/card/card';
import { Typography } from '../../elements/typography/typography';


interface ICardListProperties {
    title: string;
    data: string[];
}


export default class CardList extends React.Component<ICardListProperties, ICardListProperties> {
    constructor(props: ICardListProperties){
        console.log('value of cardList component ', props)
        super(props);
        this.state = {
            title: this.props.title,
            data: this.props.data
        }
    }

  render() {
    return(
        <React.Fragment>
            <CardListStyle className="card-list">
                <div id="cardList" className="inner">
                    <Typography variant="h3" text={this.props.title}></Typography>
                    <div className="overflow">
                        <div className="card-list-wrap">
                            { this.state.data.map(function(data: any, i: number) {
                                return (
                                    <Card  
                                        key={i}
                                        title={data.title} 
                                        description={data.description}
                                        img={data.img}
                                        linkUrl={data.linkUrl}
                                        linkLabel={data.linkLabel}
                                        border="true"
                                        badgeLabel={data.badgeLabel}
                                        logo={data.logoName}
                                        secondary={data.secondary}
                                    ></Card>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>
            </CardListStyle>

        </React.Fragment>
    )
  }
}