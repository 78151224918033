import styled from 'styled-components';

export const ButtonPrimary: any = styled.button` 
  text-decoration: none;
  background: ${({ theme }) => theme.primary};
  border: 1px solid ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.white};
  font-family: '${({ theme }) => theme.font3}';
  font-size: 17px;
  padding: 10px 16px;
  border-radius:  ${({ theme }) => theme.radiusSmall};
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.alt2};
    border: 1px solid ${({ theme }) => theme.alt2};
  }
`
export const ButtonOutlined: any = styled(ButtonPrimary)`
  background: transparent;
  color: ${({ theme }) => theme.alt1};
  border: 1px solid ${({ theme }) => theme.alt3};
  &:hover {
    color: ${({ theme }) => theme.white};
    background: ${({ theme }) => theme.alt1};
    border: 1px solid ${({ theme }) => theme.borderColor};
  }
`;