export const Constants = {
    CONTENTFUL_SPACEID : process.env.REACT_APP_CONTENTFUL_SPACEID || 'gg3wik3thrcy',
    CONTENTFUL_ACCESS_APIKEY : process.env.REACT_APP_CONTENTFUL_APIKEY || 'YilRDLs1XeERqvdQqa5qY90LNEnUtcOoqeTJGJNQgI8',
    CONTENTFUL_ENVIRONMENT : process.env.REACT_APP_CONTENTFUL_ENVIRONMENT || 'develop',
    CODETENANT: process.env.REACT_APP_CODETENANT || '000',
    GA_CODE: process.env.REACT_APP_GA_CODE || 'UA-121192107-27',
    GTM_CODE: process.env.REACT_APP_GTM_CODE || 'GTM-WF93ZPK',
    PIWIK_ID: process.env.REACT_APP_PIWIK_ID || '2184d5db-edfd-4901-85f0-e2c6f6434a36'
}

export const transcoJsonHtml: any = {
    'heading-1': 'h1',
    'heading-2': 'h2',
    'heading-3': 'h3',
    'heading-4': 'h4',
    'heading-5': 'h5',
    'paragraph': 'p',
    'unordered-list': 'ul',
    'list-item': 'li',
    'hyperlink': 'a',
    'embedded-asset-block': 'img',
    'hr': 'hr',
    'blockquote': 'blockquote',
    'ordered-list': 'ol',
    'text': undefined, // IMPORTANT
}