import React from 'react';
import { FooterStyle } from './footerStyle';
import {Logo} from '../../elements/logo/logo';
import {Link} from "react-router-dom";

interface IFooterProperties {
    copyright: string;
    links: string[];
    logo: string;
}

export default class Footer extends React.Component<IFooterProperties, IFooterProperties> {
    constructor(props: IFooterProperties){
        super(props);
        this.state = {
            copyright: this.props.copyright,
            links: this.props.links,
            logo: this.props.logo,
        }
    }

  render() {
    return(
        <React.Fragment>         
            <FooterStyle className="footer">
                <div className="logo-wrap">
                    <Logo name={this.props.logo}></Logo>
                </div>
                <div className="links-wrap">
                    <div className="copyright">{ this.props.copyright}</div>
                    <span  className="dot">.</span>
                    <div className="links">
                        { this.state.links.map(function(link: any, i: number, array: any) {
                                return (
                                    <div key={i}>  
                                         {/* <Link to={link.href} target="_blank">{link.label} </Link> */}
                                         <Link to={link.href}>{link.label} </Link>
                                        { i  + 1  !==  array.length ? <span  className="dot">.</span> : '' }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </FooterStyle>
        </React.Fragment>
    )
  }
}