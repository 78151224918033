export const mobile = {
    media : '641px',
}

export const tablet = {
  media : '1086px',
}

export const imaTheme = {
    primary: '#00AA78',
    secondary: '#D20A5A',
    alt1: '#283C50',
    alt2: '#008769',
    alt3: '#5B6E83',
    alt4: '#C8CDD7',
    white: '#fff',
    background1: '#D7F5E6',
    background2: '#283C50',
    background3: '#F3F3F8',
    borderColor: '#879BAF',
    maxWidth: '1856px',
    maxWidthCenter: '1110px',
    mobile: mobile.media,
    tablet: tablet.media,
    textColor: '#283C50;',
    font1: 'IMABlack',
    font2: 'IMA',
    font3: 'MANROPE',
    font4: 'AZO',
    radiusSmall: '8px',
    radiusMedium: '12px',
    radiusLarge: '24px',
  }
  
  export const exTheme = {
    primary: '#E60965',
    secondary: 'cyan',
    alt1: '#283C50',
    alt2: '#008769',
    alt3: '#5B6E83',
    alt4: '#C8CDD7',
    white: '#fffff3',
    background1: '#FBE5E5',
    background2: '#33333',
    background3: 'lightGrey',
    borderColor: '#333333',
    maxWidth: '1856px',
    maxWidthCenter: '1110px',
    mobile: mobile.media,
    tablet: tablet.media,
    textColor: '#333;',
    font1: 'IMABlack',
    font2: 'IMA',
    font3: 'MANROPE',
    font4: 'AZO',
    radiusSmall: '8px',
    radiusMedium: '12px',
    radiusLarge: '24px',
  }