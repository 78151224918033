import React from 'react';
import { MainSectionStyle } from './mainSectionStyle';
import { Button } from '../../elements/button/button';
import { Logo } from '../../elements/logo/logo';
import { Typography } from '../../elements/typography/typography';

interface IMainSectionProperties {
    title: string;
    titleBold: string;
    info: string;
    labelButton: string;
    href: string;
    img: string;
    imgMobile: string;
    logoNameBottom: string;
    logoNameTop: string;
    data: string[];
}

export default class MainSection extends React.Component<IMainSectionProperties, IMainSectionProperties> {
    constructor(props: IMainSectionProperties){
        super(props);
        this.state = {
            title: this.props.title,
            titleBold: this.props.titleBold,
            info: this.props.info,
            labelButton: this.props.labelButton,
            href: this.props.href,
            img: this.props.img,
            imgMobile: this.props.imgMobile,
            logoNameBottom: this.props.logoNameBottom,
            logoNameTop: this.props.logoNameTop,
            data: this.props.data,
        }
    }

  render() {
    const style = {
        backgroundImage: "url(" + this.props.img + ")",
      };
    return(
        <React.Fragment>
            <MainSectionStyle className="main" >
                <div className="inner" style={style}>
                    <div className="main-wrap">
                        <div className="logo-top">
                            <Logo name={this.props.logoNameTop}></Logo>
                        </div>
                        <div className="main-wrap-container">
                            <Typography data={this.props.data} white></Typography>
                            <div className="info">
                                <Typography variant="span" white text={this.props.info}></Typography>
                            </div>
                            {/* <Button label={this.props.labelButton} click={() => this.toto()}></Button> */}
                            <Button as="a" label={this.props.labelButton} href={this.props.href}></Button>
                        </div>
                        <div className="logo-bottom">
                            <Logo name={this.props.logoNameBottom}></Logo>
                        </div>
                    </div>
                </div>
            </MainSectionStyle>
        </React.Fragment>
    )
  }
}