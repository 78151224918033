import './App.css';
import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Main from './pages/main/main';
// import LegalNotice from './pages/legalNotice/legalNotice';
import CookiesPolicy from './pages/cookiesPolicy/cookiesPolicy';
import TermsOfService from './pages/termsOfService/termsOfService';
import { Constants } from './utils/Constants';
import PiwikPro from '@piwikpro/react-piwik-pro';

PiwikPro.initialize(Constants.PIWIK_ID, 'https://groupeima.piwik.pro');

// function appendScriptCookie() {
//   const script = document.createElement('script');
//   script.innerHTML = `
//       tarteaucitron.user.googletagmanagerId = '${Constants.GTM_CODE}';
//         (tarteaucitron.job = tarteaucitron.job || []).push('googletagmanager');
//       tarteaucitron.user.gtagUa = '${Constants.GA_CODE}';
//         tarteaucitron.user.gtagMore = function () {};
//         (tarteaucitron.job = tarteaucitron.job || []).push('gtag');
//   `;
//   document.head.appendChild(script);
// }

class App extends React.Component<{}, any> {

  // componentDidMount() {
  //   appendScriptCookie();
  // }

  render() {
    return (
      <div className="App">
          <BrowserRouter>
            <Routes>
              {/* <Route path="/LegalNotice" element={ <LegalNotice/>}/> */}
              <Route path="/CookiesPolicy" element={ <CookiesPolicy/>}/>
              <Route path="/TermsOfService" element={ <TermsOfService/>}/>
              <Route path="/"  element={ <Main />}/>
            </Routes>
            </BrowserRouter>
      </div>
    );
  }

}

/* function App() {
  appendScriptCookie();
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/LegalNotice" element={ <LegalNotice/>}/>
          <Route path="/CookiesPolicy" element={ <CookiesPolicy/>}/>
          <Route path="/TermsOfService" element={ <TermsOfService/>}/>
          <Route path="/"  element={ <Main />}/>
        </Routes>
        </BrowserRouter>
    </div>
  );
} */

export default App;
