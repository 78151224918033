import React from 'react';

interface ILogoProperties {
    name?: string;
}

interface ILogoStateProperties {
    name?: string;
}

export class Logo extends React.Component<ILogoProperties, ILogoStateProperties> {
    constructor(props: ILogoProperties){
        super(props);
        this.state = {
            name: this.props.name,
        }
    }

  render() {
    return(
    
        <React.Fragment>
                {  this.props.name === "IMA-MAIN-TOP" ? 
                            <svg xmlns="http://www.w3.org/2000/svg"  width="321.697" height="20" viewBox="0 0 321.697 20">
                            <defs>
                                <clipPath id="clip-path">
                                <rect id="Rectangle_1372" data-name="Rectangle 1372" width="321.697" height="20" fill="none"/>
                                </clipPath>
                            </defs>
                            <g id="Groupe_1559" data-name="Groupe 1559" transform="translate(0 0)">
                                <g id="Groupe_1558" data-name="Groupe 1558" transform="translate(0 0)" clipPath="url(#clip-path)">
                                    <path id="Tracé_6735" data-name="Tracé 6735" d="M5.067,8.275c-.885-1.261-1.743-2.547-2.6-3.834l-.053.026c.026,1.153.053,2.629.053,4.209v10.94H0V.2H2.467l6.89,9.866L16.247.2h2.441V19.616H16.14V8.677c0-1.58.026-3.057.053-4.209l-.053-.026c-.858,1.287-1.717,2.573-2.6,3.834L9.3,14.335Z" transform="translate(0 0.089)" fill="#fff"/>
                                    <path id="Tracé_6736" data-name="Tracé 6736" d="M16,.2H27.212V2.511h-8.66V8.382h7.587v2.279H18.552V17.31h8.9v2.305H16Z" transform="translate(7.027 0.089)" fill="#fff"/>
                                    <path id="Tracé_6737" data-name="Tracé 6737" d="M26.021,15.683a10.152,10.152,0,0,0,5.871,1.984c2.305,0,3.942-1.046,3.942-3.163,0-2.494-2.279-2.924-4.827-3.648-2.734-.777-5.389-1.9-5.389-5.443C25.618,1.982,28.112,0,31.892,0a10.961,10.961,0,0,1,5.576,1.446V4.129a10.357,10.357,0,0,0-5.631-1.8c-2.17,0-3.7.938-3.7,2.923,0,2.413,2.252,2.815,4.611,3.485,2.815.8,5.6,1.931,5.6,5.63,0,3.54-2.6,5.631-6.487,5.631a11.95,11.95,0,0,1-5.845-1.53Z" transform="translate(11.248 -0.001)" fill="#fff"/>
                                    <path id="Tracé_6738" data-name="Tracé 6738" d="M40.982,15.683a10.152,10.152,0,0,0,5.871,1.984c2.305,0,3.942-1.046,3.942-3.163,0-2.494-2.279-2.924-4.827-3.648-2.734-.777-5.389-1.9-5.389-5.443C40.579,1.982,43.073,0,46.853,0A10.961,10.961,0,0,1,52.43,1.446V4.129a10.357,10.357,0,0,0-5.631-1.8c-2.17,0-3.7.938-3.7,2.923,0,2.413,2.252,2.815,4.611,3.485,2.815.8,5.6,1.931,5.6,5.63,0,3.54-2.6,5.631-6.487,5.631a11.95,11.95,0,0,1-5.845-1.53Z" transform="translate(17.816 -0.001)" fill="#fff"/>
                                    <path id="Tracé_6739" data-name="Tracé 6739" d="M51.685.2H62.892V2.511h-8.66V8.382h7.587v2.279H54.232V17.31h8.9v2.305H51.685Z" transform="translate(22.692 0.089)" fill="#fff"/>
                                    <path id="Tracé_6740" data-name="Tracé 6740" d="M61.95.2h5.255a10.937,10.937,0,0,1,5.417.966A4.948,4.948,0,0,1,75.3,5.729a5.176,5.176,0,0,1-2.492,4.557,5.993,5.993,0,0,1-2.226.8L76.8,19.615H73.72L67.8,11.331H64.5v8.285H61.95ZM67.8,9.187A6.31,6.31,0,0,0,71.6,8.3a2.861,2.861,0,0,0,1.153-2.413,2.987,2.987,0,0,0-1.583-2.734,8.589,8.589,0,0,0-4.156-.645H64.5V9.187Z" transform="translate(27.2 0.089)" fill="#fff"/>
                                    <path id="Tracé_6741" data-name="Tracé 6741" d="M72.329.2h2.734l4.13,10.885c.643,1.689,1.259,3.353,1.9,5.149h.053c.645-1.77,1.288-3.485,1.959-5.228L87.262.2h2.6L82.41,19.615h-2.6Z" transform="translate(31.756 0.089)" fill="#fff"/>
                                    <rect id="Rectangle_1370" data-name="Rectangle 1370" width="2.547" height="19.411" transform="translate(123.926 0.294)" fill="#fff"/>
                                    <path id="Tracé_6742" data-name="Tracé 6742" d="M100.267,0a11.252,11.252,0,0,1,5.47,1.367V4.156a9.426,9.426,0,0,0-5.5-1.8A7.368,7.368,0,0,0,92.706,10a7.422,7.422,0,0,0,7.748,7.641,8.759,8.759,0,0,0,5.578-1.959V18.5a10.93,10.93,0,0,1-5.71,1.5,9.766,9.766,0,0,1-10.163-9.947A9.79,9.79,0,0,1,100.267,0" transform="translate(39.585 -0.001)" fill="#fff"/>
                                    <path id="Tracé_6743" data-name="Tracé 6743" d="M103.444.2h11.207V2.511h-8.66V8.382h7.587v2.279h-7.587V17.31h8.9v2.305H103.444Z" transform="translate(45.418 0.089)" fill="#fff"/>
                                    <path id="Tracé_6744" data-name="Tracé 6744" d="M113.46,15.683a10.152,10.152,0,0,0,5.871,1.984c2.305,0,3.94-1.046,3.94-3.163,0-2.494-2.279-2.924-4.825-3.648-2.734-.777-5.389-1.9-5.389-5.443,0-3.432,2.494-5.414,6.274-5.414a10.965,10.965,0,0,1,5.576,1.446V4.129a10.36,10.36,0,0,0-5.631-1.8c-2.172,0-3.7.938-3.7,2.923,0,2.413,2.251,2.815,4.611,3.485,2.815.8,5.6,1.931,5.6,5.63,0,3.54-2.6,5.631-6.486,5.631a11.95,11.95,0,0,1-5.845-1.53Z" transform="translate(49.638 -0.001)" fill="#fff"/>
                                    <path id="Tracé_6745" data-name="Tracé 6745" d="M134.079,7.2h4.827l-4.827,4.986v7.535h-5.443V.149h5.443ZM141.534.149h5.443V19.722h-5.443V12.187h-4.852L141.534,7.2Z" transform="translate(56.479 0.065)" fill="#fff"/>
                                    <path id="Tracé_6746" data-name="Tracé 6746" d="M159.488,19.722l-1.61-4h-7.427l-1.607,4H143.32L151.469.23l-.026-.081h5.47l8.177,19.573Zm-7.159-8.66h3.645l-1.822-4.5Z" transform="translate(62.926 0.065)" fill="#fff"/>
                                    <path id="Tracé_6747" data-name="Tracé 6747" d="M176.929,13.983c0,3.916-2.762,5.739-6.971,5.739h-9.571V.149h9.436c3.835,0,6.382,1.448,6.382,4.88a4.378,4.378,0,0,1-3.3,4.264c2.333.589,4.022,2.144,4.022,4.69m-11.206-9.41V7.79h3.27c1.1,0,1.984-.509,1.984-1.664,0-1.1-.885-1.554-1.984-1.554Zm3.7,10.7c1.288,0,2.279-.482,2.279-1.583s-1.019-1.607-2.279-1.607h-3.7v3.19Z" transform="translate(70.419 0.065)" fill="#fff"/>
                                    <rect id="Rectangle_1371" data-name="Rectangle 1371" width="5.443" height="19.573" transform="translate(250.78 0.213)" fill="#fff"/>
                                    <path id="Tracé_6748" data-name="Tracé 6748" d="M198.019,4.868h-6.194V19.72h-5.443V4.868h-6.247V.149h17.883Z" transform="translate(79.09 0.065)" fill="#fff"/>
                                    <path id="Tracé_6749" data-name="Tracé 6749" d="M207.632,19.722l-1.61-4h-7.427l-1.607,4h-5.523L199.613.23l-.026-.081h5.47l8.177,19.573Zm-7.159-8.66h3.645l-1.822-4.5Z" transform="translate(84.063 0.065)" fill="#fff"/>
                                    <path id="Tracé_6750" data-name="Tracé 6750" d="M223.4,4.868H217.2V19.72h-5.443V4.868h-6.247V.149H223.4Z" transform="translate(90.231 0.065)" fill="#fff"/>
                                    <path id="Tracé_6751" data-name="Tracé 6751" d="M222.42,9.762a2.869,2.869,0,1,1-2.9,2.869,2.878,2.878,0,0,1,2.9-2.869" transform="translate(96.383 4.285)" fill="#fff"/>
                                </g>
                            </g>
                        </svg>
                        : null
                }
                {
                    this.props.name === "IMA-MAIN-COLOR" ? 
                    <svg xmlns="http://www.w3.org/2000/svg" width="321.697" height="20" viewBox="0 0 321.697 20">
                        <defs>
                            <clipPath id="clip-path">
                            <rect id="Rectangle_1372" data-name="Rectangle 1372" width="321.697" height="20" fill="#5b6e83"/>
                            </clipPath>
                        </defs>
                        <g id="Groupe_1559" data-name="Groupe 1559" transform="translate(0 0)">
                            {/* <g id="Groupe_1558" data-name="Groupe 1558" transform="translate(0 0)" clip-path="url(#clip-path)"> */}
                            <g id="Groupe_1558" data-name="Groupe 1558" transform="translate(0 0)" clipPath="url(#clip-path)">
                            <path id="Tracé_6735" data-name="Tracé 6735" d="M5.067,8.275c-.885-1.261-1.743-2.547-2.6-3.834l-.053.026c.026,1.153.053,2.629.053,4.209v10.94H0V.2H2.467l6.89,9.866L16.247.2h2.441V19.616H16.14V8.677c0-1.58.026-3.057.053-4.209l-.053-.026c-.858,1.287-1.717,2.573-2.6,3.834L9.3,14.335Z" transform="translate(0 0.089)" fill="#5b6e83"/>
                            <path id="Tracé_6736" data-name="Tracé 6736" d="M16,.2H27.212V2.511h-8.66V8.382h7.587v2.279H18.552V17.31h8.9v2.305H16Z" transform="translate(7.027 0.089)" fill="#5b6e83"/>
                            <path id="Tracé_6737" data-name="Tracé 6737" d="M26.021,15.683a10.152,10.152,0,0,0,5.871,1.984c2.305,0,3.942-1.046,3.942-3.163,0-2.494-2.279-2.924-4.827-3.648-2.734-.777-5.389-1.9-5.389-5.443C25.618,1.982,28.112,0,31.892,0a10.961,10.961,0,0,1,5.576,1.446V4.129a10.357,10.357,0,0,0-5.631-1.8c-2.17,0-3.7.938-3.7,2.923,0,2.413,2.252,2.815,4.611,3.485,2.815.8,5.6,1.931,5.6,5.63,0,3.54-2.6,5.631-6.487,5.631a11.95,11.95,0,0,1-5.845-1.53Z" transform="translate(11.248 -0.001)" fill="#5b6e83"/>
                            <path id="Tracé_6738" data-name="Tracé 6738" d="M40.982,15.683a10.152,10.152,0,0,0,5.871,1.984c2.305,0,3.942-1.046,3.942-3.163,0-2.494-2.279-2.924-4.827-3.648-2.734-.777-5.389-1.9-5.389-5.443C40.579,1.982,43.073,0,46.853,0A10.961,10.961,0,0,1,52.43,1.446V4.129a10.357,10.357,0,0,0-5.631-1.8c-2.17,0-3.7.938-3.7,2.923,0,2.413,2.252,2.815,4.611,3.485,2.815.8,5.6,1.931,5.6,5.63,0,3.54-2.6,5.631-6.487,5.631a11.95,11.95,0,0,1-5.845-1.53Z" transform="translate(17.816 -0.001)" fill="#5b6e83"/>
                            <path id="Tracé_6739" data-name="Tracé 6739" d="M51.685.2H62.892V2.511h-8.66V8.382h7.587v2.279H54.232V17.31h8.9v2.305H51.685Z" transform="translate(22.692 0.089)" fill="#5b6e83"/>
                            <path id="Tracé_6740" data-name="Tracé 6740" d="M61.95.2h5.255a10.937,10.937,0,0,1,5.417.966A4.948,4.948,0,0,1,75.3,5.729a5.176,5.176,0,0,1-2.492,4.557,5.993,5.993,0,0,1-2.226.8L76.8,19.615H73.72L67.8,11.331H64.5v8.285H61.95ZM67.8,9.187A6.31,6.31,0,0,0,71.6,8.3a2.861,2.861,0,0,0,1.153-2.413,2.987,2.987,0,0,0-1.583-2.734,8.589,8.589,0,0,0-4.156-.645H64.5V9.187Z" transform="translate(27.2 0.089)" fill="#5b6e83"/>
                            <path id="Tracé_6741" data-name="Tracé 6741" d="M72.329.2h2.734l4.13,10.885c.643,1.689,1.259,3.353,1.9,5.149h.053c.645-1.77,1.288-3.485,1.959-5.228L87.262.2h2.6L82.41,19.615h-2.6Z" transform="translate(31.756 0.089)" fill="#5b6e83"/>
                            <rect id="Rectangle_1370" data-name="Rectangle 1370" width="2.547" height="19.411" transform="translate(123.926 0.294)" fill="#5b6e83"/>
                            <path id="Tracé_6742" data-name="Tracé 6742" d="M100.267,0a11.252,11.252,0,0,1,5.47,1.367V4.156a9.426,9.426,0,0,0-5.5-1.8A7.368,7.368,0,0,0,92.706,10a7.422,7.422,0,0,0,7.748,7.641,8.759,8.759,0,0,0,5.578-1.959V18.5a10.93,10.93,0,0,1-5.71,1.5,9.766,9.766,0,0,1-10.163-9.947A9.79,9.79,0,0,1,100.267,0" transform="translate(39.585 -0.001)" fill="#5b6e83"/>
                            <path id="Tracé_6743" data-name="Tracé 6743" d="M103.444.2h11.207V2.511h-8.66V8.382h7.587v2.279h-7.587V17.31h8.9v2.305H103.444Z" transform="translate(45.418 0.089)" fill="#5b6e83"/>
                            <path id="Tracé_6744" data-name="Tracé 6744" d="M113.46,15.683a10.152,10.152,0,0,0,5.871,1.984c2.305,0,3.94-1.046,3.94-3.163,0-2.494-2.279-2.924-4.825-3.648-2.734-.777-5.389-1.9-5.389-5.443,0-3.432,2.494-5.414,6.274-5.414a10.965,10.965,0,0,1,5.576,1.446V4.129a10.36,10.36,0,0,0-5.631-1.8c-2.172,0-3.7.938-3.7,2.923,0,2.413,2.251,2.815,4.611,3.485,2.815.8,5.6,1.931,5.6,5.63,0,3.54-2.6,5.631-6.486,5.631a11.95,11.95,0,0,1-5.845-1.53Z" transform="translate(49.638 -0.001)" fill="#5b6e83"/>
                            <path id="Tracé_6745" data-name="Tracé 6745" d="M134.079,7.2h4.827l-4.827,4.986v7.535h-5.443V.149h5.443ZM141.534.149h5.443V19.722h-5.443V12.187h-4.852L141.534,7.2Z" transform="translate(56.479 0.065)" fill="#00aa78"/>
                            <path id="Tracé_6746" data-name="Tracé 6746" d="M159.488,19.722l-1.61-4h-7.427l-1.607,4H143.32L151.469.23l-.026-.081h5.47l8.177,19.573Zm-7.159-8.66h3.645l-1.822-4.5Z" transform="translate(62.926 0.065)" fill="#00aa78"/>
                            <path id="Tracé_6747" data-name="Tracé 6747" d="M176.929,13.983c0,3.916-2.762,5.739-6.971,5.739h-9.571V.149h9.436c3.835,0,6.382,1.448,6.382,4.88a4.378,4.378,0,0,1-3.3,4.264c2.333.589,4.022,2.144,4.022,4.69m-11.206-9.41V7.79h3.27c1.1,0,1.984-.509,1.984-1.664,0-1.1-.885-1.554-1.984-1.554Zm3.7,10.7c1.288,0,2.279-.482,2.279-1.583s-1.019-1.607-2.279-1.607h-3.7v3.19Z" transform="translate(70.419 0.065)" fill="#00aa78"/>
                            <rect id="Rectangle_1371" data-name="Rectangle 1371" width="5.443" height="19.573" transform="translate(250.78 0.213)" fill="#00aa78"/>
                            <path id="Tracé_6748" data-name="Tracé 6748" d="M198.019,4.868h-6.194V19.72h-5.443V4.868h-6.247V.149h17.883Z" transform="translate(79.09 0.065)" fill="#00aa78"/>
                            <path id="Tracé_6749" data-name="Tracé 6749" d="M207.632,19.722l-1.61-4h-7.427l-1.607,4h-5.523L199.613.23l-.026-.081h5.47l8.177,19.573Zm-7.159-8.66h3.645l-1.822-4.5Z" transform="translate(84.063 0.065)" fill="#00aa78"/>
                            <path id="Tracé_6750" data-name="Tracé 6750" d="M223.4,4.868H217.2V19.72h-5.443V4.868h-6.247V.149H223.4Z" transform="translate(90.231 0.065)" fill="#00aa78"/>
                            <path id="Tracé_6751" data-name="Tracé 6751" d="M222.42,9.762a2.869,2.869,0,1,1-2.9,2.869,2.878,2.878,0,0,1,2.9-2.869" transform="translate(96.383 4.285)" fill="#00aa78"/>
                            </g>
                        </g>
                    </svg>
                    : null
                }
                {  this.props.name === "IMA-MAIN-BOTTOM" ? 
                    <svg id="Logo_Groupe_IMA_Desktop" data-name="Logo Groupe IMA Desktop" width="150" height="44.675" viewBox="0 0 150 44.675">
                    <g id="Groupe_331" data-name="Groupe 331" transform="translate(71.283 16.28)">
                        <g id="Groupe_330" data-name="Groupe 330" transform="translate(0 0.001)">
                        <path id="Tracé_182" data-name="Tracé 182" d="M188.368,84.425h8.079V112.82h-8.079Z" transform="translate(-188.368 -84.425)" fill="#fff"/>
                        <path id="Tracé_183" data-name="Tracé 183" d="M290.138,84.425H298.1l11.491,28.395h-8.785l-2-5.8H289.08L287,112.82h-8.354Zm3.844,8.628c-.549,1.8-1.1,3.451-1.765,5.295l-.981,2.706h5.491l-.941-2.706c-.667-1.922-1.177-3.53-1.726-5.295Z" transform="translate(-230.874 -84.425)" fill="#fff"/>
                        </g>
                        <path id="Tracé_184" data-name="Tracé 184" d="M247.373,84.424h-7.549l-7.3,10.354L225.2,84.424H217.43v28.4H225.2v-9.256c0-2.236-.039-4.706-.078-6.668l.078-.039c1.176,1.8,2.431,3.648,3.726,5.451l3.412,4.785Z" transform="translate(-202.051 -84.424)" fill="#fff"/>
                    </g>
                    <g id="Groupe_333" data-name="Groupe 333">
                        <path id="Tracé_185" data-name="Tracé 185" d="M106.609,66.6a12.954,12.954,0,1,1,12.739,12.967A12.952,12.952,0,0,1,106.609,66.6Z" transform="translate(-78.59 -53.659)" fill="#fff"/>
                        <g id="Groupe_332" data-name="Groupe 332" transform="translate(0 9.681)">
                        <path id="Tracé_186" data-name="Tracé 186" d="M78.749,76.155c-.085,7.248,3.132,12.44,9.632,15.686-.046.064-.114.162-.186.256q-6.329,8.248-12.653,16.5a.743.743,0,0,1-.662.329q-10.389-.012-20.779-.006h-.44Z" transform="translate(-53.66 -73.932)" fill="#fff"/>
                        <path id="Tracé_187" data-name="Tracé 187" d="M122.61,106.94c.233-.308.4-.535.576-.759q7.79-10.047,15.579-20.094a19.45,19.45,0,0,0,3.7-7.185,14.985,14.985,0,0,0,.12-6.834.139.139,0,0,1,.025-.114c.088.255.177.509.263.764.091.272.179.545.269.818q2.876,8.792,5.745,17.586a6.481,6.481,0,0,1-.017,3.409,8.229,8.229,0,0,1-1.464,3.17q-3.269,4.435-6.525,8.879a.806.806,0,0,1-.742.367q-8.507-.014-17.013-.007Z" transform="translate(-86.124 -71.954)" fill="#fff"/>
                        <path id="Tracé_188" data-name="Tracé 188" d="M101.939,123.379c.083-.123.14-.217.207-.3q5.975-7.8,11.947-15.6a.42.42,0,0,1,.49-.168,15.913,15.913,0,0,0,7.081-.352c-.1.139-.149.228-.212.31q-6.109,7.957-12.226,15.908a.621.621,0,0,1-.412.236c-2.212.015-4.424.011-6.637.009A1.772,1.772,0,0,1,101.939,123.379Z" transform="translate(-76.391 -88.433)" fill="#fff"/>
                        </g>
                    </g>
                    <path id="Tracé_189" data-name="Tracé 189" d="M259.028,105.741l-.009.014c-.006.716-.01,1.432-.01,2.126v9.256h8.079V93.592Z" transform="translate(-150.345 -72.461)" fill="#fff"/>
                    <g id="Groupe_334" data-name="Groupe 334" transform="translate(69.588 0.295)">
                        <path id="Tracé_190" data-name="Tracé 190" d="M194.613,64.336a7.354,7.354,0,0,1-3.711,1,5.452,5.452,0,0,1-5.738-5.529,5.523,5.523,0,0,1,5.8-5.589,6.507,6.507,0,0,1,3.308.864v1.982a5.562,5.562,0,0,0-3.294-1.132,3.86,3.86,0,1,0,.015,7.719,4.021,4.021,0,0,0,1.758-.373V61.207H190.6V59.6h4.009Z" transform="translate(-185.164 -54.217)" fill="#fff"/>
                        <path id="Tracé_191" data-name="Tracé 191" d="M211.21,54.527h3.145a6.387,6.387,0,0,1,3.025.536,2.863,2.863,0,0,1,1.55,2.623,2.949,2.949,0,0,1-2.4,2.966l3.249,4.665h-2.22l-3.01-4.456H213.1v4.456H211.21Zm3.4,4.873a3.1,3.1,0,0,0,1.878-.432,1.485,1.485,0,0,0-.223-2.459,4.3,4.3,0,0,0-2.012-.3H213.1V59.4Z" transform="translate(-197.427 -54.363)" fill="#fff"/>
                        <path id="Tracé_192" data-name="Tracé 192" d="M231.539,59.776a5.559,5.559,0,1,1,5.559,5.559A5.448,5.448,0,0,1,231.539,59.776Zm9.225,0a3.67,3.67,0,1,0-3.666,3.83A3.684,3.684,0,0,0,240.764,59.776Z" transform="translate(-206.999 -54.217)" fill="#fff"/>
                        <path id="Tracé_193" data-name="Tracé 193" d="M267.328,60.071a6.5,6.5,0,0,1-.775,3.7,4.367,4.367,0,0,1-3.77,1.714,4.283,4.283,0,0,1-3.756-1.744,6.335,6.335,0,0,1-.76-3.547V54.527h1.893v5.544a5.129,5.129,0,0,0,.417,2.548,2.389,2.389,0,0,0,2.28,1.133,2.317,2.317,0,0,0,2.206-1.088A5.116,5.116,0,0,0,265.5,60.1V54.527h1.833Z" transform="translate(-219.583 -54.363)" fill="#fff"/>
                        <path id="Tracé_194" data-name="Tracé 194" d="M282.646,54.527h3.011a6.764,6.764,0,0,1,3.2.566A3.033,3.033,0,0,1,290.5,57.88a3.08,3.08,0,0,1-1.5,2.743,6.949,6.949,0,0,1-3.532.641h-.924v4.053h-1.893Zm2.966,5.156a4.355,4.355,0,0,0,2.235-.343,1.527,1.527,0,0,0,.76-1.4,1.5,1.5,0,0,0-.879-1.446,4.922,4.922,0,0,0-2.146-.283h-1.043v3.472Z" transform="translate(-231.062 -54.363)" fill="#fff"/>
                        <path id="Tracé_195" data-name="Tracé 195" d="M302.739,54.527h6.438v1.684h-4.546v2.683h3.95V60.5h-3.95v3.13h4.695v1.684h-6.587Z" transform="translate(-240.522 -54.363)" fill="#fff"/>
                    </g>
                    </svg>

        : null
        }
        {  this.props.name === "IMA-DEPANNAGE" ? 
            <svg id="DEPANNAGE-HELP-1-RVB" width="202.87" height="60" viewBox="0 0 202.87 60">
            <g id="Groupe_1566" data-name="Groupe 1566" transform="translate(0 33.094)">
                <path id="Tracé_6794" data-name="Tracé 6794" d="M.3,30.247V25H1.645v4.978a5.9,5.9,0,0,0,.4,2.556,2.452,2.452,0,0,0,2.287,1.211,2.235,2.235,0,0,0,2.287-1.211,5.621,5.621,0,0,0,.4-2.422V25H8.237v4.978a5.985,5.985,0,0,1-.673,3.363A3.828,3.828,0,0,1,4.2,34.821a3.574,3.574,0,0,1-3.229-1.48A4.622,4.622,0,0,1,.3,30.247Z" transform="translate(0.104 -24.462)" fill="#5b6e83"/>
                <path id="Tracé_6795" data-name="Tracé 6795" d="M7.6,27.435H8.811v.807h0A3.246,3.246,0,0,1,11.1,27.3a2.557,2.557,0,0,1,1.749.673c.4.4.538.942.538,2.018v4.036H12.174v-3.9A2.894,2.894,0,0,0,11.9,28.78a1.528,1.528,0,0,0-1.076-.4,2.745,2.745,0,0,0-2.018,1.076v4.574H7.6Z" transform="translate(2.624 -23.668)" fill="#5b6e83"/>
                <path id="Tracé_6796" data-name="Tracé 6796" d="M14.445,31.067a2.246,2.246,0,0,0,2.422,2.018,3.883,3.883,0,0,0,2.287-.807v1.345a4.3,4.3,0,0,1-2.422.673A3.426,3.426,0,0,1,13.1,30.8a3.286,3.286,0,0,1,3.363-3.5,2.9,2.9,0,0,1,2.96,3.229V31.2H14.445Zm.135-.942h3.9A1.78,1.78,0,0,0,16.6,28.376,1.914,1.914,0,0,0,14.58,30.125Z" transform="translate(4.523 -23.668)" fill="#5b6e83"/>
                <path id="Tracé_6797" data-name="Tracé 6797" d="M24.8,27.3a3.5,3.5,0,1,1-3.5,3.5A3.393,3.393,0,0,1,24.8,27.3Zm0,5.785a2.425,2.425,0,1,0-2.287-2.422A2.3,2.3,0,0,0,24.8,33.085Z" transform="translate(7.355 -23.668)" fill="#5b6e83"/>
                <path id="Tracé_6798" data-name="Tracé 6798" d="M28.276,29.443H27.2V28.367h1.076V27.156a3.242,3.242,0,0,1,.538-2.018,2.144,2.144,0,0,1,1.614-.538,7.586,7.586,0,0,1,.942.135v1.076a2.289,2.289,0,0,0-.942-.135c-.807,0-.942.538-.942,1.211v1.48h1.749v1.076H29.487v5.516H28.276Z" transform="translate(9.392 -24.6)" fill="#5b6e83"/>
                <path id="Tracé_6799" data-name="Tracé 6799" d="M31.776,29.443H30.7V28.367h1.076V27.156a3.242,3.242,0,0,1,.538-2.018,2.144,2.144,0,0,1,1.614-.538,7.586,7.586,0,0,1,.942.135v1.076a2.289,2.289,0,0,0-.942-.135c-.807,0-.942.538-.942,1.211v1.48h1.749v1.076H32.987v5.516H31.776Z" transform="translate(10.6 -24.6)" fill="#5b6e83"/>
                <path id="Tracé_6800" data-name="Tracé 6800" d="M34.735,27.435h1.211v.942h0A2.372,2.372,0,0,1,37.963,27.3h.4v1.211h-.538a2.219,2.219,0,0,0-2.018,1.211v4.3H34.6V27.435Z" transform="translate(11.947 -23.668)" fill="#5b6e83"/>
                <path id="Tracé_6801" data-name="Tracé 6801" d="M39.145,31.067a2.246,2.246,0,0,0,2.422,2.018,3.883,3.883,0,0,0,2.287-.807v1.345a4.3,4.3,0,0,1-2.422.673A3.426,3.426,0,0,1,37.8,30.8a3.286,3.286,0,0,1,3.363-3.5,2.9,2.9,0,0,1,2.96,3.229V31.2H39.145Zm0-.942h3.9a1.78,1.78,0,0,0-1.883-1.749A2.1,2.1,0,0,0,39.145,30.125Z" transform="translate(13.052 -23.668)" fill="#5b6e83"/>
                <path id="Tracé_6802" data-name="Tracé 6802" d="M46.3,25h1.345v4.17h5.381V25h1.345v9.821H53.026V30.381H47.645v4.439H46.3Z" transform="translate(15.987 -24.462)" fill="#5b6e83"/>
                <path id="Tracé_6803" data-name="Tracé 6803" d="M54.173,33.757a1.736,1.736,0,0,1-.673-1.48,1.712,1.712,0,0,1,.942-1.614,4.3,4.3,0,0,1,1.883-.4c.538,0,.942.135,1.48.135v-.538a1.324,1.324,0,0,0-.4-1.076,1.644,1.644,0,0,0-1.211-.4,3.3,3.3,0,0,0-2.018.673V27.838a4.585,4.585,0,0,1,2.152-.538,2.642,2.642,0,0,1,2.018.673,2.324,2.324,0,0,1,.673,1.749v2.825c0,.4.135.673.538.673a.494.494,0,0,0,.4-.135v1.076a1.008,1.008,0,0,1-.673.135,1.656,1.656,0,0,1-1.345-.673h0a3.028,3.028,0,0,1-2.018.673A4.471,4.471,0,0,1,54.173,33.757Zm3.363-1.211V31.336a4.576,4.576,0,0,0-1.345-.135c-.807,0-1.614.269-1.614,1.076,0,.673.538.942,1.211.942A4.305,4.305,0,0,0,57.536,32.547Z" transform="translate(18.473 -23.668)" fill="#5b6e83"/>
                <path id="Tracé_6804" data-name="Tracé 6804" d="M59.1,24.6h1.211v4.036h0a3.482,3.482,0,0,1,1.883-.538,3.22,3.22,0,0,1,3.229,3.363,3.393,3.393,0,0,1-3.5,3.5,2.869,2.869,0,0,1-1.614-.4l-1.211.269Zm2.825,9.417A2.3,2.3,0,0,0,64.212,31.6a2.085,2.085,0,0,0-2.152-2.287,2.557,2.557,0,0,0-1.749.673v3.767A5.782,5.782,0,0,0,61.925,34.017Z" transform="translate(20.407 -24.6)" fill="#5b6e83"/>
                <path id="Tracé_6805" data-name="Tracé 6805" d="M65.707,25a.807.807,0,0,1,0,1.614.807.807,0,0,1,0-1.614Zm-.538,3.229H66.38v6.592H65.169Z" transform="translate(22.409 -24.462)" fill="#5b6e83"/>
                <path id="Tracé_6806" data-name="Tracé 6806" d="M68.211,32.257V29.029H67V27.952h1.211V26.069l1.211-.269v2.018h2.018v1.076H69.422v2.96c0,.807.135,1.614.942,1.614A1.568,1.568,0,0,0,71.3,33.2V34.41a3.213,3.213,0,0,1-1.211.269,1.714,1.714,0,0,1-1.48-.673A3.6,3.6,0,0,1,68.211,32.257Z" transform="translate(23.135 -24.186)" fill="#5b6e83"/>
                <path id="Tracé_6807" data-name="Tracé 6807" d="M71.773,33.757a1.736,1.736,0,0,1-.673-1.48,1.712,1.712,0,0,1,.942-1.614,4.3,4.3,0,0,1,1.883-.4c.538,0,.942.135,1.48.135v-.538A1.324,1.324,0,0,0,75,28.78a1.644,1.644,0,0,0-1.211-.4,3.3,3.3,0,0,0-2.018.673V27.838a4.585,4.585,0,0,1,2.152-.538,2.642,2.642,0,0,1,2.018.673,2.324,2.324,0,0,1,.673,1.749v2.825c0,.4.135.673.538.673a.494.494,0,0,0,.4-.135v1.076a1.008,1.008,0,0,1-.673.135,1.656,1.656,0,0,1-1.345-.673h0a3.028,3.028,0,0,1-2.018.673A6.661,6.661,0,0,1,71.773,33.757Zm3.5-1.211V31.336a4.576,4.576,0,0,0-1.345-.135c-.807,0-1.614.269-1.614,1.076,0,.673.538.942,1.211.942A4.305,4.305,0,0,0,75.27,32.547Z" transform="translate(24.55 -23.668)" fill="#5b6e83"/>
                <path id="Tracé_6808" data-name="Tracé 6808" d="M77.511,32.257V29.029H76.3V27.952h1.211V26.069l1.211-.269v2.018h2.018v1.076H78.722v2.96c0,.807.135,1.614.942,1.614A1.568,1.568,0,0,0,80.6,33.2V34.41a3.213,3.213,0,0,1-1.211.269,1.714,1.714,0,0,1-1.48-.673A2.524,2.524,0,0,1,77.511,32.257Z" transform="translate(26.346 -24.186)" fill="#5b6e83"/>
                <path id="Tracé_6809" data-name="Tracé 6809" d="M3.5,40.2a2.869,2.869,0,0,1,1.614.4h0V36.7H6.323V47.059H5.112v-.538h0a3.482,3.482,0,0,1-1.883.538A3.22,3.22,0,0,1,0,43.7,3.393,3.393,0,0,1,3.5,40.2Zm-.135,5.785a3.815,3.815,0,0,0,1.749-.538V41.678a3.2,3.2,0,0,0-1.614-.4A2.3,2.3,0,0,0,1.211,43.7,2.085,2.085,0,0,0,3.363,45.983Z" transform="translate(0 -20.422)" fill="#5b6e83"/>
                <path id="Tracé_6810" data-name="Tracé 6810" d="M6.3,43.47V39.435H7.511V43.2a2.894,2.894,0,0,0,.269,1.345,1.116,1.116,0,0,0,1.076.4,2.745,2.745,0,0,0,2.018-1.076V39.3h1.211v6.592H11.009v-.807h0a3.246,3.246,0,0,1-2.287.942,2.169,2.169,0,0,1-1.749-.807C6.435,45.085,6.3,44.412,6.3,43.47Z" transform="translate(2.175 -19.524)" fill="#5b6e83"/>
                <path id="Tracé_6811" data-name="Tracé 6811" d="M19.412,37a5.95,5.95,0,0,1,2.96.807v1.48a4.7,4.7,0,0,0-2.96-1.076,3.9,3.9,0,0,0,0,7.8,4.492,4.492,0,0,0,2.018-.4V43.323H19.278V42.247h3.5v4.036a6.585,6.585,0,0,1-3.363.942,5.112,5.112,0,1,1,0-10.224Z" transform="translate(4.938 -20.318)" fill="#5b6e83"/>
                <path id="Tracé_6812" data-name="Tracé 6812" d="M22.135,37.1h2.691a4.633,4.633,0,0,1,2.691.538,2.453,2.453,0,0,1,1.345,2.287,2.611,2.611,0,0,1-1.211,2.287,1.789,1.789,0,0,1-1.076.4l3.094,4.3H28.054l-2.96-4.17H23.345v4.17H22V37.1Zm2.825,4.439a3.563,3.563,0,0,0,1.883-.4,1.311,1.311,0,0,0,.538-1.211,1.351,1.351,0,0,0-.807-1.345,4.929,4.929,0,0,0-2.152-.269H23.345v3.363H24.96Z" transform="translate(7.596 -20.284)" fill="#5b6e83"/>
                <path id="Tracé_6813" data-name="Tracé 6813" d="M33.078,37A4.978,4.978,0,1,1,28.1,41.978,4.883,4.883,0,0,1,33.078,37Zm0,8.879a3.683,3.683,0,0,0,3.632-3.9,3.768,3.768,0,0,0-3.632-3.9,3.683,3.683,0,0,0-3.632,3.9A3.683,3.683,0,0,0,33.078,45.879Z" transform="translate(9.703 -20.318)" fill="#5b6e83"/>
                <path id="Tracé_6814" data-name="Tracé 6814" d="M36.6,42.212V37.1h1.345v4.978a5.9,5.9,0,0,0,.4,2.556,2.452,2.452,0,0,0,2.287,1.211,2.235,2.235,0,0,0,2.287-1.211,5.621,5.621,0,0,0,.4-2.422V37.1h1.211v4.978a5.985,5.985,0,0,1-.673,3.363,3.828,3.828,0,0,1-3.363,1.48,3.574,3.574,0,0,1-3.229-1.48A6.042,6.042,0,0,1,36.6,42.212Z" transform="translate(12.638 -20.284)" fill="#5b6e83"/>
                <path id="Tracé_6815" data-name="Tracé 6815" d="M44,37.1h2.556a4.862,4.862,0,0,1,2.825.538,2.774,2.774,0,0,1,1.48,2.422,2.665,2.665,0,0,1-1.345,2.422,6.064,6.064,0,0,1-3.229.538h-.942v3.9H44Zm2.556,4.709c1.211,0,1.749,0,2.287-.4a1.545,1.545,0,0,0,.807-1.48,1.654,1.654,0,0,0-.942-1.48,5.6,5.6,0,0,0-2.287-.269H45.345v3.632Z" transform="translate(15.193 -20.284)" fill="#5b6e83"/>
                <path id="Tracé_6816" data-name="Tracé 6816" d="M50.3,37.1h5.65v1.211h-4.3v2.96h3.767v1.076H51.645V45.71h4.439v1.211H50.3Z" transform="translate(17.368 -20.284)" fill="#5b6e83"/>
                <path id="Tracé_6817" data-name="Tracé 6817" d="M58.2,46.821V37h2.691v9.821Z" transform="translate(20.096 -20.318)" fill="#5b6e83"/>
                <path id="Tracé_6818" data-name="Tracé 6818" d="M72.8,37l-5.516,7.8L64.591,41.17v5.65H61.9V37h2.691l2.691,3.632L69.837,37Zm-2.691,9.821V42.381L72.8,38.614v8.206Z" transform="translate(21.374 -20.318)" fill="#5b6e83"/>
                <path id="Tracé_6819" data-name="Tracé 6819" d="M74.8,37.1h2.691l3.9,9.821h-2.96l-.673-2.152H74.4l-.673,2.018H70.9Zm1.211,2.96a10.544,10.544,0,0,1-.673,1.883l-.269.807h1.883l-.269-.942c-.269-.673-.4-1.211-.673-1.749Z" transform="translate(24.481 -20.284)" fill="#5b6e83"/>
            </g>
            <g id="Groupe_1570" data-name="Groupe 1570">
                <g id="Groupe_1567" data-name="Groupe 1567" transform="translate(0 0.135)">
                <path id="Tracé_6820" data-name="Tracé 6820" d="M12.646,3.194A12.339,12.339,0,0,0,9.283.907,9.829,9.829,0,0,0,5.247.1H1.211C1.076.1,1.076.1.942.235L0,1.983v.269c0,.135.135.135.269.135H2.422V20.952a.289.289,0,0,0,.269.269H5.247a11.681,11.681,0,0,0,5.247-1.345A11.988,11.988,0,0,0,14.26,15.84a10.172,10.172,0,0,0,1.345-5.247,10.963,10.963,0,0,0-.807-4.17A10.439,10.439,0,0,0,12.646,3.194Zm.942,7.4a8.738,8.738,0,0,1-1.076,4.17,8.175,8.175,0,0,1-2.96,3.094,8.738,8.738,0,0,1-4.17,1.076H4.574V2.252h.538a9.247,9.247,0,0,1,3.229.673,9.289,9.289,0,0,1,2.691,1.749A7.773,7.773,0,0,1,12.78,7.365,7.164,7.164,0,0,1,13.587,10.593Z" transform="translate(0 -0.1)" fill="#283c50"/>
                <path id="Tracé_6821" data-name="Tracé 6821" d="M15.4,6.581c0,.135.135.135.269.135h.135l7.534-3.363.135-.135V3.083l-.942-1.749L22.4,1.2h-.135l-7,4.3A.264.264,0,0,0,15,5.774Z" transform="translate(5.179 0.28)" fill="#283c50"/>
                <path id="Tracé_6822" data-name="Tracé 6822" d="M26.146,15.552l-1.48-.807h-.135a.132.132,0,0,0-.135.135,4.089,4.089,0,0,1-1.614,1.749,4.646,4.646,0,0,1-2.422.673,5.564,5.564,0,0,1-2.556-.673L26.011,9.5c.135-.135.135-.269,0-.269A5.427,5.427,0,0,0,23.59,7.076,5.171,5.171,0,0,0,20.361,6,6.664,6.664,0,0,0,13.5,12.592,6.31,6.31,0,0,0,15.518,17.3a6.682,6.682,0,0,0,8.206,1.076,6.308,6.308,0,0,0,2.422-2.556C26.28,15.686,26.28,15.552,26.146,15.552ZM23.052,8.96l-6.726,5.919a4.535,4.535,0,0,1-.673-2.287A3.988,3.988,0,0,1,17,9.363a4.372,4.372,0,0,1,3.229-1.345A5.073,5.073,0,0,1,23.052,8.96Z" transform="translate(4.661 1.937)" fill="#283c50"/>
                <path id="Tracé_6823" data-name="Tracé 6823" d="M31.926,6a6.967,6.967,0,0,0-4.709,1.883L25.6,6.4c-.135-.135-.135-.135-.269,0-.135,0-.135.135-.135.269V27.121a.289.289,0,0,0,.269.269h1.614a.289.289,0,0,0,.269-.269V17.7a6.289,6.289,0,0,0,4.574,1.749,6.106,6.106,0,0,0,4.709-2.018,6.527,6.527,0,0,0,2.018-4.709,6.106,6.106,0,0,0-2.018-4.709A5.687,5.687,0,0,0,31.926,6Zm4.709,6.726a3.988,3.988,0,0,1-1.345,3.229A4.372,4.372,0,0,1,32.061,17.3a4.173,4.173,0,0,1-3.229-1.345,4.372,4.372,0,0,1-1.345-3.229A4.173,4.173,0,0,1,28.832,9.5a4.372,4.372,0,0,1,3.229-1.345A3.988,3.988,0,0,1,35.29,9.5,4.456,4.456,0,0,1,36.635,12.726Z" transform="translate(8.701 1.937)" fill="#283c50"/>
                <path id="Tracé_6824" data-name="Tracé 6824" d="M50.518,6.3h-.269l-1.614,1.48A6.723,6.723,0,0,0,43.926,5.9a6.31,6.31,0,0,0-4.709,2.018A6.527,6.527,0,0,0,37.2,12.626a6.31,6.31,0,0,0,2.018,4.709,6.527,6.527,0,0,0,4.709,2.018,6.967,6.967,0,0,0,4.709-1.883l1.614,1.48c.135,0,.135.135.269,0,.135,0,.135-.135.135-.269V6.573A.47.47,0,0,0,50.518,6.3ZM48.5,12.761a4.173,4.173,0,0,1-1.345,3.229,4.372,4.372,0,0,1-3.229,1.345A3.988,3.988,0,0,1,40.7,15.99a4.372,4.372,0,0,1-1.345-3.229A4.173,4.173,0,0,1,40.7,9.532a4.372,4.372,0,0,1,3.229-1.345,4.173,4.173,0,0,1,3.229,1.345A4.456,4.456,0,0,1,48.5,12.761Z" transform="translate(12.845 1.903)" fill="#283c50"/>
                <path id="Tracé_6825" data-name="Tracé 6825" d="M54.947,6a4.764,4.764,0,0,0-3.5,1.345L50.1,6.269c-.135,0-.135-.135-.269,0-.135,0-.135.135-.135.269V18.78a.289.289,0,0,0,.269.269h1.614a.289.289,0,0,0,.269-.269V11.112A3.3,3.3,0,0,1,52.66,8.96a3.055,3.055,0,0,1,2.152-.807,3.3,3.3,0,0,1,2.152.807,2.84,2.84,0,0,1,.807,2.152V18.78a.289.289,0,0,0,.269.269h1.614a.289.289,0,0,0,.269-.269V11.112a4.968,4.968,0,0,0-1.48-3.632A4.4,4.4,0,0,0,54.947,6Z" transform="translate(17.161 1.937)" fill="#283c50"/>
                <path id="Tracé_6826" data-name="Tracé 6826" d="M65.112,6a4.764,4.764,0,0,0-3.5,1.345L60.4,6.4c-.135,0-.135-.135-.269,0C60,6.4,60,6.538,60,6.673V18.915a.289.289,0,0,0,.269.269h1.614a.289.289,0,0,0,.269-.269v-7.8A3.3,3.3,0,0,1,62.96,8.96a3.055,3.055,0,0,1,2.152-.807,3.3,3.3,0,0,1,2.152.807,2.84,2.84,0,0,1,.807,2.152V18.78a.289.289,0,0,0,.269.269h1.614a.289.289,0,0,0,.269-.269V11.112a4.968,4.968,0,0,0-1.48-3.632A4.678,4.678,0,0,0,65.112,6Z" transform="translate(20.717 1.937)" fill="#283c50"/>
                <path id="Tracé_6827" data-name="Tracé 6827" d="M83.118,6.3h-.269l-1.614,1.48A6.723,6.723,0,0,0,76.526,5.9a6.31,6.31,0,0,0-4.709,2.018A6.527,6.527,0,0,0,69.8,12.626a6.31,6.31,0,0,0,2.018,4.709,6.527,6.527,0,0,0,4.709,2.018,6.967,6.967,0,0,0,4.709-1.883l1.614,1.48c.135,0,.135.135.269,0,.135,0,.135-.135.135-.269V6.573C83.253,6.438,83.253,6.3,83.118,6.3ZM81.1,12.761a4.173,4.173,0,0,1-1.345,3.229,4.372,4.372,0,0,1-3.229,1.345A3.988,3.988,0,0,1,73.3,15.99a4.372,4.372,0,0,1-1.345-3.229A3.988,3.988,0,0,1,73.3,9.532a4.372,4.372,0,0,1,3.229-1.345,4.173,4.173,0,0,1,3.229,1.345A3.988,3.988,0,0,1,81.1,12.761Z" transform="translate(24.101 1.903)" fill="#283c50"/>
                <path id="Tracé_6828" data-name="Tracé 6828" d="M95.418,6.3h-.269l-1.614,1.48A6.723,6.723,0,0,0,88.826,5.9a6.31,6.31,0,0,0-4.709,2.018A6.527,6.527,0,0,0,82.1,12.626a6.31,6.31,0,0,0,2.018,4.709,6.527,6.527,0,0,0,4.709,2.018A6.9,6.9,0,0,0,93.4,17.6v2.825a4.269,4.269,0,0,1-1.345,3.363,4.462,4.462,0,0,1-3.363,1.345,4.532,4.532,0,0,1-3.767-1.883l-.135-.135h-.135L83.311,24.33a.252.252,0,0,0,0,.4,6.7,6.7,0,0,0,5.516,2.691,6.89,6.89,0,0,0,6.861-6.861V6.573ZM93.4,12.761a4.173,4.173,0,0,1-1.345,3.229,4.372,4.372,0,0,1-3.229,1.345A3.988,3.988,0,0,1,85.6,15.99a4.372,4.372,0,0,1-1.345-3.229A3.988,3.988,0,0,1,85.6,9.532a4.372,4.372,0,0,1,3.229-1.345,4.173,4.173,0,0,1,3.229,1.345A3.988,3.988,0,0,1,93.4,12.761Z" transform="translate(28.348 1.903)" fill="#283c50"/>
                <path id="Tracé_6829" data-name="Tracé 6829" d="M106.946,15.552l-1.48-.807h-.135a.132.132,0,0,0-.135.135,4.089,4.089,0,0,1-1.614,1.749,4.646,4.646,0,0,1-2.422.673,5.564,5.564,0,0,1-2.556-.673l8.206-7.13c.135-.135.135-.269,0-.269a5.427,5.427,0,0,0-2.422-2.152A5.282,5.282,0,0,0,101.026,6a6.31,6.31,0,0,0-4.709,2.018A6.527,6.527,0,0,0,94.3,12.726a6.31,6.31,0,0,0,2.018,4.709,6.682,6.682,0,0,0,8.206,1.076,6.308,6.308,0,0,0,2.422-2.556Q107.148,15.552,106.946,15.552Zm-9.821-.538a4.535,4.535,0,0,1-.673-2.287A3.988,3.988,0,0,1,97.8,9.5a4.372,4.372,0,0,1,3.229-1.345,4.222,4.222,0,0,1,2.825.942Z" transform="translate(32.561 1.937)" fill="#283c50"/>
                </g>
                <g id="Groupe_1568" data-name="Groupe 1568" transform="translate(154.17)">
                <path id="Tracé_6830" data-name="Tracé 6830" d="M119.847,7.8a4.973,4.973,0,0,0-2.825.807V.4a.357.357,0,0,0-.4-.4H115a.357.357,0,0,0-.4.4V20.852a.357.357,0,0,0,.4.4h1.614a.357.357,0,0,0,.4-.4v-7.8a2.825,2.825,0,1,1,5.65,0v7.668a.357.357,0,0,0,.4.4h1.614a.357.357,0,0,0,.4-.4V13.049a5.523,5.523,0,0,0-1.48-3.767A5.523,5.523,0,0,0,119.847,7.8Z" transform="translate(-114.6)" fill="#00aa78"/>
                <path id="Tracé_6831" data-name="Tracé 6831" d="M137.246,15.421l-1.48-.807H135.5c-.135,0-.135.135-.269.135a5.038,5.038,0,0,1-1.614,1.749,4.224,4.224,0,0,1-4.574,0l8.072-7.13c.135-.135.135-.269.135-.538a6.637,6.637,0,0,0-2.422-2.287,7.437,7.437,0,0,0-3.363-.942,6.605,6.605,0,0,0-4.843,2.018,6.605,6.605,0,0,0-2.018,4.843,6.605,6.605,0,0,0,2.018,4.843,6.605,6.605,0,0,0,4.843,2.018,8.082,8.082,0,0,0,3.632-.942,6.644,6.644,0,0,0,2.422-2.691C137.515,15.824,137.38,15.555,137.246,15.421Zm-9.821-.538a3.948,3.948,0,0,1-.538-2.152A4.372,4.372,0,0,1,128.232,9.5a4.506,4.506,0,0,1,5.785-.538Z" transform="translate(-111.147 1.934)" fill="#00aa78"/>
                <path id="Tracé_6832" data-name="Tracé 6832" d="M138.318,0H136.7a.357.357,0,0,0-.4.4V20.852a.357.357,0,0,0,.4.4h1.614a.357.357,0,0,0,.4-.4V.4A.357.357,0,0,0,138.318,0Z" transform="translate(-107.107)" fill="#00aa78"/>
                <path id="Tracé_6833" data-name="Tracé 6833" d="M152.3,7.818A6.605,6.605,0,0,0,147.461,5.8a6.967,6.967,0,0,0-4.709,1.883l-1.48-1.48a.494.494,0,0,0-.4-.135c-.135,0-.269.135-.269.4V26.921a.357.357,0,0,0,.4.4h1.614a.357.357,0,0,0,.4-.4V17.908a6.444,6.444,0,0,0,4.439,1.614A6.605,6.605,0,0,0,152.3,17.5a6.6,6.6,0,0,0,2.018-4.843A6.6,6.6,0,0,0,152.3,7.818Zm-.4,4.843a4.372,4.372,0,0,1-1.345,3.229,4.456,4.456,0,0,1-3.229,1.345,3.9,3.9,0,0,1-3.094-1.345,3.9,3.9,0,0,1-1.345-3.094A3.9,3.9,0,0,1,144.232,9.7a4.087,4.087,0,0,1,3.094-1.345A4.372,4.372,0,0,1,150.555,9.7,3.463,3.463,0,0,1,151.9,12.661Z" transform="translate(-105.622 2.003)" fill="#00aa78"/>
                </g>
                <g id="Groupe_1569" data-name="Groupe 1569" transform="translate(143.946 16.009)">
                <circle id="Ellipse_1" data-name="Ellipse 1" cx="2.556" cy="2.556" r="2.556" fill="#00c378"/>
                </g>
            </g>
            </svg>
        : null
        }

        {  this.props.name === "IMA-TRAVAUX" ? 
            <svg id="TRAVAUX-HELP-1-RVB" width="156.452" height="60" viewBox="0 0 156.452 60">
            <g id="Groupe_1571" data-name="Groupe 1571" transform="translate(0 33.259)">
                <path id="Tracé_6834" data-name="Tracé 6834" d="M.3,30.588V25.4H1.63v5.055a5.834,5.834,0,0,0,.4,2.528,2.078,2.078,0,0,0,2.129,1.2,2.21,2.21,0,0,0,2.262-1.2,5.559,5.559,0,0,0,.4-2.395V25.533h1.2v5.055a5.919,5.919,0,0,1-.665,3.326,3.785,3.785,0,0,1-3.326,1.463A3.951,3.951,0,0,1,.7,33.914,8.8,8.8,0,0,1,.3,30.588Z" transform="translate(0.099 -24.868)" fill="#5b6e83"/>
                <path id="Tracé_6835" data-name="Tracé 6835" d="M7.7,27.833H8.9v.8h0a3.21,3.21,0,0,1,2.262-.931,2.529,2.529,0,0,1,1.729.665c.4.4.532.931.532,2v4.124h-1.2V30.627a2.862,2.862,0,0,0-.266-1.33,1.511,1.511,0,0,0-1.064-.4,3.285,3.285,0,0,0-2.129,1.064v4.523H7.7Z" transform="translate(2.544 -24.108)" fill="#5b6e83"/>
                <path id="Tracé_6836" data-name="Tracé 6836" d="M14.53,31.525a2.221,2.221,0,0,0,2.395,2,3.84,3.84,0,0,0,2.262-.8v1.33a4.254,4.254,0,0,1-2.395.665A3.388,3.388,0,0,1,13.2,31.259,3.25,3.25,0,0,1,16.526,27.8a2.966,2.966,0,0,1,3.06,3.193v.665H14.53Zm0-1.064h3.858a1.76,1.76,0,0,0-1.863-1.729A2.073,2.073,0,0,0,14.53,30.461Z" transform="translate(4.361 -24.075)" fill="#5b6e83"/>
                <path id="Tracé_6837" data-name="Tracé 6837" d="M24.961,27.7A3.459,3.459,0,1,1,21.5,31.159,3.282,3.282,0,0,1,24.961,27.7Zm0,5.854A2.4,2.4,0,1,0,22.7,31.159,2.2,2.2,0,0,0,24.961,33.554Z" transform="translate(7.101 -24.108)" fill="#5b6e83"/>
                <path id="Tracé_6838" data-name="Tracé 6838" d="M28.464,29.789H27.4V28.725h1.064v-1.2a2.974,2.974,0,0,1,.532-2,2.121,2.121,0,0,1,1.6-.532,7.5,7.5,0,0,1,.931.133V26.2a2.264,2.264,0,0,0-.931-.133c-.8,0-.931.532-.931,1.2v1.463h1.729v1.064H29.662v5.455h-1.2Z" transform="translate(9.052 -25)" fill="#5b6e83"/>
                <path id="Tracé_6839" data-name="Tracé 6839" d="M31.964,29.789H30.9V28.725h1.064v-1.2a2.974,2.974,0,0,1,.532-2,2.121,2.121,0,0,1,1.6-.532,7.5,7.5,0,0,1,.931.133V26.2a2.264,2.264,0,0,0-.931-.133c-.8,0-.931.532-.931,1.2v1.463h1.729v1.064H33.162v5.455h-1.2Z" transform="translate(10.209 -25)" fill="#5b6e83"/>
                <path id="Tracé_6840" data-name="Tracé 6840" d="M34.933,27.833h1.2v.931h0a2.346,2.346,0,0,1,2-1.064h.4v1.2h-.532a2.063,2.063,0,0,0-2,1.2v4.257H34.8V27.833Z" transform="translate(11.497 -24.108)" fill="#5b6e83"/>
                <path id="Tracé_6841" data-name="Tracé 6841" d="M39.33,31.525a2.221,2.221,0,0,0,2.395,2,3.84,3.84,0,0,0,2.262-.8v1.33a4.254,4.254,0,0,1-2.395.665A3.388,3.388,0,0,1,38,31.259,3.25,3.25,0,0,1,41.326,27.8a2.966,2.966,0,0,1,3.06,3.193v.665H39.33Zm0-1.064h3.991a1.76,1.76,0,0,0-1.863-1.729A2.106,2.106,0,0,0,39.33,30.461Z" transform="translate(12.554 -24.075)" fill="#5b6e83"/>
                <path id="Tracé_6842" data-name="Tracé 6842" d="M46.6,25.5h1.33v4.124h5.322V25.5h1.33v9.712h-1.33V30.688H47.93v4.523H46.6Z" transform="translate(15.396 -24.835)" fill="#5b6e83"/>
                <path id="Tracé_6843" data-name="Tracé 6843" d="M54.465,34.086a1.717,1.717,0,0,1-.665-1.463,1.693,1.693,0,0,1,.931-1.6,4.249,4.249,0,0,1,1.863-.4c.532,0,.931.133,1.463.133v-.532a1.31,1.31,0,0,0-.4-1.064,1.626,1.626,0,0,0-1.2-.4,2.994,2.994,0,0,0-2,.665v-1.2a4.7,4.7,0,0,1,2.262-.532,2.613,2.613,0,0,1,2,.665,2.3,2.3,0,0,1,.665,1.729v2.794c0,.4.133.665.532.665a.489.489,0,0,0,.4-.133v1.064a1,1,0,0,1-.665.133,1.637,1.637,0,0,1-1.33-.665h0a2.994,2.994,0,0,1-2,.665A2.6,2.6,0,0,1,54.465,34.086Zm3.459-1.064v-1.2a4.526,4.526,0,0,0-1.33-.133c-.8,0-1.6.266-1.6,1.064,0,.665.532.931,1.2.931A5.217,5.217,0,0,0,57.924,33.022Z" transform="translate(17.774 -24.108)" fill="#5b6e83"/>
                <path id="Tracé_6844" data-name="Tracé 6844" d="M59.5,25.1h1.2v3.991h0a3.443,3.443,0,0,1,1.863-.532,3.185,3.185,0,0,1,3.193,3.326,3.467,3.467,0,0,1-3.459,3.592,2.837,2.837,0,0,1-1.6-.4l-1.2.266V25.1Zm2.794,9.313a2.275,2.275,0,0,0,2.262-2.395,2.173,2.173,0,0,0-2.129-2.395,2.529,2.529,0,0,0-1.729.665v3.725A3.16,3.16,0,0,0,62.294,34.413Z" transform="translate(19.657 -24.967)" fill="#5b6e83"/>
                <path id="Tracé_6845" data-name="Tracé 6845" d="M66.1,25.5a.8.8,0,1,1-.8.8A.858.858,0,0,1,66.1,25.5Zm-.532,3.06h1.2v6.652h-1.2Z" transform="translate(21.574 -24.835)" fill="#5b6e83"/>
                <path id="Tracé_6846" data-name="Tracé 6846" d="M68.7,32.686V29.493H67.5V28.429h1.2V26.566l1.2-.266v2h2V29.36h-2v3.06c0,.8.133,1.6.931,1.6a1.551,1.551,0,0,0,.931-.266v1.2a3.177,3.177,0,0,1-1.2.266,1.7,1.7,0,0,1-1.463-.665A3.22,3.22,0,0,1,68.7,32.686Z" transform="translate(22.3 -24.571)" fill="#5b6e83"/>
                <path id="Tracé_6847" data-name="Tracé 6847" d="M72.265,34.086a1.717,1.717,0,0,1-.665-1.463,1.693,1.693,0,0,1,.931-1.6,4.249,4.249,0,0,1,1.863-.4c.532,0,.931.133,1.463.133v-.532a1.309,1.309,0,0,0-.4-1.064,1.626,1.626,0,0,0-1.2-.4,2.994,2.994,0,0,0-2,.665v-1.2a4.7,4.7,0,0,1,2.262-.532,2.613,2.613,0,0,1,2,.665,2.3,2.3,0,0,1,.665,1.729v2.794c0,.4.133.665.532.665a.489.489,0,0,0,.4-.133v1.064a1,1,0,0,1-.665.133,1.637,1.637,0,0,1-1.33-.665h0a2.994,2.994,0,0,1-2,.665A2.6,2.6,0,0,1,72.265,34.086Zm3.459-1.064v-1.2a4.526,4.526,0,0,0-1.33-.133c-.8,0-1.6.266-1.6,1.064,0,.665.532.931,1.2.931A5.217,5.217,0,0,0,75.724,33.022Z" transform="translate(23.655 -24.108)" fill="#5b6e83"/>
                <path id="Tracé_6848" data-name="Tracé 6848" d="M78,32.686V29.493H76.8V28.429H78V26.566l1.2-.266v2h2V29.36h-2v3.06c0,.8.133,1.6.931,1.6a1.551,1.551,0,0,0,.931-.266v1.2a3.177,3.177,0,0,1-1.2.266,1.7,1.7,0,0,1-1.463-.665A3.22,3.22,0,0,1,78,32.686Z" transform="translate(25.373 -24.571)" fill="#5b6e83"/>
                <path id="Tracé_6849" data-name="Tracé 6849" d="M3.459,40.659a2.837,2.837,0,0,1,1.6.4h0V37.2h1.2V47.444h-1.2v-.4h0a3.443,3.443,0,0,1-1.863.532A3.185,3.185,0,0,1,0,44.251,3.467,3.467,0,0,1,3.459,40.659Zm0,5.854a3.773,3.773,0,0,0,1.729-.532V42.255a3.16,3.16,0,0,0-1.6-.4A2.275,2.275,0,0,0,1.33,44.251,1.99,1.99,0,0,0,3.459,46.513Z" transform="translate(0 -20.969)" fill="#5b6e83"/>
                <path id="Tracé_6850" data-name="Tracé 6850" d="M6.3,43.991V40H7.5v3.858a2.862,2.862,0,0,0,.266,1.33,1.1,1.1,0,0,0,1.064.4,3.285,3.285,0,0,0,2.129-1.064V40h1.2v6.652h-1.2v-.8h0a3.21,3.21,0,0,1-2.262.931,2.627,2.627,0,0,1-1.863-.8A2.974,2.974,0,0,1,6.3,43.991Z" transform="translate(2.081 -20.044)" fill="#5b6e83"/>
                <path id="Tracé_6851" data-name="Tracé 6851" d="M19.455,37.5a5.884,5.884,0,0,1,2.927.8v1.463a4.744,4.744,0,0,0-3.06-1.064,3.778,3.778,0,0,0-3.858,3.858,3.7,3.7,0,0,0,3.858,3.858,4.443,4.443,0,0,0,2-.4V43.753H19.189V42.688h3.459V46.68a7.519,7.519,0,0,1-3.193.8,4.989,4.989,0,1,1,0-9.978Z" transform="translate(4.757 -20.87)" fill="#5b6e83"/>
                <path id="Tracé_6852" data-name="Tracé 6852" d="M22.2,37.6h2.661a4.582,4.582,0,0,1,2.661.532,2.426,2.426,0,0,1,1.33,2.262,2.583,2.583,0,0,1-1.2,2.262,1.769,1.769,0,0,1-1.064.4l3.06,4.257h-1.6l-2.927-4.124h-1.6v4.124H22.2V37.6Zm2.927,4.523a3.524,3.524,0,0,0,1.863-.4,1.3,1.3,0,0,0,.532-1.2,1.336,1.336,0,0,0-.8-1.33,4.874,4.874,0,0,0-2.129-.266H23.4v3.193Z" transform="translate(7.334 -20.837)" fill="#5b6e83"/>
                <path id="Tracé_6853" data-name="Tracé 6853" d="M33.222,37.5a4.862,4.862,0,0,1,4.922,5.055,4.941,4.941,0,0,1-4.922,5.055,5.057,5.057,0,0,1,0-10.111Zm0,8.78A3.86,3.86,0,1,0,29.5,42.422,3.835,3.835,0,0,0,33.222,46.28Z" transform="translate(9.35 -20.87)" fill="#5b6e83"/>
                <path id="Tracé_6854" data-name="Tracé 6854" d="M36.9,42.688V37.5h1.33v5.055a5.834,5.834,0,0,0,.4,2.528,2.425,2.425,0,0,0,2.262,1.2,2.21,2.21,0,0,0,2.262-1.2,5.559,5.559,0,0,0,.4-2.395V37.633h1.2v5.055a5.919,5.919,0,0,1-.665,3.326,3.785,3.785,0,0,1-3.326,1.463,3.951,3.951,0,0,1-3.326-1.463A6.506,6.506,0,0,1,36.9,42.688Z" transform="translate(12.191 -20.87)" fill="#5b6e83"/>
                <path id="Tracé_6855" data-name="Tracé 6855" d="M44.333,37.6h2.528a5.491,5.491,0,0,1,2.927.532,2.743,2.743,0,0,1,1.463,2.395,2.636,2.636,0,0,1-1.33,2.395,6.506,6.506,0,0,1-3.326.532H45.53v3.858H44.2V37.6Zm2.395,4.789c1.2,0,1.729,0,2.262-.4a1.528,1.528,0,0,0,.8-1.463,1.636,1.636,0,0,0-.931-1.463,5.54,5.54,0,0,0-2.262-.266H45.4v3.592h1.33Z" transform="translate(14.603 -20.837)" fill="#5b6e83"/>
                <path id="Tracé_6856" data-name="Tracé 6856" d="M50.6,37.6h5.588v1.2H51.8v2.927h3.858v1.064H51.8v3.326h4.523v1.2H50.6V37.6Z" transform="translate(16.717 -20.837)" fill="#5b6e83"/>
                <path id="Tracé_6857" data-name="Tracé 6857" d="M58.6,47.345V37.5h2.661v9.845Z" transform="translate(19.36 -20.87)" fill="#5b6e83"/>
                <path id="Tracé_6858" data-name="Tracé 6858" d="M73.076,37.5l-5.455,7.849-2.661-3.592v5.588H62.3V37.5h2.661l2.661,3.592L70.149,37.5Zm-2.661,9.845v-4.39l2.661-3.725v8.248H70.415Z" transform="translate(20.582 -20.87)" fill="#5b6e83"/>
                <path id="Tracé_6859" data-name="Tracé 6859" d="M75.191,37.6h2.661l3.991,9.712h-3.06l-.665-2H74.792l-.665,2H71.2Zm1.33,2.927a10.426,10.426,0,0,1-.665,1.863l-.4.931H77.32l-.266-.931a9.8,9.8,0,0,0-.532-1.863Z" transform="translate(23.523 -20.837)" fill="#5b6e83"/>
            </g>
            <g id="Groupe_1572" data-name="Groupe 1572" transform="translate(0.133 33.259)">
                <path id="Tracé_6860" data-name="Tracé 6860" d="M.3,30.588V25.4H1.63v5.055a5.834,5.834,0,0,0,.4,2.528c.266.665.931,1.2,2.262,1.2a2.21,2.21,0,0,0,2.262-1.2,5.559,5.559,0,0,0,.4-2.395V25.533h1.2v5.055a5.919,5.919,0,0,1-.665,3.326,3.785,3.785,0,0,1-3.326,1.463A3.951,3.951,0,0,1,.832,33.914,9.186,9.186,0,0,1,.3,30.588Z" transform="translate(-0.034 -24.868)" fill="#5b6e83"/>
                <path id="Tracé_6861" data-name="Tracé 6861" d="M7.8,27.833H9v.8H9a3.21,3.21,0,0,1,2.262-.931,2.529,2.529,0,0,1,1.729.665c.4.4.532.931.532,2v4.124h-1.2V30.627a2.862,2.862,0,0,0-.266-1.33,1.511,1.511,0,0,0-1.064-.4,3.285,3.285,0,0,0-2.129,1.064v4.523H7.8Z" transform="translate(2.444 -24.108)" fill="#5b6e83"/>
                <path id="Tracé_6862" data-name="Tracé 6862" d="M14.63,31.425a2.221,2.221,0,0,0,2.395,2,3.84,3.84,0,0,0,2.262-.8v1.33a4.254,4.254,0,0,1-2.395.665A3.388,3.388,0,0,1,13.3,31.159,3.25,3.25,0,0,1,16.626,27.7a2.966,2.966,0,0,1,3.06,3.193v.665H14.63Zm0-.931h3.858a1.76,1.76,0,0,0-1.863-1.729A2.073,2.073,0,0,0,14.63,30.494Z" transform="translate(4.261 -24.108)" fill="#5b6e83"/>
                <path id="Tracé_6863" data-name="Tracé 6863" d="M24.959,27.7A3.459,3.459,0,1,1,21.5,31.159,3.355,3.355,0,0,1,24.959,27.7Zm0,5.721A2.4,2.4,0,1,0,22.7,31.026,2.2,2.2,0,0,0,24.959,33.421Z" transform="translate(6.97 -24.108)" fill="#5b6e83"/>
                <path id="Tracé_6864" data-name="Tracé 6864" d="M28.564,29.789H27.5V28.725h1.064v-1.2a2.974,2.974,0,0,1,.532-2,2.121,2.121,0,0,1,1.6-.532,7.5,7.5,0,0,1,.931.133V26.2a2.264,2.264,0,0,0-.931-.133c-.8,0-.931.532-.931,1.2v1.463h1.729v1.064H29.762v5.455h-1.2Z" transform="translate(8.952 -25)" fill="#5b6e83"/>
                <path id="Tracé_6865" data-name="Tracé 6865" d="M31.964,29.789H30.9V28.725h1.064v-1.2a2.974,2.974,0,0,1,.532-2,2.121,2.121,0,0,1,1.6-.532,7.5,7.5,0,0,1,.931.133V26.2a2.264,2.264,0,0,0-.931-.133c-.8,0-.931.532-.931,1.2v1.463h1.729v1.064H33.162v5.455h-1.2Z" transform="translate(10.076 -25)" fill="#5b6e83"/>
                <path id="Tracé_6866" data-name="Tracé 6866" d="M34.933,27.833h1.2v.931h0a2.346,2.346,0,0,1,2-1.064h.4v1.2h-.532a2.063,2.063,0,0,0-2,1.2v4.257H34.8V27.833Z" transform="translate(11.364 -24.108)" fill="#5b6e83"/>
                <path id="Tracé_6867" data-name="Tracé 6867" d="M39.43,31.425a2.221,2.221,0,0,0,2.395,2,3.84,3.84,0,0,0,2.262-.8v1.33a4.254,4.254,0,0,1-2.395.665A3.388,3.388,0,0,1,38.1,31.159,3.25,3.25,0,0,1,41.426,27.7a2.966,2.966,0,0,1,3.06,3.193v.665H39.43Zm0-.931h3.858a1.76,1.76,0,0,0-1.863-1.729A2.073,2.073,0,0,0,39.43,30.494Z" transform="translate(12.454 -24.108)" fill="#5b6e83"/>
                <path id="Tracé_6868" data-name="Tracé 6868" d="M46.7,25.4h1.33v4.124h5.322V25.4h1.33v9.712h-1.33V30.588H48.03v4.523H46.7Z" transform="translate(15.296 -24.868)" fill="#5b6e83"/>
                <path id="Tracé_6869" data-name="Tracé 6869" d="M54.565,34.086a1.717,1.717,0,0,1-.665-1.463,1.693,1.693,0,0,1,.931-1.6,4.249,4.249,0,0,1,1.863-.4c.532,0,.931.133,1.463.133v-.532a1.31,1.31,0,0,0-.4-1.064,1.626,1.626,0,0,0-1.2-.4,2.994,2.994,0,0,0-2,.665v-1.2a4.7,4.7,0,0,1,2.262-.532,2.613,2.613,0,0,1,2,.665,2.3,2.3,0,0,1,.665,1.729v2.794c0,.4.133.665.532.665a.489.489,0,0,0,.4-.133v1.064a1,1,0,0,1-.665.133,1.637,1.637,0,0,1-1.33-.665h0a2.994,2.994,0,0,1-2,.665A4.463,4.463,0,0,1,54.565,34.086Zm3.326-1.2v-1.2a4.526,4.526,0,0,0-1.33-.133c-.8,0-1.6.266-1.6,1.064,0,.665.532.931,1.2.931A4.258,4.258,0,0,0,57.891,32.888Z" transform="translate(17.674 -24.108)" fill="#5b6e83"/>
                <path id="Tracé_6870" data-name="Tracé 6870" d="M59.5,25h1.2v3.991h0a3.443,3.443,0,0,1,1.863-.532,3.185,3.185,0,0,1,3.193,3.326,3.467,3.467,0,0,1-3.459,3.592,2.837,2.837,0,0,1-1.6-.4l-1.2.266V25Zm2.927,9.313a2.275,2.275,0,0,0,2.262-2.395,2.173,2.173,0,0,0-2.129-2.395,2.529,2.529,0,0,0-1.729.665v3.725A3.16,3.16,0,0,0,62.427,34.313Z" transform="translate(19.524 -25)" fill="#5b6e83"/>
                <path id="Tracé_6871" data-name="Tracé 6871" d="M66.2,25.4a.8.8,0,1,1,0,1.6.858.858,0,0,1-.8-.8A.767.767,0,0,1,66.2,25.4Zm-.665,3.193h1.2v6.652h-1.2Z" transform="translate(21.474 -24.868)" fill="#5b6e83"/>
                <path id="Tracé_6872" data-name="Tracé 6872" d="M68.7,32.586V29.393H67.5V28.329h1.2V26.466l1.2-.266v2h2V29.26h-2v3.06c0,.8.133,1.6.931,1.6a1.551,1.551,0,0,0,.931-.266v1.2a3.177,3.177,0,0,1-1.2.266,1.7,1.7,0,0,1-1.463-.665A4.867,4.867,0,0,1,68.7,32.586Z" transform="translate(22.167 -24.604)" fill="#5b6e83"/>
                <path id="Tracé_6873" data-name="Tracé 6873" d="M72.365,34.086a1.717,1.717,0,0,1-.665-1.463,1.693,1.693,0,0,1,.931-1.6,4.249,4.249,0,0,1,1.863-.4c.532,0,.931.133,1.463.133v-.532a1.309,1.309,0,0,0-.4-1.064,1.626,1.626,0,0,0-1.2-.4,2.994,2.994,0,0,0-2,.665v-1.2a4.7,4.7,0,0,1,2.262-.532,2.613,2.613,0,0,1,2,.665,2.3,2.3,0,0,1,.665,1.729v2.794c0,.4.133.665.532.665a.489.489,0,0,0,.4-.133v1.064a1,1,0,0,1-.665.133,1.637,1.637,0,0,1-1.33-.665h0a2.994,2.994,0,0,1-2,.665A4.463,4.463,0,0,1,72.365,34.086Zm3.326-1.2v-1.2a4.526,4.526,0,0,0-1.33-.133c-.8,0-1.6.266-1.6,1.064,0,.665.532.931,1.2.931A4.257,4.257,0,0,0,75.691,32.888Z" transform="translate(23.555 -24.108)" fill="#5b6e83"/>
                <path id="Tracé_6874" data-name="Tracé 6874" d="M78,32.586V29.393H76.8V28.329H78V26.466l1.2-.266v2h2V29.26h-2v3.06c0,.8.133,1.6.931,1.6a1.551,1.551,0,0,0,.931-.266v1.2a3.177,3.177,0,0,1-1.2.266,1.7,1.7,0,0,1-1.463-.665A3.22,3.22,0,0,1,78,32.586Z" transform="translate(25.24 -24.604)" fill="#5b6e83"/>
                <path id="Tracé_6875" data-name="Tracé 6875" d="M3.559,40.659a2.837,2.837,0,0,1,1.6.4h0V37.2h1.2V47.444h-1.2v-.4h0a3.443,3.443,0,0,1-1.863.532A3.185,3.185,0,0,1,.1,44.251,3.318,3.318,0,0,1,3.559,40.659ZM3.426,46.38a3.773,3.773,0,0,0,1.729-.532V42.122a3.16,3.16,0,0,0-1.6-.4A2.275,2.275,0,0,0,1.3,44.118,2.062,2.062,0,0,0,3.426,46.38Z" transform="translate(-0.1 -20.969)" fill="#5b6e83"/>
                <path id="Tracé_6876" data-name="Tracé 6876" d="M6.4,43.891V39.9H7.6v3.858a2.862,2.862,0,0,0,.266,1.33,1.511,1.511,0,0,0,1.064.4,3.285,3.285,0,0,0,2.129-1.064V39.9h1.2v6.652h-1.2v-.8h0a3.21,3.21,0,0,1-2.262.931,2.627,2.627,0,0,1-1.863-.8A2.762,2.762,0,0,1,6.4,43.891Z" transform="translate(1.981 -20.077)" fill="#5b6e83"/>
                <path id="Tracé_6877" data-name="Tracé 6877" d="M19.588,37.4a5.884,5.884,0,0,1,2.927.8v1.463a4.744,4.744,0,0,0-3.06-1.064A3.778,3.778,0,0,0,15.6,42.455a3.7,3.7,0,0,0,3.858,3.858,4.443,4.443,0,0,0,2-.4V43.52H19.322V42.455h3.459v3.991a6.512,6.512,0,0,1-3.326.931A4.862,4.862,0,0,1,14.4,42.455,5,5,0,0,1,19.588,37.4Z" transform="translate(4.624 -20.903)" fill="#5b6e83"/>
                <path id="Tracé_6878" data-name="Tracé 6878" d="M22.3,37.5h2.661a4.582,4.582,0,0,1,2.661.532,2.426,2.426,0,0,1,1.33,2.262,2.583,2.583,0,0,1-1.2,2.262,1.769,1.769,0,0,1-1.064.4l3.06,4.257h-1.6l-2.927-4.124h-1.6v4.124H22.3V37.5Zm2.927,4.523a3.523,3.523,0,0,0,1.863-.4,1.3,1.3,0,0,0,.532-1.2,1.336,1.336,0,0,0-.8-1.33,4.874,4.874,0,0,0-2.129-.266H23.5v3.326h1.729Z" transform="translate(7.234 -20.87)" fill="#5b6e83"/>
                <path id="Tracé_6879" data-name="Tracé 6879" d="M33.322,37.4A5.057,5.057,0,1,1,28.4,42.455,4.862,4.862,0,0,1,33.322,37.4Zm0,8.914A3.86,3.86,0,1,0,29.6,42.455,3.749,3.749,0,0,0,33.322,46.314Z" transform="translate(9.25 -20.903)" fill="#5b6e83"/>
                <path id="Tracé_6880" data-name="Tracé 6880" d="M36.9,42.688V37.5h1.33v5.055a5.834,5.834,0,0,0,.4,2.528,2.425,2.425,0,0,0,2.262,1.2,2.21,2.21,0,0,0,2.262-1.2,5.559,5.559,0,0,0,.4-2.395V37.633h1.2v5.055a5.919,5.919,0,0,1-.665,3.326,3.862,3.862,0,0,1-3.193,1.463,3.951,3.951,0,0,1-3.326-1.463A6.781,6.781,0,0,1,36.9,42.688Z" transform="translate(12.058 -20.87)" fill="#5b6e83"/>
                <path id="Tracé_6881" data-name="Tracé 6881" d="M44.433,37.5h2.528a5.491,5.491,0,0,1,2.927.532,2.743,2.743,0,0,1,1.463,2.395,2.636,2.636,0,0,1-1.33,2.395,6.781,6.781,0,0,1-3.326.665H45.63v3.858H44.3V37.5Zm2.395,4.789c1.2,0,1.729,0,2.262-.4a1.528,1.528,0,0,0,.8-1.463,1.636,1.636,0,0,0-.931-1.463,5.54,5.54,0,0,0-2.262-.266H45.5v3.592h1.33Z" transform="translate(14.503 -20.87)" fill="#5b6e83"/>
                <path id="Tracé_6882" data-name="Tracé 6882" d="M50.6,37.5h5.588v1.2H51.8v2.927h3.858v1.064H51.8v3.459h4.656v1.2H50.733V37.5Z" transform="translate(16.584 -20.87)" fill="#5b6e83"/>
                <path id="Tracé_6883" data-name="Tracé 6883" d="M58.6,47.245V37.4h2.661v9.845Z" transform="translate(19.227 -20.903)" fill="#5b6e83"/>
                <path id="Tracé_6884" data-name="Tracé 6884" d="M73.176,37.5l-5.455,7.849-2.661-3.592v5.588H62.4V37.5h2.661l2.661,3.592L70.249,37.5Zm-2.661,9.712v-4.39L73.176,39.1v8.248H70.515Z" transform="translate(20.482 -20.87)" fill="#5b6e83"/>
                <path id="Tracé_6885" data-name="Tracé 6885" d="M75.291,37.5h2.661l3.991,9.712h-3.06l-.665-2H74.892l-.665,2H71.3Zm1.33,3.06a10.428,10.428,0,0,1-.665,1.863l-.4.931h2l-.266-.931a7.99,7.99,0,0,1-.665-1.863Z" transform="translate(23.423 -20.87)" fill="#5b6e83"/>
            </g>
            <g id="Groupe_1576" data-name="Groupe 1576" transform="translate(0.133)">
                <g id="Groupe_1573" data-name="Groupe 1573" transform="translate(107.228)">
                <path id="Tracé_6886" data-name="Tracé 6886" d="M86.021,7.849a5.7,5.7,0,0,0-2.927.8V.4a.353.353,0,0,0-.4-.4H81.1a.353.353,0,0,0-.4.4V20.887a.353.353,0,0,0,.4.4h1.6a.353.353,0,0,0,.4-.4V13.171a2.822,2.822,0,0,1,4.789-2,2.809,2.809,0,0,1,.8,2.129V21.02a.353.353,0,0,0,.4.4h1.6a.353.353,0,0,0,.4-.4V13.171a4.831,4.831,0,0,0-1.6-3.725A3.8,3.8,0,0,0,86.021,7.849Z" transform="translate(-80.7)" fill="#00aa78"/>
                <path id="Tracé_6887" data-name="Tracé 6887" d="M103.706,15.612l-1.463-.8h-.266c-.133,0-.133.133-.266.133a4.043,4.043,0,0,1-1.6,1.729,4.177,4.177,0,0,1-4.523,0l8.115-7.051a.8.8,0,0,0,.133-.532,6.516,6.516,0,0,0-2.395-2.262A7.355,7.355,0,0,0,98.118,5.9a7.058,7.058,0,0,0-4.922,2,6.618,6.618,0,0,0-2,4.922,7.058,7.058,0,0,0,2,4.922,6.832,6.832,0,0,0,4.922,2,6.691,6.691,0,0,0,3.592-1.064,8.063,8.063,0,0,0,2.528-2.661C103.972,15.878,103.839,15.612,103.706,15.612Zm-3.459-6.519-6.519,5.854a3.9,3.9,0,0,1-.532-2.129,4.126,4.126,0,0,1,1.33-3.193,4.456,4.456,0,0,1,5.721-.532Z" transform="translate(-77.231 1.949)" fill="#00aa78"/>
                <path id="Tracé_6888" data-name="Tracé 6888" d="M104.9,0h-1.6a.353.353,0,0,0-.4.4V20.887a.353.353,0,0,0,.4.4h1.6a.353.353,0,0,0,.4-.4V.4A.353.353,0,0,0,104.9,0Z" transform="translate(-73.366)" fill="#00aa78"/>
                <path id="Tracé_6889" data-name="Tracé 6889" d="M119.04,7.9a6.618,6.618,0,0,0-4.922-2,6.421,6.421,0,0,0-4.656,1.863l-1.6-1.463a.489.489,0,0,0-.4-.133c-.133,0-.266.133-.266.4V27.186a.353.353,0,0,0,.4.4h1.6a.353.353,0,0,0,.4-.4v-9.18a6.714,6.714,0,0,0,4.523,1.6,6.816,6.816,0,0,0,6.918-6.918A6.752,6.752,0,0,0,119.04,7.9Zm-.4,4.922a4.126,4.126,0,0,1-1.33,3.193,4.126,4.126,0,0,1-3.193,1.33,4.324,4.324,0,0,1-3.193-1.33,4.126,4.126,0,0,1-1.33-3.193,4.324,4.324,0,0,1,1.33-3.193,4.5,4.5,0,0,1,6.386,0A4.126,4.126,0,0,1,118.641,12.818Z" transform="translate(-71.945 1.949)" fill="#00aa78"/>
                </g>
                <g id="Groupe_1574" data-name="Groupe 1574" transform="translate(0 0.399)">
                <path id="Tracé_6890" data-name="Tracé 6890" d="M10.078.3H.366A.286.286,0,0,0,.1.566v1.6a.286.286,0,0,0,.266.266H4.224V21.054a.286.286,0,0,0,.266.266h1.6a.286.286,0,0,0,.266-.266V2.429h3.858a.286.286,0,0,0,.266-.266V.566C10.344.433,10.211.3,10.078.3Z" transform="translate(-0.1 -0.3)" fill="#283c50"/>
                <path id="Tracé_6891" data-name="Tracé 6891" d="M16.785,6.466A3.652,3.652,0,0,0,15.055,6.2,4.711,4.711,0,0,0,11.6,7.53L10.4,6.6c-.133,0-.133-.133-.266,0C10,6.6,10,6.6,10,6.732V19.1a.286.286,0,0,0,.266.266h1.6a.286.286,0,0,0,.266-.266V11.255a2.708,2.708,0,0,1,.8-2,2.809,2.809,0,0,1,2.129-.8,1.687,1.687,0,0,1,.8.133c.133,0,.266,0,.266-.133L16.918,7V6.865A.583.583,0,0,0,16.785,6.466Z" transform="translate(3.171 1.649)" fill="#283c50"/>
                <path id="Tracé_6892" data-name="Tracé 6892" d="M30.4,6.5h-.266l-1.6,1.463A6.317,6.317,0,0,0,23.885,6.1a6.532,6.532,0,0,0-4.789,2,6.532,6.532,0,0,0-2,4.789,6.532,6.532,0,0,0,2,4.789,6.532,6.532,0,0,0,4.789,2,6.648,6.648,0,0,0,4.656-1.863l1.6,1.463c.133.133.133.133.266,0,.133,0,.133-.133.133-.266V6.765C30.537,6.632,30.4,6.632,30.4,6.5ZM23.752,17.541A4.547,4.547,0,0,1,19.1,12.885a4.547,4.547,0,0,1,4.656-4.656,3.944,3.944,0,0,1,3.193,1.33,4.324,4.324,0,0,1,1.33,3.193,3.944,3.944,0,0,1-1.33,3.193A3.63,3.63,0,0,1,23.752,17.541Z" transform="translate(5.516 1.616)" fill="#283c50"/>
                <path id="Tracé_6893" data-name="Tracé 6893" d="M40.042,6.4H38.313c-.133,0-.133,0-.266.133l-3.326,7.849L31.395,6.533c0-.133-.133-.133-.266-.133H29.266c-.133,0-.133,0-.266.133V6.8l5.455,12.772c0,.133.133.133.266.133s.133,0,.266-.133L40.441,6.8V6.533C40.308,6.533,40.175,6.4,40.042,6.4Z" transform="translate(9.448 1.715)" fill="#283c50"/>
                <path id="Tracé_6894" data-name="Tracé 6894" d="M52.4,6.5h-.266l-1.6,1.463A6.317,6.317,0,0,0,45.885,6.1a6.532,6.532,0,0,0-4.789,2,6.532,6.532,0,0,0-2,4.789,6.532,6.532,0,0,0,2,4.789,6.532,6.532,0,0,0,4.789,2,6.648,6.648,0,0,0,4.656-1.863l1.6,1.463c.133.133.133.133.266,0,.133,0,.133-.133.133-.266V6.765C52.537,6.632,52.4,6.632,52.4,6.5ZM45.752,17.541A4.547,4.547,0,0,1,41.1,12.885a4.547,4.547,0,0,1,4.656-4.656,3.944,3.944,0,0,1,3.193,1.33,4.324,4.324,0,0,1,1.33,3.193,3.944,3.944,0,0,1-1.33,3.193A3.63,3.63,0,0,1,45.752,17.541Z" transform="translate(12.785 1.616)" fill="#283c50"/>
                <path id="Tracé_6895" data-name="Tracé 6895" d="M61.678,6.4h-1.6a.286.286,0,0,0-.266.266v7.716a3.262,3.262,0,0,1-.8,2.129,2.647,2.647,0,0,1-2.129.931,2.834,2.834,0,0,1-2.129-.931,2.647,2.647,0,0,1-.931-2.129V6.666a.286.286,0,0,0-.266-.266h-1.6a.286.286,0,0,0-.266.266v7.716a5.462,5.462,0,0,0,1.463,3.725,5.226,5.226,0,0,0,3.725,1.463,5.319,5.319,0,0,0,3.459-1.33l1.2,1.064c.133,0,.133.133.266,0,.133,0,.133-.133.133-.266V6.666A.286.286,0,0,0,61.678,6.4Z" transform="translate(16.947 1.715)" fill="#283c50"/>
                <path id="Tracé_6896" data-name="Tracé 6896" d="M71.743,18.873l-3.991-6.918L70.679,6.9V6.633c0-.133-.133-.133-.266-.133H68.55c-.133,0-.133,0-.266.133L66.422,9.826,64.559,6.633c0-.133-.133-.133-.266-.133H62.43c-.133,0-.133,0-.266.133V6.9l2.927,5.055L61.1,18.873v.266c0,.133.133.133.266.133h1.863c.133,0,.133,0,.266-.133l2.927-5.055,2.927,5.055c0,.133.133.133.266.133h2c.133,0,.133,0,.266-.133C71.876,19.006,71.876,18.873,71.743,18.873Z" transform="translate(20.053 1.748)" fill="#283c50"/>
                </g>
                <g id="Groupe_1575" data-name="Groupe 1575" transform="translate(96.851 16.231)">
                <circle id="Ellipse_2" data-name="Ellipse 2" cx="2.528" cy="2.528" r="2.528" fill="#00c378"/>
                </g>
            </g>
            </svg>
        : null
        }

        {  this.props.name === "IMA-PROTECT" ? 
        <svg id="IMA_PROTECT-PDT-1-RVB" width="136.589" height="60" viewBox="0 0 136.589 60">
            <g id="Groupe_1577" data-name="Groupe 1577" transform="translate(0 40.132)">
                <path id="Tracé_6897" data-name="Tracé 6897" d="M.3,44.674V40.8h.993v3.775a4.357,4.357,0,0,0,.3,1.887,1.811,1.811,0,0,0,1.689.894,1.65,1.65,0,0,0,1.689-.894,4.151,4.151,0,0,0,.3-1.788V40.9h.894v3.675a4.419,4.419,0,0,1-.5,2.483,2.826,2.826,0,0,1-2.483,1.093A2.639,2.639,0,0,1,.8,47.058,3.849,3.849,0,0,1,.3,44.674Z" transform="translate(-0.002 -40.403)" fill="#5b6e83"/>
                <path id="Tracé_6898" data-name="Tracé 6898" d="M7.7,43.1h.894v.6h0a2.4,2.4,0,0,1,1.689-.7,1.888,1.888,0,0,1,1.291.5c.3.3.4.7.4,1.49v3.079h-.894V45.185a2.137,2.137,0,0,0-.2-.993,1.128,1.128,0,0,0-.795-.3,2.453,2.453,0,0,0-1.589.795v3.377H7.7Z" transform="translate(-0.051 -40.417)" fill="#5b6e83"/>
                <path id="Tracé_6899" data-name="Tracé 6899" d="M14.193,45.881a1.658,1.658,0,0,0,1.788,1.49,2.868,2.868,0,0,0,1.689-.6v.993a3.177,3.177,0,0,1-1.788.5A2.53,2.53,0,0,1,13.2,45.683,2.426,2.426,0,0,1,15.683,43.1a2.215,2.215,0,0,1,2.285,2.384v.5H14.193Zm0-.7h2.881a1.314,1.314,0,0,0-1.391-1.291A1.455,1.455,0,0,0,14.193,45.186Z" transform="translate(-0.087 -40.418)" fill="#5b6e83"/>
                <path id="Tracé_6900" data-name="Tracé 6900" d="M23.983,43A2.583,2.583,0,1,1,21.4,45.583,2.565,2.565,0,0,1,23.983,43Zm0,4.371a1.791,1.791,0,1,0-1.689-1.788A1.7,1.7,0,0,0,23.983,47.371Z" transform="translate(-0.142 -40.417)" fill="#5b6e83"/>
                <path id="Tracé_6901" data-name="Tracé 6901" d="M28.195,43.976H27.4v-.795h.795v-.894a2.394,2.394,0,0,1,.4-1.49,1.583,1.583,0,0,1,1.192-.4,5.6,5.6,0,0,1,.7.1v.795a1.69,1.69,0,0,0-.7-.1c-.6,0-.7.4-.7.894v1.093H30.38v.795H29.089v4.073h-.894Z" transform="translate(-0.181 -40.4)" fill="#5b6e83"/>
                <path id="Tracé_6902" data-name="Tracé 6902" d="M31.595,43.976H30.8v-.795h.795v-.894a2.394,2.394,0,0,1,.4-1.49,1.583,1.583,0,0,1,1.192-.4,5.6,5.6,0,0,1,.7.1v.795a1.69,1.69,0,0,0-.7-.1c-.6,0-.7.4-.7.894v1.093H33.78v.795H32.489v4.073h-.894Z" transform="translate(-0.204 -40.4)" fill="#5b6e83"/>
                <path id="Tracé_6903" data-name="Tracé 6903" d="M34.8,43.1h.894v.7h0A1.751,1.751,0,0,1,37.183,43h.3v.993h-.4a1.639,1.639,0,0,0-1.49.894v3.179H34.7V43.1Z" transform="translate(-0.23 -40.417)" fill="#5b6e83"/>
                <path id="Tracé_6904" data-name="Tracé 6904" d="M38.993,45.881a1.658,1.658,0,0,0,1.788,1.49,2.868,2.868,0,0,0,1.689-.6v.993a3.177,3.177,0,0,1-1.788.5A2.53,2.53,0,0,1,38,45.683,2.426,2.426,0,0,1,40.483,43.1a2.215,2.215,0,0,1,2.285,2.384v.5H38.993Zm0-.7h2.881a1.314,1.314,0,0,0-1.391-1.291A1.455,1.455,0,0,0,38.993,45.186Z" transform="translate(-0.252 -40.418)" fill="#5b6e83"/>
                <path id="Tracé_6905" data-name="Tracé 6905" d="M46.5,40.8h.993v3.079h3.974V40.8h.993v7.252h-.993V44.674H47.493v3.377H46.5Z" transform="translate(-0.308 -40.403)" fill="#5b6e83"/>
                <path id="Tracé_6906" data-name="Tracé 6906" d="M54.2,47.868a1.282,1.282,0,0,1-.5-1.093,1.264,1.264,0,0,1,.7-1.192,3.173,3.173,0,0,1,1.391-.3c.4,0,.7.1,1.093.1v-.4a.978.978,0,0,0-.3-.795,1.214,1.214,0,0,0-.894-.3,2.44,2.44,0,0,0-1.49.5V43.5a3.506,3.506,0,0,1,1.689-.4,1.951,1.951,0,0,1,1.49.5,1.716,1.716,0,0,1,.5,1.291v2.086c0,.3.1.5.4.5a.365.365,0,0,0,.3-.1v.795a.744.744,0,0,1-.5.1,1.223,1.223,0,0,1-.993-.5h0a2.236,2.236,0,0,1-1.49.5A4.641,4.641,0,0,1,54.2,47.868Zm2.583-.894v-.993a3.38,3.38,0,0,0-.993-.1c-.6,0-1.192.2-1.192.795,0,.5.4.7.894.7A2.485,2.485,0,0,0,56.779,46.974Z" transform="translate(-0.356 -40.418)" fill="#5b6e83"/>
                <path id="Tracé_6907" data-name="Tracé 6907" d="M59.4,40.4h.894v2.98h0a2.571,2.571,0,0,1,1.391-.4,2.378,2.378,0,0,1,2.384,2.483,2.588,2.588,0,0,1-2.583,2.682,2.118,2.118,0,0,1-1.192-.3l-.894.2V40.4Zm2.086,6.954a1.7,1.7,0,0,0,1.689-1.788,1.623,1.623,0,0,0-1.589-1.788,1.888,1.888,0,0,0-1.291.5v2.781A2.36,2.36,0,0,0,61.486,47.354Z" transform="translate(-0.393 -40.4)" fill="#5b6e83"/>
                <path id="Tracé_6908" data-name="Tracé 6908" d="M65.8,40.8a.6.6,0,0,1,0,1.192.6.6,0,1,1,0-1.192Zm-.4,2.285h.894v4.967H65.4Z" transform="translate(-0.432 -40.403)" fill="#5b6e83"/>
                <path id="Tracé_6909" data-name="Tracé 6909" d="M68.294,46.368V43.984H67.4v-.795h.894V41.8l.894-.2v1.49h1.49v.894h-1.49V46.17c0,.6.1,1.192.7,1.192a1.158,1.158,0,0,0,.7-.2v.795a2.372,2.372,0,0,1-.894.2,1.265,1.265,0,0,1-1.093-.5A1.864,1.864,0,0,1,68.294,46.368Z" transform="translate(-0.446 -40.408)" fill="#5b6e83"/>
                <path id="Tracé_6910" data-name="Tracé 6910" d="M72,47.868a1.282,1.282,0,0,1-.5-1.093,1.264,1.264,0,0,1,.7-1.192,3.173,3.173,0,0,1,1.391-.3c.4,0,.7.1,1.093.1v-.4a.978.978,0,0,0-.3-.795,1.214,1.214,0,0,0-.894-.3,2.44,2.44,0,0,0-1.49.5V43.5a3.506,3.506,0,0,1,1.689-.4,1.951,1.951,0,0,1,1.49.5,1.716,1.716,0,0,1,.5,1.291v2.086c0,.3.1.5.4.5a.365.365,0,0,0,.3-.1v.795a.744.744,0,0,1-.5.1,1.223,1.223,0,0,1-.993-.5h0a2.236,2.236,0,0,1-1.49.5A3.9,3.9,0,0,1,72,47.868Zm2.583-.894v-.993a3.38,3.38,0,0,0-.993-.1c-.6,0-1.192.2-1.192.795,0,.5.4.7.894.7A2.485,2.485,0,0,0,74.579,46.974Z" transform="translate(-0.474 -40.418)" fill="#5b6e83"/>
                <path id="Tracé_6911" data-name="Tracé 6911" d="M77.594,46.368V43.984H76.7v-.795h.894V41.8l.894-.2v1.49h1.49v.894h-1.49V46.17c0,.6.1,1.192.7,1.192a1.158,1.158,0,0,0,.7-.2v.795a2.372,2.372,0,0,1-.894.2,1.265,1.265,0,0,1-1.093-.5A1.864,1.864,0,0,1,77.594,46.368Z" transform="translate(-0.508 -40.408)" fill="#5b6e83"/>
                <path id="Tracé_6912" data-name="Tracé 6912" d="M2.583,55.083a2.118,2.118,0,0,1,1.192.3h0V52.5h.894v7.649H3.775v-.3h0a2.571,2.571,0,0,1-1.391.4A2.378,2.378,0,0,1,0,57.765,2.588,2.588,0,0,1,2.583,55.083Zm-.1,4.371a2.817,2.817,0,0,0,1.291-.4V56.275a2.36,2.36,0,0,0-1.192-.3A1.7,1.7,0,0,0,.894,57.765,1.592,1.592,0,0,0,2.483,59.454Z" transform="translate(0 -40.48)" fill="#5b6e83"/>
                <path id="Tracé_6913" data-name="Tracé 6913" d="M6.3,58.28V55.3h.894v2.881a2.137,2.137,0,0,0,.2.993.824.824,0,0,0,.795.3,2.453,2.453,0,0,0,1.589-.795V55.3h.894v4.967H9.777v-.6h0a2.4,2.4,0,0,1-1.689.7,1.961,1.961,0,0,1-1.391-.6A2.22,2.22,0,0,1,6.3,58.28Z" transform="translate(-0.042 -40.499)" fill="#5b6e83"/>
                <path id="Tracé_6914" data-name="Tracé 6914" d="M18.174,52.8a4.393,4.393,0,0,1,2.185.6v1.093a3.542,3.542,0,0,0-2.285-.795,2.821,2.821,0,0,0-2.881,2.881,2.763,2.763,0,0,0,2.881,2.881,3.317,3.317,0,0,0,1.49-.3V57.469H17.975v-.795h2.583v2.98a4.863,4.863,0,0,1-2.483.7A3.63,3.63,0,0,1,14.3,56.674,3.758,3.758,0,0,1,18.174,52.8Z" transform="translate(-0.095 -40.482)" fill="#5b6e83"/>
                <path id="Tracé_6915" data-name="Tracé 6915" d="M22.2,52.9h1.987a3.421,3.421,0,0,1,1.987.4,1.812,1.812,0,0,1,.993,1.689,1.928,1.928,0,0,1-.894,1.689,1.321,1.321,0,0,1-.795.3l2.285,3.179H26.571l-2.185-3.079H23.193v3.079H22.2V52.9Zm2.185,3.377a2.631,2.631,0,0,0,1.391-.3.968.968,0,0,0,.4-.894,1,1,0,0,0-.6-.993,3.64,3.64,0,0,0-1.589-.2h-.894v2.483h1.291Z" transform="translate(-0.147 -40.483)" fill="#5b6e83"/>
                <path id="Tracé_6916" data-name="Tracé 6916" d="M31.975,52.8a3.63,3.63,0,0,1,3.675,3.775,3.69,3.69,0,0,1-3.675,3.775,3.776,3.776,0,0,1,0-7.55Zm0,6.556a2.883,2.883,0,1,0-2.781-2.881A2.8,2.8,0,0,0,31.975,59.356Z" transform="translate(-0.187 -40.482)" fill="#5b6e83"/>
                <path id="Tracé_6917" data-name="Tracé 6917" d="M36.8,56.774V52.9h.993v3.775a4.357,4.357,0,0,0,.3,1.887,1.811,1.811,0,0,0,1.689.894,1.65,1.65,0,0,0,1.689-.894,4.151,4.151,0,0,0,.3-1.788V53h.894v3.675a4.419,4.419,0,0,1-.5,2.483,2.826,2.826,0,0,1-2.483,1.093A2.95,2.95,0,0,1,37.2,59.158,6.011,6.011,0,0,1,36.8,56.774Z" transform="translate(-0.244 -40.483)" fill="#5b6e83"/>
                <path id="Tracé_6918" data-name="Tracé 6918" d="M44.3,52.9h1.887a4.1,4.1,0,0,1,2.185.4,2.048,2.048,0,0,1,1.093,1.788,1.968,1.968,0,0,1-.993,1.788,4.477,4.477,0,0,1-2.384.4h-.795v2.881H44.3Zm1.788,3.576c.894,0,1.291,0,1.689-.3a1.141,1.141,0,0,0,.6-1.093,1.222,1.222,0,0,0-.7-1.093,4.136,4.136,0,0,0-1.689-.2h-.894v2.682h.993Z" transform="translate(-0.293 -40.483)" fill="#5b6e83"/>
                <path id="Tracé_6919" data-name="Tracé 6919" d="M50.5,52.9h4.172v.894H51.393v2.185h2.881v.795H51.393v2.483h3.278v.894H50.4V52.9Z" transform="translate(-0.334 -40.483)" fill="#5b6e83"/>
                <path id="Tracé_6920" data-name="Tracé 6920" d="M58.5,60.151V52.8h1.987v7.351Z" transform="translate(-0.387 -40.482)" fill="#5b6e83"/>
                <path id="Tracé_6921" data-name="Tracé 6921" d="M70.246,52.8l-4.073,5.861-1.987-2.682v4.172H62.2V52.8h1.987l1.987,2.682L68.061,52.8ZM68.26,60.151V56.873l1.987-2.781v6.06Z" transform="translate(-0.412 -40.482)" fill="#5b6e83"/>
                <path id="Tracé_6922" data-name="Tracé 6922" d="M74.081,52.9h1.987l2.98,7.252H76.763l-.5-1.49H73.783l-.5,1.49H71.2Zm.993,2.185a7.786,7.786,0,0,1-.5,1.391l-.3.7H75.67l-.2-.7a7.317,7.317,0,0,0-.4-1.391Z" transform="translate(-0.472 -40.483)" fill="#5b6e83"/>
            </g>
            <g id="Groupe_1582" data-name="Groupe 1582" transform="translate(0.199)">
                <g id="Groupe_1578" data-name="Groupe 1578" transform="translate(53.444 0.397)">
                <path id="Tracé_6923" data-name="Tracé 6923" d="M54,14.407V.4h3.974V14.407Z" transform="translate(-54 -0.4)" fill="#5b6e83"/>
                <path id="Tracé_6924" data-name="Tracé 6924" d="M76.6.4,68.749,11.625,64.875,6.46v8.046H61.1V.4h3.874l3.775,5.166L72.425.4H76.6ZM72.822,14.407V8.049L76.6,2.685V14.407Z" transform="translate(-54.047 -0.4)" fill="#5b6e83"/>
                <path id="Tracé_6925" data-name="Tracé 6925" d="M90.322,14.407,89.13,11.526H83.766l-1.192,2.881H78.6L84.461.5V.4h3.874L94.2,14.407ZM85.156,8.148h2.583L86.448,4.97Z" transform="translate(-54.163 -0.4)" fill="#5b6e83"/>
                </g>
                <g id="Groupe_1580" data-name="Groupe 1580" transform="translate(53.444 19.57)">
                <g id="Groupe_1579" data-name="Groupe 1579">
                    <path id="Tracé_6926" data-name="Tracé 6926" d="M63.834,23.874c0,2.682-1.987,4.073-4.47,4.073H57.377v4.073H54V19.9h5.364C61.848,19.9,63.834,21.291,63.834,23.874Zm-4.868,1.192c.894,0,1.49-.4,1.49-1.192s-.6-1.192-1.49-1.192H57.377v2.285h1.589Z" transform="translate(-54 -19.701)" fill="#e2001a"/>
                    <path id="Tracé_6927" data-name="Tracé 6927" d="M72.554,32.019l-2.682-4.073h-.795v4.073H65.7V19.9h5.364c2.384,0,4.47,1.391,4.47,3.974a3.764,3.764,0,0,1-2.384,3.675l3.079,4.47Zm-3.377-9.238v2.285h1.589c.894,0,1.49-.4,1.49-1.192s-.6-1.192-1.49-1.192H69.177Z" transform="translate(-54.077 -19.701)" fill="#e2001a"/>
                    <path id="Tracé_6928" data-name="Tracé 6928" d="M77.4,25.958c0-4.073,3.079-6.258,6.854-6.258s6.656,2.185,6.656,6.258-2.881,6.258-6.656,6.258S77.4,29.932,77.4,25.958Zm10.033,0a3,3,0,0,0-3.278-3.179,3.131,3.131,0,0,0-3.377,3.179,3.209,3.209,0,0,0,3.377,3.278A3.129,3.129,0,0,0,87.433,25.958Z" transform="translate(-54.155 -19.7)" fill="#e2001a"/>
                    <path id="Tracé_6929" data-name="Tracé 6929" d="M102.926,22.781H99.052v9.238H95.674V22.781H91.8V19.9h11.126Z" transform="translate(-54.25 -19.701)" fill="#e2001a"/>
                    <path id="Tracé_6930" data-name="Tracé 6930" d="M113.442,22.781h-5.265V24.37h4.272v2.881h-4.272v1.788h5.265V31.92H104.8V19.9h8.642Z" transform="translate(-54.336 -19.701)" fill="#e2001a"/>
                    <path id="Tracé_6931" data-name="Tracé 6931" d="M115,25.958c0-3.675,2.583-6.258,6.556-6.258a6.275,6.275,0,0,1,3.179.7v3.179a4.623,4.623,0,0,0-2.781-.7c-1.987,0-3.576.993-3.576,3.079s1.589,3.079,3.576,3.079a5.457,5.457,0,0,0,2.781-.7v3.179a5.81,5.81,0,0,1-3.179.7C117.682,32.217,115,29.634,115,25.958Z" transform="translate(-54.404 -19.7)" fill="#e2001a"/>
                    <path id="Tracé_6932" data-name="Tracé 6932" d="M137.426,22.781h-3.874v9.238h-3.377V22.781H126.3V19.9h11.026v2.881Z" transform="translate(-54.479 -19.701)" fill="#e2001a"/>
                </g>
                </g>
                <g id="Groupe_1581" data-name="Groupe 1581">
                <path id="Tracé_6933" data-name="Tracé 6933" d="M29.638,0a9.238,9.238,0,1,0,9.238,9.238A9.221,9.221,0,0,0,29.638,0" transform="translate(-0.334)" fill="#5b6e83"/>
                <path id="Tracé_6934" data-name="Tracé 6934" d="M15.7,32.043H.2L18.279,8.5v.894a11.333,11.333,0,0,0,6.954,10.43Z" transform="translate(-0.2 -0.056)" fill="#5b6e83"/>
                <path id="Tracé_6935" data-name="Tracé 6935" d="M23.666,32.121H18.5L27.44,20.5h0a16.244,16.244,0,0,0,2.185.2,16.366,16.366,0,0,0,3.179-.4Z" transform="translate(-0.321 -0.134)" fill="#5b6e83"/>
                <path id="Tracé_6936" data-name="Tracé 6936" d="M44.182,25.47a5.9,5.9,0,0,0,1.291-3.675,1.937,1.937,0,0,0-.1-.795L40.407,5.9a10.168,10.168,0,0,1,.5,3.477c0,3.179-1.291,5.662-3.278,8.146L26.5,32.026H39.414Z" transform="translate(-0.374 -0.039)" fill="#5b6e83"/>
                </g>
            </g>
        </svg>
        : null
        }

        {  this.props.name === "IMA-SERELIA" ? 
        <svg width="90.43" height="60" viewBox="0 0 90.43 60">
            <g id="SERELIA-1-RVB" transform="translate(0 -0.05)">
                <g id="Groupe_1583" data-name="Groupe 1583" transform="translate(0 37.127)">
                <path id="Tracé_6937" data-name="Tracé 6937" d="M.3,37.27V32.8H1.446v4.355a5.026,5.026,0,0,0,.344,2.178,2.089,2.089,0,0,0,1.948,1.032,1.9,1.9,0,0,0,1.948-1.032,4.789,4.789,0,0,0,.344-2.063V32.915H7.062v4.241a5.1,5.1,0,0,1-.573,2.865,3.261,3.261,0,0,1-2.865,1.261A3.045,3.045,0,0,1,.873,40.021,4.441,4.441,0,0,1,.3,37.27Z" transform="translate(0.044 -32.342)" fill="#5b6e83"/>
                <path id="Tracé_6938" data-name="Tracé 6938" d="M7.7,35.215H8.732V35.9h0a2.765,2.765,0,0,1,1.948-.8,2.179,2.179,0,0,1,1.49.573c.344.344.458.8.458,1.719v3.553H11.6V37.621a2.466,2.466,0,0,0-.229-1.146,1.3,1.3,0,0,0-.917-.344,2.83,2.83,0,0,0-1.834.917v3.9H7.7Z" transform="translate(1.125 -32.005)" fill="#5b6e83"/>
                <path id="Tracé_6939" data-name="Tracé 6939" d="M14.346,38.309a1.913,1.913,0,0,0,2.063,1.719,3.308,3.308,0,0,0,1.948-.688v1.146a3.665,3.665,0,0,1-2.063.573A2.919,2.919,0,0,1,13.2,38.08a2.8,2.8,0,0,1,2.865-2.98A2.556,2.556,0,0,1,18.7,37.851v.573H14.346Zm0-.8H17.67a1.516,1.516,0,0,0-1.6-1.49A1.786,1.786,0,0,0,14.346,37.507Z" transform="translate(1.929 -32.005)" fill="#5b6e83"/>
                <path id="Tracé_6940" data-name="Tracé 6940" d="M24.38,35a2.98,2.98,0,1,1-2.98,2.98A2.959,2.959,0,0,1,24.38,35Zm0,5.043a2.066,2.066,0,1,0-1.948-2.063A1.96,1.96,0,0,0,24.38,40.043Z" transform="translate(3.127 -32.02)" fill="#5b6e83"/>
                <path id="Tracé_6941" data-name="Tracé 6941" d="M28.217,36.526H27.3v-.917h.917V34.578a2.762,2.762,0,0,1,.458-1.719,1.827,1.827,0,0,1,1.375-.458,6.463,6.463,0,0,1,.8.115v.917a1.95,1.95,0,0,0-.8-.115c-.688,0-.8.458-.8,1.032v1.261h1.49v.917h-1.49v4.7H28.217Z" transform="translate(3.989 -32.4)" fill="#5b6e83"/>
                <path id="Tracé_6942" data-name="Tracé 6942" d="M31.717,36.526H30.8v-.917h.917V34.578a2.762,2.762,0,0,1,.458-1.719,1.827,1.827,0,0,1,1.375-.458,6.463,6.463,0,0,1,.8.115v.917a1.95,1.95,0,0,0-.8-.115c-.688,0-.8.458-.8,1.032v1.261h1.49v.917h-1.49v4.7H31.717Z" transform="translate(4.501 -32.4)" fill="#5b6e83"/>
                <path id="Tracé_6943" data-name="Tracé 6943" d="M34.815,35.215h1.032v.8h0a2.021,2.021,0,0,1,1.719-.917h.344v1.032h-.458a1.891,1.891,0,0,0-1.719,1.032v3.668H34.7V35.215Z" transform="translate(5.071 -32.005)" fill="#5b6e83"/>
                <path id="Tracé_6944" data-name="Tracé 6944" d="M39.046,38.309a1.913,1.913,0,0,0,2.063,1.719,3.308,3.308,0,0,0,1.948-.688v1.146a3.665,3.665,0,0,1-2.063.573A2.919,2.919,0,0,1,37.9,38.08a2.8,2.8,0,0,1,2.865-2.98A2.556,2.556,0,0,1,43.4,37.851v.573H39.046Zm0-.8H42.37a1.516,1.516,0,0,0-1.6-1.49A1.786,1.786,0,0,0,39.046,37.507Z" transform="translate(5.538 -32.005)" fill="#5b6e83"/>
                <path id="Tracé_6945" data-name="Tracé 6945" d="M46.315,39.462a4.632,4.632,0,0,0,2.521.8c1.032,0,1.719-.458,1.719-1.375,0-1.032-1.032-1.261-2.063-1.6-1.146-.344-2.292-.8-2.292-2.292S47.232,32.7,48.951,32.7a5.719,5.719,0,0,1,2.407.573v1.146a4.294,4.294,0,0,0-2.407-.8c-.917,0-1.6.458-1.6,1.261,0,1.032.917,1.261,1.948,1.49,1.261.344,2.407.8,2.407,2.407a2.45,2.45,0,0,1-2.751,2.407,4.908,4.908,0,0,1-2.521-.688V39.462Z" transform="translate(6.751 -32.356)" fill="#5b6e83"/>
                <path id="Tracé_6946" data-name="Tracé 6946" d="M52.373,40.6a1.479,1.479,0,0,1-.573-1.261,1.459,1.459,0,0,1,.8-1.375,3.661,3.661,0,0,1,1.6-.344c.458,0,.8.115,1.261.115v-.458a1.128,1.128,0,0,0-.344-.917,1.4,1.4,0,0,0-1.032-.344,2.815,2.815,0,0,0-1.719.573V35.558a4.046,4.046,0,0,1,1.948-.458,2.251,2.251,0,0,1,1.719.573,1.98,1.98,0,0,1,.573,1.49V39.57c0,.344.115.573.458.573a.421.421,0,0,0,.344-.115v.917a.859.859,0,0,1-.573.115,1.411,1.411,0,0,1-1.146-.573h0a2.579,2.579,0,0,1-1.719.573A4.494,4.494,0,0,1,52.373,40.6Zm2.98-1.032V38.538a3.9,3.9,0,0,0-1.146-.115c-.688,0-1.375.229-1.375.917,0,.573.458.8,1.032.8A4.5,4.5,0,0,0,55.353,39.57Z" transform="translate(7.57 -32.005)" fill="#5b6e83"/>
                <path id="Tracé_6947" data-name="Tracé 6947" d="M57.515,35.215h1.032V35.9h0a2.765,2.765,0,0,1,1.948-.8,2.179,2.179,0,0,1,1.49.573c.344.344.458.8.458,1.719v3.553H61.411V37.621a2.466,2.466,0,0,0-.229-1.146,1.3,1.3,0,0,0-.917-.344,2.83,2.83,0,0,0-1.834.917v3.9H57.4V35.215Z" transform="translate(8.388 -32.005)" fill="#5b6e83"/>
                <path id="Tracé_6948" data-name="Tracé 6948" d="M63.932,39.087V36.336H62.9v-.917h1.032v-1.49l1.032-.229v1.719h1.719v.917H64.963v2.521c0,.688.115,1.375.8,1.375a1.336,1.336,0,0,0,.8-.229v.917a2.737,2.737,0,0,1-1.032.229,1.46,1.46,0,0,1-1.261-.573A2.151,2.151,0,0,1,63.932,39.087Z" transform="translate(9.192 -32.21)" fill="#5b6e83"/>
                <path id="Tracé_6949" data-name="Tracé 6949" d="M67.946,38.66a1.913,1.913,0,0,0,2.063,1.719,3.308,3.308,0,0,0,1.948-.688v1.146a3.665,3.665,0,0,1-2.063.573,2.919,2.919,0,0,1-3.095-2.98,2.8,2.8,0,0,1,2.865-2.98A2.556,2.556,0,0,1,72.3,38.2v.573H67.946Zm0-.8H71.27a1.516,1.516,0,0,0-1.6-1.49A1.7,1.7,0,0,0,67.946,37.858Zm1.834-3.209h-.8l.917-1.948h1.146Z" transform="translate(9.762 -32.356)" fill="#5b6e83"/>
                <path id="Tracé_6950" data-name="Tracé 6950" d="M2.98,47.48a2.444,2.444,0,0,1,1.375.344h0V44.5H5.387v8.825H4.355v-.344h0a2.966,2.966,0,0,1-1.6.458A2.744,2.744,0,0,1,0,50.575,2.986,2.986,0,0,1,2.98,47.48Zm-.115,5.043a3.251,3.251,0,0,0,1.49-.458V48.855a2.723,2.723,0,0,0-1.375-.344,1.96,1.96,0,0,0-1.948,2.063A1.837,1.837,0,0,0,2.865,52.523Z" transform="translate(0 -30.632)" fill="#5b6e83"/>
                <path id="Tracé_6951" data-name="Tracé 6951" d="M6.3,50.738V47.3H7.332v3.324a2.466,2.466,0,0,0,.229,1.146.95.95,0,0,0,.917.344,2.83,2.83,0,0,0,1.834-.917V47.3h1.032v5.731H10.311v-.688h0a2.765,2.765,0,0,1-1.948.8,1.848,1.848,0,0,1-1.49-.688A2.105,2.105,0,0,1,6.3,50.738Z" transform="translate(0.921 -30.223)" fill="#5b6e83"/>
                <path id="Tracé_6952" data-name="Tracé 6952" d="M18.77,44.8a5.069,5.069,0,0,1,2.521.688v1.261a4.087,4.087,0,0,0-2.636-.917,3.255,3.255,0,0,0-3.324,3.324,3.188,3.188,0,0,0,3.324,3.324,3.827,3.827,0,0,0,1.719-.344V50.187H18.541V49.27h2.98v3.438a7.03,7.03,0,0,1-2.865.688A4.188,4.188,0,0,1,14.3,49.155,4.244,4.244,0,0,1,18.77,44.8Z" transform="translate(2.09 -30.588)" fill="#5b6e83"/>
                <path id="Tracé_6953" data-name="Tracé 6953" d="M22.1,44.9h2.292a3.947,3.947,0,0,1,2.292.458,2.09,2.09,0,0,1,1.146,1.948A2.225,2.225,0,0,1,26.8,49.255a1.524,1.524,0,0,1-.917.344l2.636,3.668H27.143l-2.521-3.553H23.246v3.553H22.1V44.9Zm2.521,3.9a3.036,3.036,0,0,0,1.6-.344,1.117,1.117,0,0,0,.458-1.032A1.151,1.151,0,0,0,26,46.275a4.2,4.2,0,0,0-1.834-.229H23.132v2.865h1.49Z" transform="translate(3.23 -30.573)" fill="#5b6e83"/>
                <path id="Tracé_6954" data-name="Tracé 6954" d="M32.441,44.8a4.188,4.188,0,0,1,4.241,4.355,4.257,4.257,0,0,1-4.241,4.355,4.357,4.357,0,0,1,0-8.711Zm0,7.564a3.138,3.138,0,0,0,3.095-3.324,3.21,3.21,0,0,0-3.095-3.324,3.332,3.332,0,0,0,0,6.648Z" transform="translate(4.121 -30.588)" fill="#5b6e83"/>
                <path id="Tracé_6955" data-name="Tracé 6955" d="M36.8,49.37V44.9h1.146v4.355a5.027,5.027,0,0,0,.344,2.178,2.089,2.089,0,0,0,1.948,1.032,1.9,1.9,0,0,0,1.948-1.032,4.789,4.789,0,0,0,.344-2.063V45.015h1.032v4.241a5.1,5.1,0,0,1-.573,2.865,3.261,3.261,0,0,1-2.865,1.261,3.045,3.045,0,0,1-2.751-1.261A4.44,4.44,0,0,1,36.8,49.37Z" transform="translate(5.378 -30.573)" fill="#5b6e83"/>
                <path id="Tracé_6956" data-name="Tracé 6956" d="M44.2,44.9h2.178a4.731,4.731,0,0,1,2.521.458,2.363,2.363,0,0,1,1.261,2.063,2.271,2.271,0,0,1-1.146,2.063,5.166,5.166,0,0,1-2.751.458h-.917v3.324H44.2Zm2.063,4.126c1.032,0,1.49,0,1.948-.344a1.317,1.317,0,0,0,.688-1.261,1.409,1.409,0,0,0-.8-1.261,4.773,4.773,0,0,0-1.948-.229H45.117v3.095h1.146Z" transform="translate(6.459 -30.573)" fill="#5b6e83"/>
                <path id="Tracé_6957" data-name="Tracé 6957" d="M50.515,44.9h4.814v1.032H51.546v2.521h3.209v.917H51.546v2.865h3.782v1.032H50.4V44.9Z" transform="translate(7.365 -30.573)" fill="#5b6e83"/>
                <path id="Tracé_6958" data-name="Tracé 6958" d="M58.4,53.267V44.9h2.292v8.367Z" transform="translate(8.534 -30.573)" fill="#5b6e83"/>
                <path id="Tracé_6959" data-name="Tracé 6959" d="M71.384,44.8l-4.7,6.762-2.292-3.095v4.814H62.1V44.915h2.292l2.292,3.095,2.178-3.095h2.521Zm-2.292,8.481V49.5l2.292-3.209v6.991Z" transform="translate(9.075 -30.588)" fill="#5b6e83"/>
                <path id="Tracé_6960" data-name="Tracé 6960" d="M74.438,44.9h2.292l3.324,8.367H77.418l-.573-1.719H73.98l-.573,1.719H71Zm1.146,2.521a8.985,8.985,0,0,1-.573,1.6l-.344.8h1.6l-.229-.8a8.443,8.443,0,0,0-.458-1.6Z" transform="translate(10.375 -30.573)" fill="#5b6e83"/>
                </g>
                <g id="Groupe_1589" data-name="Groupe 1589" transform="translate(0 0.05)">
                <g id="Groupe_1585" data-name="Groupe 1585" transform="translate(19.681)">
                    <g id="Groupe_1584" data-name="Groupe 1584">
                    <path id="Tracé_6961" data-name="Tracé 6961" d="M17.2,9.9A41.952,41.952,0,0,1,28.776,6.348,151.173,151.173,0,0,1,50.438,3.253c7.794-.573,15.473-.344,19.37,1.261,1.146.458,1.948.917,2.178,1.719a2.125,2.125,0,0,1-.8,2.292,18.66,18.66,0,0,1-5.616,3.324,1.155,1.155,0,0,1-.344.115,21.191,21.191,0,0,0,6.418-3.438,3.5,3.5,0,0,0,1.49-2.292c0-.917-.688-1.6-1.719-2.178C66.025,1.19,50.9,1.763,39.32,3.483,28.088,5.316,23.5,6.463,17.2,9.9" transform="translate(-17.168 0.242)" fill="#ffe6f0"/>
                    <path id="Tracé_6962" data-name="Tracé 6962" d="M19.382,8.752c-.573.344-1.49.688-2.063,1.032,0,0-.229.115-.115.115,4.241-1.6,5.616-1.6,11.461-2.751C34.4,6,37.834,5.772,45.17,5.2s14.1-.458,18.453.573c2.292.573,4.241,1.375,4.126,2.98-.115,1.375-2.063,2.521-3.668,3.553.344-.115,1.261-.458,1.49-.573a19.628,19.628,0,0,0,5.616-3.324c.688-.688,1.146-1.6.8-2.292A3.79,3.79,0,0,0,69.812,4.4c-3.9-1.6-11.576-1.834-19.37-1.261A151.173,151.173,0,0,0,28.78,6.231c-6.3,1.261-4.928.688-9.4,2.521" transform="translate(-17.172 0.359)" fill="#ffe6f0"/>
                    <path id="Tracé_6963" data-name="Tracé 6963" d="M19.1,9.162A93.338,93.338,0,0,1,35.49,4.233a170.729,170.729,0,0,1,21.662-2.98C64.945.68,72.739.91,76.521,2.514A3.79,3.79,0,0,1,78.7,4.233a2.125,2.125,0,0,1-.8,2.292C76.636,7.786,74.458,8.7,72.281,9.849c-.115.115-.115,0-.229.115C74.458,8.7,76.865,7.9,78.47,6.526a3.5,3.5,0,0,0,1.49-2.292c0-.917-.688-1.6-1.719-2.178C72.854-.81,57.725-.237,46.149,1.483,34.8,3.2,25.289,5.723,19.1,9.162" transform="translate(-16.89 -0.05)" fill="#ffe6f0"/>
                    <path id="Tracé_6964" data-name="Tracé 6964" d="M19.382,9.038c-.573.344-1.49.688-2.063,1.032,0,0-.229.115-.115.115A95.458,95.458,0,0,1,32.448,5.944a164.766,164.766,0,0,1,19.6-2.751c7.45-.573,14.212-.458,18.567.573,2.292.573,4.241,1.375,4.126,2.98-.115,1.375-2.063,2.521-3.668,3.553a7.631,7.631,0,0,0,.8-.344,2.942,2.942,0,0,1,.573-.229A19.628,19.628,0,0,0,78.064,6.4c.688-.688,1.146-1.6.8-2.292a3.79,3.79,0,0,0-2.178-1.719C72.791.786,65.112.671,57.319,1.13A151.832,151.832,0,0,0,35.771,4.224a90.943,90.943,0,0,0-16.39,4.814" transform="translate(-17.172 0.074)" fill="#f0467d"/>
                    </g>
                </g>
                <g id="Groupe_1588" data-name="Groupe 1588" transform="translate(0 6.934)">
                    <g id="Groupe_1587" data-name="Groupe 1587">
                    <path id="Tracé_6965" data-name="Tracé 6965" d="M.115,11.143a4.6,4.6,0,0,1,1.6-3.668A7.44,7.44,0,0,1,6.648,6.1a17.493,17.493,0,0,1,3.438.344c1.146.229,2.292.573,3.438.917-.115.344-.115.688-.229,1.146s-.115.8-.229,1.261l-3.209-1.6a11.894,11.894,0,0,0-1.375-.344A7.05,7.05,0,0,0,6.991,7.7a3.915,3.915,0,0,0-1.719.229,3.414,3.414,0,0,0-1.261.8,3.09,3.09,0,0,0-.8.917,3.347,3.347,0,0,0-.229,1.032,2.737,2.737,0,0,0,.229,1.032,5.74,5.74,0,0,0,.8.917c.344.229.688.458,1.146.8l1.375.688,3.9,1.6a3.886,3.886,0,0,1,1.6,1.032,3.583,3.583,0,0,1,1.032,1.261,7.011,7.011,0,0,1,.573,1.375,5.753,5.753,0,0,1,.229,1.375,4.375,4.375,0,0,1-1.834,3.782,5.5,5.5,0,0,1-2.178,1.032,10.03,10.03,0,0,1-2.751.344,9.265,9.265,0,0,1-1.719-.115c-.573-.115-1.261-.115-1.834-.229A11.99,11.99,0,0,1,1.6,25.126,11.759,11.759,0,0,1,0,24.667c.115-.344.115-.688.229-1.146s.115-.8.229-1.261c0,0,2.636,1.375,3.095,1.6a3.173,3.173,0,0,0,1.49.344,8.119,8.119,0,0,0,1.6.115,6.186,6.186,0,0,0,3.095-.688,2.427,2.427,0,0,0,1.146-2.292,3.173,3.173,0,0,0-.115-1.032,4.071,4.071,0,0,0-.458-1.032,3.09,3.09,0,0,0-.8-.917,2.378,2.378,0,0,0-1.146-.688l-4.126-1.49a6.034,6.034,0,0,1-3.209-2.063A5.108,5.108,0,0,1,.115,11.143Z" transform="translate(0 -6.1)" fill="#283c50"/>
                    <rect id="Rectangle_1384" data-name="Rectangle 1384" width="2.98" height="13.295" transform="translate(65.1 6.533)" fill="#283c50"/>
                    <g id="Groupe_1586" data-name="Groupe 1586" transform="translate(55.931)">
                        <path id="Tracé_6966" data-name="Tracé 6966" d="M56.021,25.928a8.069,8.069,0,0,1-5.158-1.948A7.167,7.167,0,0,1,48.8,18.593V6.1h3.209V19.624a5.316,5.316,0,0,0,.458,2.407,5.141,5.141,0,0,0,1.146,1.49,4.234,4.234,0,0,0,1.6.8c.229.115.573.115.8.229Z" transform="translate(-48.8 -6.1)" fill="#283c50"/>
                    </g>
                    <path id="Tracé_6967" data-name="Tracé 6967" d="M26.8,25.066V18.991a6.65,6.65,0,0,1,2.063-5.158A8.324,8.324,0,0,1,34.708,12h1.146v1.375c-.229,0-.458-.115-.688-.115h-.688a4.455,4.455,0,0,0-1.719.229,3.774,3.774,0,0,0-1.49.8,2.911,2.911,0,0,0-1.032,1.49,6.109,6.109,0,0,0-.458,2.178v7.221H26.8Z" transform="translate(3.916 -5.238)" fill="#283c50"/>
                    <path id="Tracé_6968" data-name="Tracé 6968" d="M42.633,25.081A6.724,6.724,0,0,1,40,24.622a4.8,4.8,0,0,1-2.063-1.261A6.5,6.5,0,0,1,36.558,21.3a7.526,7.526,0,0,1-.458-2.865,6.963,6.963,0,0,1,1.6-4.814A5.964,5.964,0,0,1,42.289,11.9a7.078,7.078,0,0,1,2.865.573A4.979,4.979,0,0,1,47.1,13.848a4.584,4.584,0,0,1,1.146,2.063,8.725,8.725,0,0,1,.344,2.407v.8H38.851a7.887,7.887,0,0,0,.229,1.49,3.774,3.774,0,0,0,.8,1.49,4.586,4.586,0,0,0,1.375,1.146,3.925,3.925,0,0,0,2.063.458,9.794,9.794,0,0,0,2.865-.458l.229.8c-.344.115-.688.344-1.032.458s-.573.229-.917.344a2.524,2.524,0,0,1-.917.115A2.524,2.524,0,0,1,42.633,25.081Zm3.438-7.45a4.743,4.743,0,0,0-.917-3.095,3.189,3.189,0,0,0-2.407-.917,3.661,3.661,0,0,0-1.6.344A4.487,4.487,0,0,0,40,14.88a12.5,12.5,0,0,0-.688,1.261,6.708,6.708,0,0,0-.229,1.49Z" transform="translate(5.275 -5.252)" fill="#283c50"/>
                    <path id="Tracé_6969" data-name="Tracé 6969" d="M20.633,25.081A6.724,6.724,0,0,1,18,24.622a4.8,4.8,0,0,1-2.063-1.261A6.5,6.5,0,0,1,14.558,21.3a7.526,7.526,0,0,1-.458-2.865,6.963,6.963,0,0,1,1.6-4.814A5.964,5.964,0,0,1,20.289,11.9a7.077,7.077,0,0,1,2.865.573A4.979,4.979,0,0,1,25.1,13.848a4.584,4.584,0,0,1,1.146,2.063,8.725,8.725,0,0,1,.344,2.407v.8H16.851a7.888,7.888,0,0,0,.229,1.49,3.774,3.774,0,0,0,.8,1.49,4.586,4.586,0,0,0,1.375,1.146,3.925,3.925,0,0,0,2.063.458,9.794,9.794,0,0,0,2.865-.458l.229.8c-.344.115-.688.344-1.032.458s-.573.229-.917.344a2.524,2.524,0,0,1-.917.115A2.524,2.524,0,0,1,20.633,25.081Zm3.324-7.45a4.743,4.743,0,0,0-.917-3.095,3.189,3.189,0,0,0-2.407-.917,3.661,3.661,0,0,0-1.6.344,4.487,4.487,0,0,0-1.146.917,12.5,12.5,0,0,0-.688,1.261,6.708,6.708,0,0,0-.229,1.49Z" transform="translate(2.06 -5.252)" fill="#283c50"/>
                    <path id="Tracé_6970" data-name="Tracé 6970" d="M75.468,25.181H72.374V22.544a7.144,7.144,0,0,1-1.032,1.375,6.3,6.3,0,0,1-1.032.8,1.936,1.936,0,0,1-1.146.344,4.3,4.3,0,0,1-1.261.115,7.163,7.163,0,0,1-2.865-.458,4.979,4.979,0,0,1-1.948-1.375,6.01,6.01,0,0,1-1.146-2.063,10.029,10.029,0,0,1-.344-2.751,10.029,10.029,0,0,1,.344-2.751,5.152,5.152,0,0,1,3.324-3.324A8.387,8.387,0,0,1,68.362,12a8.784,8.784,0,0,1,3.095.458,5.747,5.747,0,0,1,2.178,1.261A4.8,4.8,0,0,1,74.9,15.782a9.764,9.764,0,0,1,.458,2.636v6.762ZM72.374,17.96a10.55,10.55,0,0,0-.229-2.178,3.963,3.963,0,0,0-.8-1.375,2.378,2.378,0,0,0-1.146-.688,6.764,6.764,0,0,0-1.6-.229,5.807,5.807,0,0,0-1.49.229,2.378,2.378,0,0,0-1.146.688,3.963,3.963,0,0,0-.8,1.375,6.034,6.034,0,0,0-.229,2.178v1.146a11.546,11.546,0,0,0,.229,2.178,2.614,2.614,0,0,0,.688,1.375,3.569,3.569,0,0,0,1.032.8,5.753,5.753,0,0,0,1.375.229,4.6,4.6,0,0,0,1.719-.344,5.089,5.089,0,0,0,1.261-.917,3.988,3.988,0,0,0,.8-1.719,10.029,10.029,0,0,0,.344-2.751Z" transform="translate(9.002 -5.238)" fill="#283c50"/>
                    </g>
                </g>
                </g>
            </g>
        </svg>
        : null
        }
    {  this.props.name === "IMA-FOOTER" ? 
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="387.435" height="32" viewBox="0 0 387.435 32">
            <rect id="Rectangle_1376" data-name="Rectangle 1376" width="387.435" height="32" fill="none"/>
            <path id="Tracé_6752" data-name="Tracé 6752" d="M3.814,17.514C3.149,16.565,2.5,15.6,1.856,14.626l-.04.021c.021.867.04,1.976.04,3.169v8.237H0V11.437H1.857l5.188,7.429,5.189-7.429h1.837V26.053H12.152V17.816c0-1.19.021-2.3.04-3.169l-.04-.021c-.646.969-1.292,1.937-1.958,2.887L7,22.074Z" transform="translate(0 -2.747)" fill="#283c50"/>
            <path id="Tracé_6753" data-name="Tracé 6753" d="M22.821,11.437h8.434v1.736H24.739V17.6h5.714v1.716H24.739v5.006h6.7v1.736h-8.62Z" transform="translate(-5.48 -2.747)" fill="#283c50"/>
            <path id="Tracé_6754" data-name="Tracé 6754" d="M36.832,22.954a7.643,7.643,0,0,0,4.421,1.494c1.736,0,2.967-.786,2.967-2.381,0-1.877-1.717-2.2-3.633-2.745-2.059-.586-4.058-1.433-4.058-4.1,0-2.584,1.878-4.077,4.723-4.077a8.257,8.257,0,0,1,4.2,1.09v2.018a7.807,7.807,0,0,0-4.24-1.353c-1.634,0-2.786.707-2.786,2.2,0,1.817,1.7,2.119,3.473,2.624,2.119.608,4.218,1.454,4.218,4.239,0,2.665-1.958,4.24-4.885,4.24a9.015,9.015,0,0,1-4.4-1.157Z" transform="translate(-8.772 -2.677)" fill="#283c50"/>
            <path id="Tracé_6755" data-name="Tracé 6755" d="M58.165,22.954a7.643,7.643,0,0,0,4.421,1.494c1.736,0,2.967-.786,2.967-2.381,0-1.877-1.717-2.2-3.633-2.745-2.059-.586-4.058-1.433-4.058-4.1,0-2.584,1.878-4.077,4.723-4.077a8.257,8.257,0,0,1,4.2,1.09v2.018A7.807,7.807,0,0,0,62.547,12.9c-1.634,0-2.786.707-2.786,2.2,0,1.816,1.694,2.12,3.47,2.621,2.119.608,4.218,1.454,4.218,4.239,0,2.665-1.958,4.24-4.885,4.24a9.008,9.008,0,0,1-4.4-1.153Z" transform="translate(-13.895 -2.677)" fill="#283c50"/>
            <path id="Tracé_6756" data-name="Tracé 6756" d="M73.7,11.437H82.13v1.736H75.61V17.6h5.713v1.716H75.61v5.006h6.7v1.736H73.7Z" transform="translate(-17.697 -2.747)" fill="#283c50"/>
            <path id="Tracé_6757" data-name="Tracé 6757" d="M88.335,11.429h3.956a8.24,8.24,0,0,1,4.078.727,3.723,3.723,0,0,1,2.019,3.432,3.9,3.9,0,0,1-1.877,3.431,4.5,4.5,0,0,1-1.675.608l4.683,6.419H97.2l-4.461-6.238H90.252v6.238H88.335Zm4.4,6.763a4.748,4.748,0,0,0,2.866-.666,2.149,2.149,0,0,0,.869-1.817,2.252,2.252,0,0,0-1.191-2.059,6.477,6.477,0,0,0-3.128-.485h-1.9v5.027Z" transform="translate(-21.213 -2.739)" fill="#283c50"/>
            <path id="Tracé_6758" data-name="Tracé 6758" d="M103.133,11.437h2.058l3.109,8.2c.486,1.272.948,2.523,1.433,3.875h.04c.485-1.332.97-2.624,1.474-3.936l3.128-8.135h1.959l-5.612,14.615h-1.959Z" transform="translate(-24.766 -2.747)" fill="#283c50"/>
            <rect id="Rectangle_1373" data-name="Rectangle 1373" width="1.918" height="14.615" transform="translate(93.305 8.691)" fill="#283c50"/>
            <path id="Tracé_6759" data-name="Tracé 6759" d="M136.166,11.145a8.463,8.463,0,0,1,4.118,1.03v2.1a7.093,7.093,0,0,0-4.138-1.353,5.547,5.547,0,0,0-5.675,5.416c0,.112,0,.226,0,.338a5.588,5.588,0,0,0,5.413,5.756c.14,0,.28,0,.42,0a6.6,6.6,0,0,0,4.2-1.474v2.12a8.233,8.233,0,0,1-4.3,1.131,7.354,7.354,0,0,1-7.646-7.048c-.006-.147-.008-.294,0-.441a7.371,7.371,0,0,1,7.165-7.571c.149,0,.3,0,.446,0" transform="translate(-30.87 -2.676)" fill="#283c50"/>
            <path id="Tracé_6760" data-name="Tracé 6760" d="M147.5,11.437h8.434v1.736h-6.52V17.6h5.713v1.716h-5.713v5.006h6.7v1.736H147.5Z" transform="translate(-35.42 -2.747)" fill="#283c50"/>
            <path id="Tracé_6761" data-name="Tracé 6761" d="M161.511,22.954a7.643,7.643,0,0,0,4.421,1.494c1.736,0,2.963-.786,2.963-2.381,0-1.877-1.716-2.2-3.633-2.745-2.058-.586-4.057-1.433-4.057-4.1,0-2.584,1.878-4.077,4.723-4.077a8.257,8.257,0,0,1,4.2,1.09v2.018a7.814,7.814,0,0,0-4.24-1.353c-1.634,0-2.785.707-2.785,2.2,0,1.817,1.7,2.119,3.472,2.624,2.119.608,4.218,1.454,4.218,4.239,0,2.665-1.957,4.24-4.884,4.24a9.006,9.006,0,0,1-4.4-1.151Z" transform="translate(-38.712 -2.677)" fill="#283c50"/>
            <path id="Tracé_6762" data-name="Tracé 6762" d="M187.522,16.668h3.633l-3.633,3.754v5.672h-4.1V11.358h4.1Zm5.609-5.31h4.1V26.094h-4.1V20.422h-3.653l3.653-3.754Z" transform="translate(-44.046 -2.728)" fill="#00aa78"/>
            <path id="Tracé_6763" data-name="Tracé 6763" d="M216.531,26.094l-1.212-3.008h-5.592l-1.21,3.008h-4.158l6.136-14.675-.021-.061h4.118l6.155,14.736Zm-5.39-6.52h2.745l-1.372-3.391Z" transform="translate(-49.075 -2.728)" fill="#00aa78"/>
            <path id="Tracé_6764" data-name="Tracé 6764" d="M241.144,21.773c0,2.948-2.08,4.321-5.248,4.321h-7.207V11.358h7.1c2.887,0,4.805,1.09,4.805,3.674a3.3,3.3,0,0,1-2.483,3.21,3.577,3.577,0,0,1,3.029,3.532m-8.435-7.085v2.422h2.462c.827,0,1.494-.38,1.494-1.251,0-.827-.666-1.17-1.494-1.17Zm2.785,8.055c.97,0,1.717-.363,1.717-1.191s-.767-1.21-1.717-1.21h-2.785v2.4Z" transform="translate(-54.917 -2.728)" fill="#00aa78"/>
            <rect id="Rectangle_1374" data-name="Rectangle 1374" width="4.098" height="14.736" transform="translate(188.815 8.63)" fill="#00aa78"/>
            <path id="Tracé_6765" data-name="Tracé 6765" d="M270.319,14.911h-4.663V26.094h-4.1V14.911h-4.7V11.358h13.464Z" transform="translate(-61.681 -2.728)" fill="#00aa78"/>
            <path id="Tracé_6766" data-name="Tracé 6766" d="M285.179,26.094l-1.212-3.008h-5.588l-1.216,3.008h-4.156l6.132-14.675-.021-.061h4.118l6.155,14.736Zm-5.39-6.52h2.745l-1.372-3.391Z" transform="translate(-65.56 -2.728)" fill="#00aa78"/>
            <path id="Tracé_6767" data-name="Tracé 6767" d="M306.5,14.911h-4.659V26.094h-4.1V14.911h-4.7V11.358H306.5Z" transform="translate(-70.369 -2.728)" fill="#00aa78"/>
            <path id="Tracé_6768" data-name="Tracé 6768" d="M315.2,25.065a2.176,2.176,0,1,1-.009,0h.009" transform="translate(-75.168 -6.019)" fill="#00c378"/>
            <path id="Tracé_6769" data-name="Tracé 6769" d="M342.251,7.256V1.087h1.53V7.1a6.574,6.574,0,0,0,.452,3.013,2.878,2.878,0,0,0,2.77,1.4,2.822,2.822,0,0,0,2.736-1.4,6.421,6.421,0,0,0,.441-2.951V1.087h1.482V7.081a7.179,7.179,0,0,1-.774,4.027,4.533,4.533,0,0,1-3.947,1.819,4.47,4.47,0,0,1-3.916-1.819,7.058,7.058,0,0,1-.774-3.851" transform="translate(-82.182 -0.261)" fill="#5b6e83"/>
            <path id="Tracé_6770" data-name="Tracé 6770" d="M357.857,5.947h1.451v1l.031.016a3.933,3.933,0,0,1,2.772-1.192,2.745,2.745,0,0,1,2.111.87,3.211,3.211,0,0,1,.66,2.337v4.9h-1.451V9.25a2.467,2.467,0,0,0-.374-1.615,1.641,1.641,0,0,0-1.272-.515A3.341,3.341,0,0,0,359.3,8.441v5.433h-1.448Z" transform="translate(-85.936 -1.386)" fill="#5b6e83"/>
            <path id="Tracé_6771" data-name="Tracé 6771" d="M371.134,10.33a2.714,2.714,0,0,0,2.931,2.416,4.521,4.521,0,0,0,2.74-.983v1.546a5.615,5.615,0,0,1-2.836.742,4.04,4.04,0,0,1-4.279-3.785c-.008-.119-.009-.237-.006-.356a3.951,3.951,0,0,1,3.758-4.135c.062,0,.125,0,.188,0a3.546,3.546,0,0,1,3.62,3.47q0,.182-.011.365a5.142,5.142,0,0,1-.046.726Zm.033-1.144h4.672a2.145,2.145,0,0,0-2.177-2.112l-.063,0a2.419,2.419,0,0,0-2.432,2.109" transform="translate(-88.775 -1.386)" fill="#5b6e83"/>
            <path id="Tracé_6772" data-name="Tracé 6772" d="M390.933,5.771a4.14,4.14,0,1,1-4.188,4.14,4.08,4.08,0,0,1,4.019-4.14c.057,0,.113,0,.169,0m0,6.959a2.82,2.82,0,1,0-2.734-2.9c0,.027,0,.055,0,.084a2.717,2.717,0,0,0,2.61,2.818c.042,0,.084,0,.125,0" transform="translate(-92.873 -1.386)" fill="#5b6e83"/>
            <path id="Tracé_6773" data-name="Tracé 6773" d="M400.528,5.868h-1.257V4.562h1.257V3.049a3.155,3.155,0,0,1,.707-2.385A2.611,2.611,0,0,1,403.09,0a4.638,4.638,0,0,1,1.191.163v1.3a5.382,5.382,0,0,0-1.129-.145c-1,0-1.175.679-1.175,1.515V4.562h2.03V5.868h-2.031v6.621h-1.448Z" transform="translate(-95.881 0)" fill="#5b6e83"/>
            <path id="Tracé_6774" data-name="Tracé 6774" d="M407.819,5.868h-1.257V4.562h1.257V3.049a3.155,3.155,0,0,1,.707-2.385A2.611,2.611,0,0,1,410.381,0a4.639,4.639,0,0,1,1.191.163v1.3a5.383,5.383,0,0,0-1.129-.145c-1,0-1.176.679-1.176,1.515V4.562h2.03V5.868h-2.031v6.621h-1.448Z" transform="translate(-97.632 0)" fill="#5b6e83"/>
            <path id="Tracé_6775" data-name="Tracé 6775" d="M414.975,5.984h1.451v1.16l.031.016a2.893,2.893,0,0,1,2.356-1.239,2,2,0,0,1,.516.063V7.419a2.3,2.3,0,0,0-.563-.065,2.591,2.591,0,0,0-2.337,1.4v5.155h-1.451Z" transform="translate(-99.652 -1.422)" fill="#5b6e83"/>
            <path id="Tracé_6776" data-name="Tracé 6776" d="M423.226,10.33a2.714,2.714,0,0,0,2.931,2.416,4.521,4.521,0,0,0,2.74-.983v1.546a5.615,5.615,0,0,1-2.836.742,4.04,4.04,0,0,1-4.279-3.785c-.008-.119-.009-.237-.006-.356a3.951,3.951,0,0,1,3.758-4.135c.062,0,.125,0,.188,0a3.546,3.546,0,0,1,3.621,3.469c0,.122,0,.244-.011.365a5.152,5.152,0,0,1-.047.726Zm.031-1.145h4.672a2.144,2.144,0,0,0-2.177-2.111l-.062,0a2.419,2.419,0,0,0-2.432,2.109" transform="translate(-101.284 -1.386)" fill="#5b6e83"/>
            <path id="Tracé_6777" data-name="Tracé 6777" d="M439.6,1.087h1.531V6h6.347V1.087h1.529V12.75h-1.529V7.384h-6.347v5.365H439.6Z" transform="translate(-105.565 -0.261)" fill="#5b6e83"/>
            <path id="Tracé_6778" data-name="Tracé 6778" d="M455.505,13.484a2.112,2.112,0,0,1-.836-1.756A2.2,2.2,0,0,1,455.717,9.8a4.126,4.126,0,0,1,2.223-.532,7.015,7.015,0,0,1,1.691.21v-.66a1.713,1.713,0,0,0-.451-1.3,2.146,2.146,0,0,0-1.466-.435,4.135,4.135,0,0,0-2.432.788V6.445a4.989,4.989,0,0,1,2.643-.676,3.443,3.443,0,0,1,2.368.726,2.666,2.666,0,0,1,.79,2.077v3.368c0,.483.178.836.608.836a1.139,1.139,0,0,0,.469-.112v1.208a2.075,2.075,0,0,1-.774.145,1.69,1.69,0,0,1-1.531-.836h-.031a3.767,3.767,0,0,1-2.432.869,3.171,3.171,0,0,1-1.885-.562m4.124-1.417V10.584a6.042,6.042,0,0,0-1.546-.208c-.982,0-1.948.288-1.948,1.223,0,.774.66,1.16,1.515,1.16a3.3,3.3,0,0,0,1.98-.691" transform="translate(-109.184 -1.383)" fill="#5b6e83"/>
            <path id="Tracé_6779" data-name="Tracé 6779" d="M466.644.238h1.451V5.07l.031.017a4.188,4.188,0,0,1,2.24-.644,3.837,3.837,0,0,1,3.87,3.8c0,.074,0,.148,0,.223a4.1,4.1,0,0,1-3.947,4.254c-.086,0-.172,0-.258,0a5.184,5.184,0,0,1-1.982-.418l-1.4.241Zm3.432,11.213a2.69,2.69,0,0,0,2.7-2.683c0-.073,0-.147-.008-.22a2.584,2.584,0,0,0-2.356-2.793c-.074-.006-.148-.009-.223-.009a3.264,3.264,0,0,0-2.093.741v4.446a4.27,4.27,0,0,0,1.982.515" transform="translate(-112.059 -0.057)" fill="#5b6e83"/>
            <path id="Tracé_6780" data-name="Tracé 6780" d="M479.886,1.087a1.011,1.011,0,1,1-.009,0h.009m-.726,3.736h1.451V12.75h-1.45Z" transform="translate(-114.996 -0.261)" fill="#5b6e83"/>
            <path id="Tracé_6781" data-name="Tracé 6781" d="M484.853,10.418V6.55h-1.467V5.245h1.467V3.087l1.449-.241v2.4h2.385V6.55H486.3v3.593c0,.982.145,1.884,1.191,1.884a2.42,2.42,0,0,0,1.194-.337v1.368a3.5,3.5,0,0,1-1.435.29,2.3,2.3,0,0,1-1.8-.742,3.116,3.116,0,0,1-.6-2.189" transform="translate(-116.08 -0.684)" fill="#5b6e83"/>
            <path id="Tracé_6782" data-name="Tracé 6782" d="M492.887,13.484a2.112,2.112,0,0,1-.836-1.756A2.2,2.2,0,0,1,493.1,9.8a4.126,4.126,0,0,1,2.223-.532,7.015,7.015,0,0,1,1.691.21v-.66a1.71,1.71,0,0,0-.451-1.3,2.146,2.146,0,0,0-1.467-.435,4.135,4.135,0,0,0-2.432.788V6.445a4.991,4.991,0,0,1,2.643-.676,3.438,3.438,0,0,1,2.368.725,2.666,2.666,0,0,1,.79,2.077v3.368c0,.483.178.836.608.836a1.139,1.139,0,0,0,.469-.112v1.209a2.075,2.075,0,0,1-.774.145,1.69,1.69,0,0,1-1.531-.836H497.2a3.767,3.767,0,0,1-2.432.869,3.169,3.169,0,0,1-1.886-.565m4.124-1.417V10.584a6.042,6.042,0,0,0-1.546-.208c-.982,0-1.948.288-1.948,1.223,0,.774.66,1.16,1.515,1.16a3.3,3.3,0,0,0,1.98-.691" transform="translate(-118.161 -1.383)" fill="#5b6e83"/>
            <path id="Tracé_6783" data-name="Tracé 6783" d="M504.368,10.418V6.55H502.9V5.245h1.467V3.087l1.449-.241v2.4H508.2V6.55h-2.385v3.593c0,.982.145,1.884,1.191,1.884a2.42,2.42,0,0,0,1.194-.337v1.368a3.5,3.5,0,0,1-1.435.29,2.3,2.3,0,0,1-1.8-.742,3.116,3.116,0,0,1-.6-2.189" transform="translate(-120.766 -0.684)" fill="#5b6e83"/>
            <path id="Tracé_6784" data-name="Tracé 6784" d="M345.912,29.876a4.557,4.557,0,0,1,1.9.419l.032-.017V25.671h1.451v12.31h-1.448V37.5l-.032-.016a3.916,3.916,0,0,1-6.111-3.051q0-.144,0-.287a4.087,4.087,0,0,1,3.9-4.267c.1,0,.2-.005.306,0m-.129,6.991a3.664,3.664,0,0,0,2.063-.67v-4.53a4.435,4.435,0,0,0-1.949-.5,2.7,2.7,0,0,0-2.732,2.661c0,.081,0,.161.008.242a2.583,2.583,0,0,0,2.354,2.794,2.4,2.4,0,0,0,.256.009" transform="translate(-82.056 -6.165)" fill="#5b6e83"/>
            <path id="Tracé_6785" data-name="Tracé 6785" d="M354.993,36.286V31.437h1.451v4.577a2.406,2.406,0,0,0,.387,1.643,1.548,1.548,0,0,0,1.256.547,3.393,3.393,0,0,0,2.482-1.353V31.437h1.451v7.927h-1.453v-1l-.031-.016a3.884,3.884,0,0,1-2.74,1.192,2.827,2.827,0,0,1-2.158-.886,3.44,3.44,0,0,1-.644-2.368" transform="translate(-85.244 -7.55)" fill="#5b6e83"/>
            <path id="Tracé_6786" data-name="Tracé 6786" d="M378.063,26.283a6.833,6.833,0,0,1,3.575.967v1.741A5.717,5.717,0,0,0,378.03,27.7a4.608,4.608,0,0,0,.049,9.216,5.2,5.2,0,0,0,2.384-.549V33.646h-2.609V32.309h4.14v4.816A7.418,7.418,0,0,1,377.95,38.3a5.843,5.843,0,0,1-6.04-5.637c0-.113,0-.226,0-.34a5.9,5.9,0,0,1,5.761-6.045c.131,0,.262,0,.394,0" transform="translate(-89.309 -6.311)" fill="#5b6e83"/>
            <path id="Tracé_6787" data-name="Tracé 6787" d="M388.336,26.514h3.158a6.575,6.575,0,0,1,3.254.578,2.969,2.969,0,0,1,1.611,2.723,3.094,3.094,0,0,1-1.5,2.74,3.6,3.6,0,0,1-1.337.483l3.738,5.14h-1.852l-3.561-4.995h-1.982v4.995h-1.529Zm3.511,5.412a3.682,3.682,0,0,0,2.287-.532,1.717,1.717,0,0,0,.694-1.451,1.8,1.8,0,0,0-.951-1.659,5.165,5.165,0,0,0-2.5-.388h-1.515v4.027Z" transform="translate(-93.255 -6.362)" fill="#5b6e83"/>
            <path id="Tracé_6788" data-name="Tracé 6788" d="M407.086,26.285a6.01,6.01,0,1,1-5.931,6.087c0-.026,0-.052,0-.078a5.862,5.862,0,0,1,5.713-6.008c.071,0,.143,0,.214,0m0,10.6a4.6,4.6,0,1,0-4.393-4.789q0,.1,0,.2a4.407,4.407,0,0,0,4.219,4.587c.059,0,.119,0,.178,0" transform="translate(-96.333 -6.312)" fill="#5b6e83"/>
            <path id="Tracé_6789" data-name="Tracé 6789" d="M419.145,32.689V26.52h1.531v6.008a6.583,6.583,0,0,0,.451,3.013,2.876,2.876,0,0,0,2.77,1.4,2.823,2.823,0,0,0,2.74-1.4,6.417,6.417,0,0,0,.435-2.948V26.52h1.482v5.992a7.192,7.192,0,0,1-.774,4.027,4.532,4.532,0,0,1-3.946,1.819,4.474,4.474,0,0,1-3.916-1.819,7.052,7.052,0,0,1-.772-3.851" transform="translate(-100.647 -6.369)" fill="#5b6e83"/>
            <path id="Tracé_6790" data-name="Tracé 6790" d="M434.8,26.513h3.044a6.978,6.978,0,0,1,3.447.608A3.139,3.139,0,0,1,442.979,30a3.285,3.285,0,0,1-1.58,2.9,7.554,7.554,0,0,1-3.865.661h-1.206v4.609H434.8Zm2.932,5.718a5.293,5.293,0,0,0,2.786-.435,2.051,2.051,0,0,0-.146-3.528,5.976,5.976,0,0,0-2.66-.371h-1.381v4.331Z" transform="translate(-104.412 -6.362)" fill="#5b6e83"/>
            <path id="Tracé_6791" data-name="Tracé 6791" d="M447.978,26.52h6.733V27.9h-5.2v3.546h4.559v1.337h-4.559V36.8h5.349v1.386h-6.878Z" transform="translate(-107.577 -6.369)" fill="#5b6e83"/>
            <rect id="Rectangle_1375" data-name="Rectangle 1375" width="3.27" height="11.761" transform="translate(353.156 20.053)" fill="#5b6e83"/>
            <path id="Tracé_6792" data-name="Tracé 6792" d="M485.523,26.391,478.9,35.784l-3.257-4.35v6.717h-3.191V26.391h3.255l3.205,4.367,3.093-4.367ZM482.3,38.149v-5.3l3.191-4.526v9.824Z" transform="translate(-113.455 -6.338)" fill="#5b6e83"/>
            <path id="Tracé_6793" data-name="Tracé 6793" d="M496.1,26.52h3.267l4.72,11.663h-3.609l-.821-2.384h-3.995l-.855,2.384h-3.432Zm1.578,3.544c-.228.741-.451,1.417-.725,2.174l-.4,1.112h2.255l-.387-1.112c-.274-.788-.484-1.449-.709-2.174Z" transform="translate(-118 -6.369)" fill="#5b6e83"/>
        </svg>
      : null
    }
        </React.Fragment>
    )
  }
}