import React from 'react';
import { IconStyle } from './iconStyle';

interface IconProperties {
    icon?: string;
}

interface IconStateProperties {
    icon?: string;
}

export class Icon extends React.Component<IconProperties, IconStateProperties> {
    constructor(props: IconProperties) {
        super(props);
        this.state = {
            icon: this.props.icon,
        }
    }
  render() {
    return(
        <React.Fragment>
            <IconStyle >
                { this.props.icon === 'national' ?
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="60" height="58.864" viewBox="0 0 60 58.864">
                        <rect id="Rectangle_1366" data-name="Rectangle 1366" width="60" height="58.864" fill="none"></rect>
                        <rect id="Rectangle_1359" data-name="Rectangle 1359" width="5.069" height="2.138" transform="translate(3.855 23.635)" fill="#008769"></rect>
                        <rect id="Rectangle_1360" data-name="Rectangle 1360" width="6.033" height="2.136" transform="translate(8.672 19.854)" fill="#008769"></rect>
                        <rect id="Rectangle_1361" data-name="Rectangle 1361" width="6.033" height="2.138" transform="translate(10.601 23.635)" fill="#008769"></rect>
                        <rect id="Rectangle_1362" data-name="Rectangle 1362" width="10.85" height="2.138" transform="translate(3.855 40.653)" fill="#008769"></rect>
                        <rect id="Rectangle_1363" data-name="Rectangle 1363" width="4.104" height="2.136" transform="translate(3.855 27.418)" fill="#008769"></rect>
                        <rect id="Rectangle_1364" data-name="Rectangle 1364" width="5.069" height="2.136" transform="translate(3.855 35.927)" fill="#008769"></rect>
                        <rect id="Rectangle_1365" data-name="Rectangle 1365" width="5.069" height="2.136" transform="translate(3.855 31.2)" fill="#008769"></rect>
                        <path id="Tracé_6706" data-name="Tracé 6706" d="M7.1,2.938H5.174V1.047H2.995V2.938H1.068V5.073H2.995V6.964H5.174V5.073H7.1Zm-3.855.246H4.921V4.828H3.246Z" transform="translate(-0.103 -0.101)" fill="#008769"></path>
                        <path id="Tracé_6707" data-name="Tracé 6707" d="M7.1,59.456H5.174V57.565H2.995v1.891H1.068v2.136H2.995v1.891H5.174V61.591H7.1ZM3.246,59.7H4.922v1.645H3.246Z" transform="translate(-0.103 -5.563)" fill="#008769"></path>
                        <path id="Tracé_6708" data-name="Tracé 6708" d="M54.569,43.957a9.58,9.58,0,1,0,9.763,9.578,9.683,9.683,0,0,0-9.763-9.578m3.59,15.914a7.585,7.585,0,0,1-7.179,0v-.726a2.611,2.611,0,0,1,2.636-2.579h1.908a2.611,2.611,0,0,1,2.635,2.579ZM52.888,52.6a1.682,1.682,0,1,1,1.681,1.647A1.664,1.664,0,0,1,52.888,52.6m7.476,5.433a4.879,4.879,0,0,0-2.482-3.192A3.84,3.84,0,0,0,58.61,52.6a4.042,4.042,0,0,0-8.082,0,3.846,3.846,0,0,0,.73,2.243,4.879,4.879,0,0,0-2.483,3.192,7.134,7.134,0,0,1-1.608-4.5,7.4,7.4,0,0,1,14.805,0,7.122,7.122,0,0,1-1.608,4.5" transform="translate(-4.33 -4.248)" fill="#00c378"></path>
                        <path id="Tracé_6709" data-name="Tracé 6709" d="M54.569,21.979a9.579,9.579,0,1,0,9.763,9.578,9.682,9.682,0,0,0-9.763-9.578m3.59,15.914a7.59,7.59,0,0,1-7.179,0v-.726a2.611,2.611,0,0,1,2.636-2.579h1.908a2.611,2.611,0,0,1,2.635,2.579Zm-5.271-7.272a1.682,1.682,0,1,1,1.681,1.646,1.665,1.665,0,0,1-1.681-1.646m7.476,5.432a4.879,4.879,0,0,0-2.482-3.192,3.837,3.837,0,0,0,.729-2.24,4.042,4.042,0,0,0-8.082,0,3.843,3.843,0,0,0,.73,2.242,4.879,4.879,0,0,0-2.483,3.192,7.134,7.134,0,0,1-1.608-4.5,7.4,7.4,0,0,1,14.805,0,7.121,7.121,0,0,1-1.608,4.5" transform="translate(-4.33 -2.124)" fill="#00c378"></path>
                        <path id="Tracé_6710" data-name="Tracé 6710" d="M23.736,51.005a1.174,1.174,0,0,0,1.182-1.164V45.859H22.554v2.818H17.662L15.9,51.005Z" transform="translate(-1.537 -4.432)" fill="#00c378"></path>
                        <path id="Tracé_6711" data-name="Tracé 6711" d="M40.939,31.857a2.586,2.586,0,0,0-2.614-2.552h-6.64l-1.746,2.313h8.386a.24.24,0,1,1,0,.48H34.5a1.17,1.17,0,0,0-1.182,1.156v.931a.718.718,0,0,1-.73.7,1.17,1.17,0,0,0-1.182,1.155v.931a.719.719,0,0,1-.73.7A1.171,1.171,0,0,0,29.5,38.838v.931a.718.718,0,0,1-.73.7H25.135l-1.513-.491-1.478,1.957,2.429.79a1.193,1.193,0,0,0,.372.059h3.823a3.059,3.059,0,0,0,3.093-3.017v0a3.032,3.032,0,0,0,1.911-2.79v0a3.035,3.035,0,0,0,1.9-2.563h2.65a2.587,2.587,0,0,0,2.614-2.554" transform="translate(-2.14 -2.832)" fill="#00c378"></path>
                        <path id="Tracé_6712" data-name="Tracé 6712" d="M43.649,38.107l-2.855,2.8,1.589,1.557,2.854-2.8Z" transform="translate(-3.943 -3.682)" fill="#00c378"></path>
                        <path id="Tracé_6713" data-name="Tracé 6713" d="M43.648,25.542l1.588-1.56-2.854-2.8-1.589,1.558Z" transform="translate(-3.942 -2.047)" fill="#00c378"></path>
                        <path id="Tracé_6714" data-name="Tracé 6714" d="M54.569,0c-.246,0-.486.019-.728.036a9.661,9.661,0,0,0-9.034,9.542,9.345,9.345,0,0,0,.244,2.1,9.778,9.778,0,0,0,19.28-2.1A9.682,9.682,0,0,0,54.569,0m3.59,15.914a7.59,7.59,0,0,1-7.179,0v-.726a2.611,2.611,0,0,1,2.636-2.579h1.908a2.611,2.611,0,0,1,2.635,2.579ZM52.888,8.642a1.682,1.682,0,1,1,1.681,1.646,1.665,1.665,0,0,1-1.681-1.646m7.476,5.432a4.879,4.879,0,0,0-2.482-3.192,3.837,3.837,0,0,0,.729-2.24,4.042,4.042,0,0,0-8.082,0,3.85,3.85,0,0,0,.73,2.243,4.875,4.875,0,0,0-2.483,3.191,7.134,7.134,0,0,1-1.608-4.5,7.015,7.015,0,0,1,.042-.759A7.291,7.291,0,0,1,51.68,2.9a7.493,7.493,0,0,1,2.889-.575,7.339,7.339,0,0,1,7.4,7.257,7.121,7.121,0,0,1-1.608,4.5" transform="translate(-4.33 0)" fill="#00c378"></path>
                        <path id="Tracé_6715" data-name="Tracé 6715" d="M2.363,20.122H21.017V26.7h2.363V18.957A1.174,1.174,0,0,0,22.2,17.793H1.182A1.174,1.174,0,0,0,0,18.957V47.129a1.175,1.175,0,0,0,1.182,1.164H14.366l1.759-2.33H2.363Z" transform="translate(0 -1.719)" fill="#008769"></path>
                        <path id="Tracé_6716" data-name="Tracé 6716" d="M21.858,40.7a1.178,1.178,0,0,0-.373-.059H16v2.312h5.292l.257.083,1.478-1.957Z" transform="translate(-1.546 -3.927)" fill="#008769"></path>
                        <path id="Tracé_6717" data-name="Tracé 6717" d="M22.977,29.967l-1.847,1.2H16V33.48h5.482a1.2,1.2,0,0,0,.653-.193L24.283,31.9a1.7,1.7,0,0,1,.936-.277h4.127l1.746-2.313H25.219a4.1,4.1,0,0,0-2.242.661" transform="translate(-1.547 -2.832)" fill="#008769"></path>
                    </svg>
                    : null
                }
                { this.props.icon === 'digital' ?
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="60" height="46.24" viewBox="0 0 60 46.24">
                    <rect id="Rectangle_1358" data-name="Rectangle 1358" width="60" height="46.24" fill="none"/>
                    <path id="Tracé_6700" data-name="Tracé 6700" d="M22.863,42.442h7.314a10.822,10.822,0,0,1-.382,2.374H27.549A2.221,2.221,0,0,0,25.33,46.79,2.175,2.175,0,0,0,27.5,49.169H43.75a2.224,2.224,0,0,0,2.22-1.96A2.176,2.176,0,0,0,43.8,44.816H41.5a10.686,10.686,0,0,1-.379-2.374H58.487a3.467,3.467,0,0,0,3.463-3.464V34.343H22.863Zm1.829-6.269H60.12v2.805a1.636,1.636,0,0,1-1.634,1.635H39.245l.013.928A12.022,12.022,0,0,0,40.03,46.1l.241.543H43.8a.349.349,0,0,1,.345.385.392.392,0,0,1-.4.309H27.5a.345.345,0,0,1-.343-.383.39.39,0,0,1,.4-.311h3.476l.241-.543a12.519,12.519,0,0,0,.775-4.563l.013-.927H24.692Z" transform="translate(-1.95 -2.929)" fill="#00c378"/>
                    <path id="Tracé_6701" data-name="Tracé 6701" d="M58.527,0H45.938L44.557,1.829h13.97a1.635,1.635,0,0,1,1.634,1.634V28.1H24.733v-.017l-1.395,1.847H61.99V3.463A3.467,3.467,0,0,0,58.527,0" transform="translate(-1.99 0)" fill="#00c378"/>
                    <path id="Tracé_6702" data-name="Tracé 6702" d="M17.619,44.789a1.353,1.353,0,0,1-1.351,1.351H12.416l-1.382,1.829h5.234a3.184,3.184,0,0,0,3.18-3.18V36.827L17.619,39.25Z" transform="translate(-0.941 -3.141)" fill="#00c378"/>
                    <path id="Tracé_6703" data-name="Tracé 6703" d="M23.433,14.624a7.424,7.424,0,0,0-7.416-7.416h-6.1V3.463a1.635,1.635,0,0,1,1.634-1.634h31.7L44.638,0H11.553A3.467,3.467,0,0,0,8.09,3.463V9.037h7.927A5.593,5.593,0,0,1,21.6,14.624v15.3h.434l1.395-1.848Z" transform="translate(-0.69 0)" fill="#008769"/>
                    <path id="Tracé_6704" data-name="Tracé 6704" d="M9.857,40.552a2.6,2.6,0,1,0,2.6,2.6,2.606,2.606,0,0,0-2.6-2.6m0,3.377a.774.774,0,1,1,.774-.774.774.774,0,0,1-.774.774" transform="translate(-0.619 -3.458)" fill="#008769"/>
                    <path id="Tracé_6705" data-name="Tracé 6705" d="M15.327,12.51H3.18A3.184,3.184,0,0,0,0,15.69V42.715a3.183,3.183,0,0,0,3.18,3.18h6.913l1.382-1.829H3.18a1.352,1.352,0,0,1-1.351-1.35V15.69A1.352,1.352,0,0,1,3.18,14.339H15.327a1.353,1.353,0,0,1,1.351,1.351V37.175l1.829-2.422V15.69a3.184,3.184,0,0,0-3.18-3.18" transform="translate(0 -1.067)" fill="#008769"/>
                </svg>

                : null
                }
                { this.props.icon === 'home' ?
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="60" height="55.749" viewBox="0 0 60 55.749">
                    <rect id="Rectangle_1368" data-name="Rectangle 1368" width="60" height="55.749" fill="none"/>
                    <path id="Tracé_6727" data-name="Tracé 6727" d="M8.191,22.849H5.365V55.058a1.413,1.413,0,0,0,1.413,1.413H16.47L18.6,53.645H8.191Z" transform="translate(-0.169 -0.721)" fill="#008769"/>
                    <path id="Tracé_6728" data-name="Tracé 6728" d="M34.3,55.057V41.866h6.662V55.057a1.412,1.412,0,0,0,1.412,1.413H53.922a1.413,1.413,0,0,0,1.413-1.413V22.848H52.51v30.8H43.788V40.454a1.413,1.413,0,0,0-1.413-1.413H32.889a1.413,1.413,0,0,0-1.413,1.413v13.19H18.965L16.832,56.47H32.889A1.413,1.413,0,0,0,34.3,55.057" transform="translate(-0.531 -0.721)" fill="#00c378"/>
                    <path id="Tracé_6729" data-name="Tracé 6729" d="M59.9,23.574l1.629-2.31-11.313-7.98-1.7,2.256Z" transform="translate(-1.532 -0.42)" fill="#00c378"/>
                    <path id="Tracé_6730" data-name="Tracé 6730" d="M15.635,36.9h9.533a1.413,1.413,0,0,0,1.413-1.413V28.135a1.413,1.413,0,0,0-1.413-1.413H15.635a1.412,1.412,0,0,0-1.412,1.413v7.356A1.412,1.412,0,0,0,15.635,36.9m1.413-7.356h6.707v4.53H17.048Z" transform="translate(-0.449 -0.844)" fill="#008769"/>
                    <path id="Tracé_6731" data-name="Tracé 6731" d="M30.814.258a1.418,1.418,0,0,0-1.629,0L0,20.845l1.629,2.31L30,3.142,46.983,15.121l1.7-2.256Z" transform="translate(0 0)" fill="#008769"/>
                </svg>
                : null
                }
                { this.props.icon === 'human' ?
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="60" height="46.536" viewBox="0 0 60 46.536">
                    <rect id="Rectangle_1367" data-name="Rectangle 1367" width="60" height="46.536" fill="none"/>
                    <path id="Tracé_6718" data-name="Tracé 6718" d="M60.653,34.3a3.926,3.926,0,0,0-4.967-2l-15.213,6.24c-.177.075-.817.319-.835.329l-.115.026a8.919,8.919,0,0,1-2.409.352L27.105,38.168a.168.168,0,0,1-.114-.063.185.185,0,0,1-.04-.135.216.216,0,0,1,.248-.144L37,38.875l.1.006a8.42,8.42,0,0,0,2.711-.455l.6-.249a4.684,4.684,0,0,0,1.615-1.246,2.534,2.534,0,0,0,.771-2.2c-.416-1.584-1.622-1.788-4.537-2.283l-.441-.077c-.323-.055-.664-.113-1.033-.182-1.177-.212-2.263-.366-4.109-.614-1.226-.165-2.767-.382-4.868-.684-.51-.074-1.006-.131-1.5-.174l-1.523,2.017a22.453,22.453,0,0,1,2.72.236c2.11.3,3.658.519,4.889.688,1.809.24,2.87.391,4.008.6.374.071.726.133,1.062.189l.437.076a11.207,11.207,0,0,1,2.8.642,2.845,2.845,0,0,1-.3.44,2.5,2.5,0,0,1-.832.652l-.491.206a6.246,6.246,0,0,1-1.906.323l-9.729-1.041a2.319,2.319,0,0,0-2.509,1.648,2.4,2.4,0,0,0-.07.377,2.277,2.277,0,0,0,2.025,2.494l9.96,1.071.183.01a10.134,10.134,0,0,0,3.078-.432l.25-.075v-.006c.253-.087.577-.213.859-.326l15.233-6.251a1.829,1.829,0,0,1,2.3.946,1.8,1.8,0,0,1,.053,1.4v0a1.829,1.829,0,0,1-1,1.036L41.392,45.177a15.84,15.84,0,0,1-6.891,1.5,69.171,69.171,0,0,1-7.736-.916c-.687-.1-1.376-.206-2.06-.307a81.51,81.51,0,0,0-9-.69l-1.649,2.183c.669-.053,1.213-.085,1.546-.085a78.89,78.89,0,0,1,8.812.672c.67.1,1.355.2,2.039.305a68.479,68.479,0,0,0,8.029.937l.189,0a18.014,18.014,0,0,0,7.605-1.691l16.382-7.533a3.916,3.916,0,0,0,2-5.253" transform="translate(-1.024 -2.239)" fill="#00c378"/>
                    <path id="Tracé_6719" data-name="Tracé 6719" d="M47.9,33.27l12.187-5v-.7c-.007-6.216-2.167-11.765-5.5-14.137l-.442-.316-.515.178a5.462,5.462,0,0,1-4.2-.26l-.541-.268-.5.335a10.934,10.934,0,0,0-4.081,5l-.143.352.115.362a25.04,25.04,0,0,1,1.186,7.751v0c0,.427-.009.756-.027,1.091l-.315.952,1.108.269a2.515,2.515,0,0,1,1.859,1.9,1.808,1.808,0,0,1,.045.7Zm-.341-6.144c0-.172.006-.353,0-.553A27.135,27.135,0,0,0,46.4,18.554a8.852,8.852,0,0,1,2.694-3.376,7.613,7.613,0,0,0,4.672.271c2.425,2.051,4.063,6.486,4.212,11.421L50.089,30.1a4.6,4.6,0,0,0-2.527-2.979" transform="translate(-3.218 -0.931)" fill="#00c378"/>
                    <path id="Tracé_6720" data-name="Tracé 6720" d="M40.648,27.075l-.485-.085c-1.3-.24-2.524-.405-4.217-.635-1.222-.163-2.756-.374-4.841-.678-.242-.036-.486-.053-.728-.083L28.9,27.552c.632.044,1.264.108,1.892.2H30.8c2.1.306,3.638.515,4.867.683,1.595.214,2.854.385,4.128.621l2.914.488.04-1.188c.008-.215.008-.446.008-.719,0-5.312-1.568-10.155-4.137-12.958l-1.3,1.723c1.975,2.361,3.229,6.329,3.33,10.669" transform="translate(-2.106 -1.07)" fill="#00c378"/>
                    <path id="Tracé_6721" data-name="Tracé 6721" d="M52.046,1.8A5.149,5.149,0,0,0,46.9,6.95a5.163,5.163,0,0,0,5.142,5.143h.006a5.147,5.147,0,1,0,0-10.295m0,8.194a3.047,3.047,0,0,1,0-6.094V3.55l0,.348a3.047,3.047,0,0,1,0,6.094" transform="translate(-3.418 -0.131)" fill="#00c378"/>
                    <path id="Tracé_6722" data-name="Tracé 6722" d="M14.6,44.75c-1.008,0-3.281.223-5.911.481l-.136.012c-2.251.22-4.782.465-6.45.553V37.349a30.146,30.146,0,0,0,9.888-1.9c5.011-2,8.35-2.81,11.775-2.727l1.523-2.018c-4.278-.386-8.06.39-14.079,2.8A29.756,29.756,0,0,1,1.007,35.3L0,35.338V47.93H1.05c1.6,0,4.775-.31,7.84-.61,1.484-.146,3-.294,4.142-.384l1.648-2.183-.083,0" transform="translate(0 -2.231)" fill="#008769"/>
                    <path id="Tracé_6723" data-name="Tracé 6723" d="M12.635,30.385l.013-.006a42.273,42.273,0,0,1,8.458-2.568l.828-.158.025-.844a25.549,25.549,0,0,1,.685-5.2l.09-.378-.3-.589a11.089,11.089,0,0,0-3.353-4.111L18.5,16.1l-.612.388a5.483,5.483,0,0,1-5.053.429l-.606-.253-.492.434C8.794,19.7,6.941,24.831,6.9,30.5l-.007,1.215,1.2-.17a21.408,21.408,0,0,0,4.536-1.157M12.686,19.1a7.586,7.586,0,0,0,5.657-.456,9.164,9.164,0,0,1,2.17,2.859,27.727,27.727,0,0,0-.618,4.411,43.961,43.961,0,0,0-8.025,2.514,19.3,19.3,0,0,1-2.825.818c.256-4.209,1.63-8.041,3.641-10.146" transform="translate(-0.502 -1.174)" fill="#008769"/>
                    <path id="Tracé_6724" data-name="Tracé 6724" d="M37.605,13.882l-.561-.5-.654.368a6.317,6.317,0,0,1-5.885.186l-.658-.32-.529.506C26.7,16.641,25,21.2,24.774,26.321l-.053,1.151,1.151-.053a23.03,23.03,0,0,1,2.723.038L30.073,25.5a25.493,25.493,0,0,0-3.093-.206h-.032c.349-3.81,1.559-7.115,3.353-9.156a8.453,8.453,0,0,0,6.413-.194c.1.111.2.248.3.367l1.3-1.723a9.425,9.425,0,0,0-.708-.706" transform="translate(-1.801 -0.976)" fill="#008769"/>
                    <path id="Tracé_6725" data-name="Tracé 6725" d="M15.178,15.879h.006a5.143,5.143,0,1,0-.006,0m-2.145-7.3a3.047,3.047,0,1,1,2.151,5.2,3.046,3.046,0,0,1-2.151-5.2" transform="translate(-0.731 -0.407)" fill="#008769"/>
                    <path id="Tracé_6726" data-name="Tracé 6726" d="M33.45,12.585a6.3,6.3,0,0,0,6.29-6.292,6.291,6.291,0,1,0-6.29,6.292m0-10.482h0a4.191,4.191,0,1,1-4.192,4.191A4.17,4.17,0,0,1,33.448,2.1" transform="translate(-1.979 0)" fill="#008769"/>
                </svg>
                : null
                }
            </IconStyle>
        </React.Fragment>
    )
  }
}