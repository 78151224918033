import styled from 'styled-components';

export const FooterStyle = styled.footer` 
background: ${({ theme }) => theme.background3};
.logo-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
}
.links-wrap {
  border-top: 1px solid ${({ theme }) => theme.alt4};
  display flex;
  padding: 20px;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  color: ${({ theme }) => theme.alt3};
  font-size: 14px;
  line-height: 19px;
  font-family: '${({ theme }) => theme.font3}';
  a {
    color: ${({ theme }) => theme.alt3};
    text-decoration: none;
  }
  .links {
    display flex;
    flex-direction: column; 
    > div {
      margin: 4px 0;
    }
  }
  .copyright {
    margin: 0 0 4px;
  }
  .dot {
    display: none;
  }
}

    @media screen and (min-width: ${({ theme }) => theme.mobile}) {
      .links-wrap {
        padding: 20px;
        flex-direction: row;
        justify-content: center;
        .links {
          flex-direction: row; 
          > div {
            margin: 0;
          }
        }
        .dot { 
          display: inline;
          margin: 0 10px;
        }
      }
    }
`