import React from 'react';

import { LinkStyle, LinkSecondaryStyle } from './linkStyle';

interface ILinkProperties {
  label?: string;
  href?: string;
  target?: string;
  click?: string;
  secondary?: string;
  arrow?: string;
}

interface ILinkStateProperties {
  label?: string;
  href?: string;
  target?: string;
  click?: string;
  secondary?: string;
  arrow?: string;
}

export class Link extends React.Component<ILinkProperties, ILinkStateProperties> {
    constructor(props: ILinkProperties) {
        super(props);
        this.state = {
            label: this.props.label,
            href: this.props.href,
            target: this.props.target,
            click: this.props.click,
            secondary: this.props.secondary,
            arrow: this.props.arrow,
        }
    }

  render() {

    return(
        <React.Fragment>
          {this.props.secondary ?
              <LinkSecondaryStyle target={this.props.target} href={this.props.href} onClick={this.props.click}>
                {this.props.label} { !this.props.arrow ? '›' : null }
              </LinkSecondaryStyle> :
              <LinkStyle target={this.props.target} href={this.props.href} onClick={this.props.click}>
                  {this.props.label} { !this.props.arrow ? '›' : null }
              </LinkStyle>
            }
  
        </React.Fragment>
    )
  }
}